import listActionTypes from "./actiontypes"
import { libraryListPageStateSelector } from "./selectors"
import LibraryAPI from "../api"
import { fetchingPaginatedItemsFlowFactory } from "core/factories/actions"

const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_LIST,
} = listActionTypes

export default function* listFetchingFlow() {
  yield* fetchingPaginatedItemsFlowFactory(
    {
      fetch: FETCH_LIST,
      request: FETCH_LIST_REQUEST,
      success: FETCH_LIST_SUCCESS,
      failure: FETCH_LIST_FAILURE,
    },
    ({ page, limit, filters }) => () =>
      LibraryAPI.list({
        page: page + 1,
        limit,
        ...filters,
      }),
    ({ page }) => (state) => libraryListPageStateSelector(state, page)
  )()
}
