import React from "react"
import PropTypes from "prop-types"
import { LessonType } from "lessons"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

import ParticipantTableRow from "./Row"

function ParticipantTable({ participants, remove }) {
  const tableRows = participants.map((participant) => (
    <ParticipantTableRow
      key={participant.id}
      id={participant.id}
      authors={participant.authors}
      course={participant.course}
      subscribed={participant.subscribed}
      lessons={participant.lessons}
    />
  ))
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="h6" component="h2">
              Курс
            </Typography>
          </TableCell>
          <TableCell>Кто ведет курс</TableCell>
          <TableCell align="right">Статус</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableRows}</TableBody>
    </Table>
  )
}

ParticipantTable.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      authors: PropTypes.string.isRequired,
      course: PropTypes.string.isRequired,
      subscribed: PropTypes.bool.isRequired,
      lessons: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
    })
  ).isRequired,
}

export default ParticipantTable
