import React from "react"
import PropTypes from "prop-types"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import CanBeSubscribedIcon from "./CanBeSubscribedIcon"
import CanBeSubscribedPopup from "./CanBeSubscribedPopup"
import { COURSE_STATES } from "../../constants"
import { CourseType } from "../../types"
import { AuthorType } from "authors"
import { RouterLink } from "core/components"
import urls from "core/urls"
import { DISCIPLE_AGE_OPTIONS } from "disciples"

const showPopup = (event) => {
  event.currentTarget.parentNode.nextSibling.firstChild.style.visibility =
    "visible"
}
const hidePopup = (event) => {
  event.currentTarget.parentNode.nextSibling.firstChild.style.visibility =
    "hidden"
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    position: "relative",
    height: 220,
    width: 367,
    cursor: "pointer",
    overflow: "visible",
    border: "none",
    margin: "0 auto",
    backgroundColor: "#000",

    "& img": {
      maxHeight: 220,
    },

    "&:hover img": {
      filter: "brightness(25%)",
    },
  },
  background: {
    "-webkit-transition": "all 0.3s ease",
    transition: "all 0.3s ease",
    filter: "brightness(40%)",
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    color: "white",
    left: 0,
    top: 0,
    paddingLeft: 25,
    zIndex: 10,
  },
  title: {
    width: "90%",
    fontSize: 27,
    marginTop: 26,
    lineHeight: 1,

    "@media (min-width: 960)": {
      fontSize: 30,
    },
  },
  subtitle: {
    width: "90%",
    fontSize: 18,
    marginTop: 10,
    lineHeight: 1.5,
  },
  authors: {
    fontSize: 15,
    fontStyle: "italic",
    marginTop: 15,
  },
  footer: {
    display: "flex",
    marginTop: "auto",
    marginBottom: 23,
  },
  age: {
    fontSize: 15,
    lineHeight: 1,
  },
}))

function CoursesGridItem({
  authors,
  id,
  thumbnail,
  title,
  subtitle,
  age,
  state,
  start,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      component={RouterLink}
      to={urls.courseDetails(id)}
    >
      <div className={classes.contentWrapper}>
        <img
          className={classes.background}
          src={thumbnail}
          alt="course"
          width="100%"
          height="*"
        />
        <Grid
          className={classes.content}
          container
          direction="column"
          wrap="nowrap"
        >
          <Grid className={classes.title} item>
            {title}
          </Grid>
          <Grid className={classes.subtitle} item>
            {subtitle}
          </Grid>
          <Grid className={classes.authors} item>
            {authors.map((a) => `${a.firstName} ${a.lastName}`)}
          </Grid>
          <Grid className={classes.footer}>
            <div className={classes.age}>
              Вебинар {DISCIPLE_AGE_OPTIONS[age]}
            </div>
            {state === COURSE_STATES.canBeSubscribed ? (
              <CanBeSubscribedIcon
                onMouseOver={showPopup}
                onMouseOut={hidePopup}
              />
            ) : null}
          </Grid>
          <Grid item>
            {state === COURSE_STATES.canBeSubscribed && mediumOrHigherScreen ? (
              <CanBeSubscribedPopup start={start} />
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

CoursesGridItem.propTypes = {
  age: CourseType.disciplesAge,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: AuthorType.firstName,
      lastName: AuthorType.lastName,
    })
  ),
  id: CourseType.id,
  state: CourseType.state,
  subtitle: CourseType.subtitle,
  thumbnail: CourseType.thumbnail,
  title: CourseType.title,
}

export default CoursesGridItem
