import React from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  "@keyframes lds-ellipsis1": {
    from: {
      transform: "scale(0)",
    },
    to: {
      transform: "scale(1)",
    },
  },

  "@keyframes lds-ellipsis3": {
    from: {
      transform: "scale(1)",
    },
    to: {
      transform: "scale(0)",
    },
  },

  "@keyframes lds-ellipsis2": {
    from: {
      transform: "translate(0, 0)",
    },
    to: {
      transform: "translate(19px, 0)",
    },
  },

  loadingAnimation: {
    display: "inline-block",
    width: 64,
    height: 64,
    position: "absolute",
    left: "50%",
    marginLeft: -32,
    zIndex: 1000,

    "& div": {
      position: "absolute",
      top: 27,
      width: 11,
      height: 11,
      borderRadius: "50%",
      background: theme.colors.lightBackground,
      animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
    },

    "& div:nth-child(1)": {
      left: 6,
      animation: "$lds-ellipsis1 0.6s infinite",
    },

    "& div:nth-child(2)": {
      left: 6,
      animation: "$lds-ellipsis2 0.6s infinite",
    },

    "& div:nth-child(3)": {
      left: 26,
      animation: "$lds-ellipsis2 0.6s infinite",
    },

    "& div:nth-child(4)": {
      left: 45,
      animation: "$lds-ellipsis3 0.6s infinite",
    },
  },
}))

function Loader() {
  const classes = useStyles()
  return (
    <div className={classes.loadingAnimation}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default Loader
