import PropTypes from "prop-types"
import { AuthorType } from "authors"
import { LessonType } from "lessons"
import { PriceType } from "payments"

export const CourseType = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.number),
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  disciplesAge: PropTypes.oneOf([
    "DISCIPLES_AGE_CHILDREN",
    "DISCIPLES_AGE_ADULTS",
  ]),
  image: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  state: PropTypes.oneOf(["draft", "announced", "can_be_subscribed"])
    .isRequired,
  participantsLimit: PropTypes.number,
  url: PropTypes.string,
  lessons: PropTypes.arrayOf(PropTypes.number),
  prices: PropTypes.arrayOf(PropTypes.shape(PriceType)),
  fullYear: PropTypes.bool,
}

export const CourseTypeNested = {
  ...CourseType,
  authors: PropTypes.arrayOf(PropTypes.shape(AuthorType)).isRequired,
  lessons: PropTypes.arrayOf(PropTypes.shape(LessonType)),
}
