import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/styles"

import { Footer, SmallHeader } from "core/components"
import { Library } from "library"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  content: {
    margin: `${theme.spacing(3)}px auto 0 auto`,
    maxWidth: 1167,
    padding: 0,

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(5)}px auto 0 auto`,
    },
  },
  title: theme.presets.italicTitle,
}))

function ScreensLibrary() {
  const classes = useStyles()
  return (
    <Fragment>
      <SmallHeader />
      <div className={classes.content}>
        <Typography
          variant="h1"
          component="h1"
          align="center"
          className={classes.title}
        >
          Библиотека
        </Typography>
      </div>
      <div className={classes.content}>
        <Library />
      </div>
      <Footer />
    </Fragment>
  )
}

export default ScreensLibrary
