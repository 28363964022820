import { createSlice } from "@reduxjs/toolkit"
import { normalize } from "normalizr"

import courseSchema from "courses/schema"
import courseDetailsActionTypes from "courses/details/actiontypes"
import courseListActionTypes from "courses/list/actiontypes"
import { finishParticipantsCreation } from "../creation/slice"

import { saveEntities } from "common/reducers"

const initialState = {}

const participantsEntitiesSlice = createSlice({
  name: "participant-entities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(finishParticipantsCreation, (state, action) =>
        saveEntities({
          state,
          entities: normalize(action.payload.details, courseSchema).entities
            .participants,
        })
      )
      .addCase(
        courseDetailsActionTypes.FETCH_DETAILS_SUCCESS,
        (state, action) =>
          saveEntities({
            state,
            entities: normalize(action.payload.details, courseSchema).entities
              .participants,
          })
      )
      .addCase(courseListActionTypes.FETCH_LIST_SUCCESS, (state, action) =>
        saveEntities({
          state,
          entities: normalize(action.items, [courseSchema]).entities
            .participants,
        })
      )
  },
})

export default participantsEntitiesSlice.reducer
