import LIST_ACTION_TYPES from "./actiontypes"
import CREATION_ACTION_TYPES from "../creation/actiontypes"
import DELETION_ACTION_TYPES from "../deletion/actiontypes"
import UPDATING_ACTION_TYPES from "../updating/actiontypes"
import { participantSchema } from "../schema"
import { listReducerFactory } from "core/factories/reducers"
import { AUTH_ACTION_TYPES } from "users"

const { CREATE_SUCCESS } = CREATION_ACTION_TYPES
const { DELETE_SUCCESS } = DELETION_ACTION_TYPES
const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  INVALIDATE_LIST,
} = LIST_ACTION_TYPES
const { UPDATE_SUCCESS } = UPDATING_ACTION_TYPES
const { LOGOUT_SUCCESS } = AUTH_ACTION_TYPES

const listReducer = listReducerFactory(
  {
    createSuccess: CREATE_SUCCESS,
    deleteSuccess: DELETE_SUCCESS,
    listRequest: FETCH_LIST_REQUEST,
    listSuccess: FETCH_LIST_SUCCESS,
    listFailure: FETCH_LIST_FAILURE,
    invalidateList: INVALIDATE_LIST,
    signoutSuccess: LOGOUT_SUCCESS,
    updateSuccess: UPDATE_SUCCESS,
  },
  participantSchema
)

export default listReducer
