const PASSWORD_RESET_ACTION_TYPES = {
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_RESETTING: "RESET_PASSWORD_RESETTING",

  RESET_PASSWORD_COMPLETE: "RESET_PASSWORD_COMPLETE",
  RESET_PASSWORD_COMPLETE_REQUEST: "RESET_PASSWORD_COMPLETE_REQUEST",
  RESET_PASSWORD_COMPLETE_SUCCESS: "RESET_PASSWORD_COMPLETE_SUCCESS",
  RESET_PASSWORD_COMPLETE_FAILURE: "RESET_PASSWORD_COMPLETE_FAILURE",
  RESET_PASSWORD_RESETTING_COMPLETION: "RESET_PASSWORD_RESETTING_COMPLETION",
}

export default PASSWORD_RESET_ACTION_TYPES
