import { all, call } from "redux-saga/effects"

import { authorsSaga } from "authors"
import { coursesSaga } from "courses"
import { dictionariesSaga } from "dictionaries"
import { disciplesSaga } from "disciples"
import { feedbacksSaga } from "feedbacks"
import { lessonsSaga } from "lessons"
import { librarySaga } from "library"
import { libraryCategoriesSaga } from "library-categories"
import { quotationsSaga } from "quotations"
import { usersSaga } from "users"
import { paymentsSaga } from "payments"
import { participantsSaga } from "features/participants/saga"
import { timetableSaga } from "timetable"

export default function* rootSaga() {
  yield all([
    call(authorsSaga),
    call(coursesSaga),
    call(dictionariesSaga),
    call(disciplesSaga),
    call(feedbacksSaga),
    call(lessonsSaga),
    call(librarySaga),
    call(libraryCategoriesSaga),
    call(quotationsSaga),
    call(usersSaga),
    call(paymentsSaga),
    call(participantsSaga),
    call(timetableSaga),
  ])
}
