import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    marginTop: 30,
    fontSize: 18,
    fontStyle: "italic",
  },
})

export default function FeedbacksListItemFooter({ text }) {
  const classes = useStyles()
  return text ? <div className={classes.root}>{text}</div> : null
}

FeedbacksListItemFooter.propTypes = {
  text: PropTypes.string,
}
