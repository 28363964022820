import { useDispatch, useSelector } from "react-redux"

import {
  libraryCurrentPageSelector,
  libraryListPageStateSelector,
  libraryListStateSelector,
  libraryPageSelector,
} from "./selectors"
import { useCallback, useEffect } from "react"
import { fetchLibraryList } from "./actions"

export function useCoursesListPageState(page) {
  return useSelector((state) => libraryListPageStateSelector(state, page))
}

export function useLibraryListState() {
  return useSelector(libraryListStateSelector)
}

export function useCurrentLibraryPage() {
  return useSelector(libraryCurrentPageSelector)
}

export function useLibraryPage(page, limit, filters) {
  const libraryPageState = useCoursesListPageState(page)
  const dispatch = useDispatch()
  const fetchCallback = useCallback(() => {
    dispatch(fetchLibraryList(page, limit, filters))
  }, [page, limit, filters, dispatch])
  const didInvalidate = libraryPageState && libraryPageState.didInvalidate
  useEffect(fetchCallback, [fetchCallback, didInvalidate])

  const libraryPage = useSelector((state) => libraryPageSelector(state, page))
  const isLibraryPageLoaded = !!libraryPageState && !libraryPageState.loading
  return {
    libraryPage,
    libraryPageState,
    isLibraryPageLoaded,
  }
}
