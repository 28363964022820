import { call, put, take, fork, select } from "redux-saga/effects"

import TIMETABLE_ACTION_TYPES from "./actiontypes"
import TimetableAPI from "./api"
import { timetableStateSelector } from "./selectors"

const {
  FETCH,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
} = TIMETABLE_ACTION_TYPES

export function fetchTimetable() {
  return {
    type: FETCH,
  }
}

function* fetch() {
  yield put({ type: FETCH_REQUEST })
  try {
    const timetable = yield call(TimetableAPI.fetchDetails)
    yield put({ type: FETCH_SUCCESS, timetable, receivedAt: Date.now() })
  } catch (error) {
    yield put({
      type: FETCH_FAILURE,
      status: error.message,
      payload: { error: error.message, status: error.statusCode },
    })
  }
}

function shouldFetchTimetable(timetableState) {
  if (timetableState.isFetching) {
    return false
  } else if (!timetableState.lastUpdated) {
    return true
  } else {
    return timetableState.didInvalidate
  }
}

function* fetchTimetableIfNeeded() {
  const timetableState = yield select(timetableStateSelector)
  if (shouldFetchTimetable(timetableState)) {
    yield call(fetch)
  }
}

export function* fetchTimetableFlow() {
  yield take(FETCH)
  while (true) {
    yield fork(fetchTimetableIfNeeded)
    const { type } = yield take([FETCH, FETCH_REQUEST])
    if (type === FETCH) continue
    yield take([FETCH_SUCCESS, FETCH_FAILURE])
  }
}

export function* timetableSaga() {
  yield call(fetchTimetableFlow)
}
