import { omit } from "lodash"
import { normalize } from "normalizr"

function entitiesReducerFactory(actionTypes, schema, normalizedDataSelector) {
  const supportedActions = [
    "createSuccess",
    "listSuccess",
    "detailsSuccess",
    "updateSuccess",
    "deleteSuccess",
    "logoutSuccess",
    "listFromDetailsSuccess",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = {}, action) => {
    let normalizedData = null
    switch (action.type) {
      case actionTypes.listSuccess:
        normalizedData = normalize(action.items, [schema])
        return {
          ...state,
          ...normalizedDataSelector(normalizedData),
        }

      case actionTypes.createSuccess:
      case actionTypes.detailsSuccess:
      case actionTypes.updateSuccess:
        normalizedData = normalize(action.details, schema)
        const id = action.details.id
        return {
          ...state,
          [id]: normalizedDataSelector(normalizedData)[id],
        }

      case actionTypes.listFromDetailsSuccess:
        normalizedData = normalize(action.details, schema)
        return {
          ...state,
          ...normalizedDataSelector(normalizedData),
        }

      case actionTypes.deleteSuccess:
        return omit(state, action.id)

      case actionTypes.logoutSuccess:
        return {}

      default:
        return state
    }
  }
}

export default entitiesReducerFactory
