import LIST_ACTION_TYPES from "./actiontypes"
import { feedbacksPageStateSelector } from "./selectors"
import FeedbacksAPI from "../api"
import { fetchingPaginatedItemsFlowFactory } from "core/factories/actions"

const {
  FETCH_LIST,
  FETCH_LIST_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
} = LIST_ACTION_TYPES

export function fetchFeedbacksList(page) {
  return {
    type: FETCH_LIST,
    page,
  }
}

export function* listFetchingFlow() {
  yield* fetchingPaginatedItemsFlowFactory(
    {
      fetch: FETCH_LIST,
      request: FETCH_LIST_REQUEST,
      success: FETCH_LIST_SUCCESS,
      failure: FETCH_LIST_FAILURE,
    },
    (action) => () =>
      FeedbacksAPI.fetchList({
        page: action.page,
      }),
    (action) => (state) => feedbacksPageStateSelector(state, action.page)
  )()
}
