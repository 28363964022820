import { listActionTypes } from "../list"
import { libraryCategorySchema } from "../schema"
import { entitiesReducerFactory } from "core/factories/reducers"

const { FETCH_LIST_SUCCESS } = listActionTypes

const libraryCategoryEntitiesReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_LIST_SUCCESS,
  },
  libraryCategorySchema,
  (normalizedData) => normalizedData.entities.libraryCategories
)

export default libraryCategoryEntitiesReducer
