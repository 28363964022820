import { omit } from "lodash"

import PASSWORD_UPDATE_ACTION_TYPES from "./actiontypes"

const {
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
} = PASSWORD_UPDATE_ACTION_TYPES

function passwordUpdateReducer(
  state = {
    loading: false,
    didInvalidate: false,
  },
  action
) {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_PASSWORD_SUCCESS:
      return omit(
        {
          ...state,
          loading: false,
          didInvalidate: true,
        },
        ["error"]
      )

    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case UPDATE_PASSWORD_RESET:
      return {
        loading: false,
        didInvalidate: false,
      }

    default:
      return state
  }
}

export default passwordUpdateReducer
