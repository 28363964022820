import { combineReducers } from "redux"

import detailsReducer from "./details/reducer"
import loginReducer from "./login/reducer"
import { passwordUpdateReducer } from "./password-update"
import { passwordResetReducer } from "./password-reset"
import signUpReducer from "./signup/reducer"

export const currentUserStateReducer = combineReducers({
  details: detailsReducer,
  login: loginReducer,
  passwordReset: passwordResetReducer,
  passwordUpdate: passwordUpdateReducer,
  signUp: signUpReducer,
})

export { default as userEntitiesReducer } from "./entities/reducer"
