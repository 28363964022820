import PropTypes from "prop-types"

import { CourseType } from "../../types"
import { formatTextByNumber } from "core/utils/text"
import { LessonType } from "lessons"

export const messages = {
  noLessons: "Список лекций пока не опубликован",
  fullYearLesson: "Годичный курс",
}

function CourseVolume({ lessons, fullYear }) {
  const formatLessonsDurationTimeText = () => {
    const minDuration = lessons.map((lesson) => lesson.duration).sort()[0]
    const maxDuration = lessons.map((lesson) => lesson.duration).sort()[
      lessons.length - 1
    ]
    const maxDurationText = formatTextByNumber(
      maxDuration,
      "минуте",
      "минут",
      "минуты"
    )
    if (minDuration === maxDuration) {
      return maxDurationText
    } else {
      return `${minDuration} - ${maxDurationText}`
    }
  }

  const formatLessonsNumberText = () => {
    const lessonsNumber = lessons.length
    let lessonsNumberText = formatTextByNumber(
      lessonsNumber,
      "занятие",
      "занятий",
      "занятия"
    )
    return lessonsNumberText
  }

  if (fullYear) {
    return messages.fullYearLesson
  }
  if (!lessons.length) return messages.noLessons
  return `${formatLessonsNumberText()} по ${formatLessonsDurationTimeText()}`
}

CourseVolume.propTypes = {
  fullYear: CourseType.fullYear,
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      duration: LessonType.duration,
    })
  ),
}

export default CourseVolume
