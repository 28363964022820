import { omit } from "lodash"
import { normalize } from "normalizr"

export function setSliceLoading({ state, value }) {
  state.loading = value
  return state
}

export function saveSliceItems({ state, items }) {
  state.loading = false
  state.didInvalidate = false
  state.items = items
  state.lastUpdated = Date.now()
  delete state.error
  return state
}

export function saveSliceItem({ state, id }) {
  state.loading = false
  state.didInvalidate = false
  state.completed = true
  state.id = id
  delete state.error
  return state
}

export function failureSlice({ state, error }) {
  state.loading = false
  state.error = error
  state.lastUpdated = Date.now()
  state.didInvalidate = false
  return state
}

export function invalidateSlice({ state }) {
  return omit(
    {
      ...state,
      didInvalidate: true,
    },
    ["error"]
  )
}

export function removeSlice({ state, key }) {
  delete state[key]
  return state
}

export function saveEntity({ state, action, schema, selector }) {
  const normalizedData = normalize(action.payload.details, schema)
  if (selector(normalizedData)) {
    const id = Object.keys(selector(normalizedData))[0]
    return {
      ...state,
      [id]: selector(normalizedData)[id],
    }
  } else {
    return state
  }
}

export function saveEntities({ state, entities }) {
  return {
    ...state,
    ...entities,
  }
}

export function removeEntity({ state, id }) {
  delete state[id]
  return state
}
