const AUTH_ACTION_TYPES = {
  LOGIN: "LOGIN",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  UNAUTHORIZED_ERROR: "UNAUTHORIZED_ERROR",
}

export default AUTH_ACTION_TYPES
