import PropTypes from "prop-types"

export const FeedbackType = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorAge: PropTypes.number,
  course: PropTypes.number,
  courseAuthors: PropTypes.arrayOf(PropTypes.number),
  footer: PropTypes.text,
  createdAt: PropTypes.string.isRequired,
}
