import { combineReducers } from "redux"

import RESET_PASSWORD_ACTION_TYPES from "./actiontypes"

const {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_RESETTING,
  RESET_PASSWORD_COMPLETE_REQUEST,
  RESET_PASSWORD_COMPLETE_SUCCESS,
  RESET_PASSWORD_COMPLETE_FAILURE,
  RESET_PASSWORD_RESETTING_COMPLETION,
} = RESET_PASSWORD_ACTION_TYPES

export function startReducer(
  state = {
    loading: false,
    error: null,
    completed: false,
  },
  action
) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        completed: true,
        error: null,
      }

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case RESET_PASSWORD_RESETTING:
      return {
        loading: false,
        error: null,
        completed: false,
      }

    default:
      return state
  }
}

export function completionReducer(
  state = {
    loading: false,
    error: null,
    completed: false,
  },
  action
) {
  switch (action.type) {
    case RESET_PASSWORD_COMPLETE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case RESET_PASSWORD_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        completed: true,
      }

    case RESET_PASSWORD_COMPLETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case RESET_PASSWORD_RESETTING_COMPLETION:
      return {
        loading: false,
        error: null,
        completed: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  start: startReducer,
  completion: completionReducer,
})
