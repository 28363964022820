import DETAILS_ACTION_TYPES from "./actiontypes"
import { userDetailsStateSelector } from "./selectors"
import UsersAPI from "../api"
import { itemDetailsFetchingFlowFactory } from "core/factories/actions"

const {
  FETCH_DETAILS,
  FETCH_DETAILS_REQUEST,
  FETCH_DETAILS_SUCCESS,
  FETCH_DETAILS_FAILURE,
} = DETAILS_ACTION_TYPES

export function fetchUserDetails() {
  return { type: FETCH_DETAILS }
}

// Small hack because user details are fetched without "id" param
function selectorFunc(state) {
  const currentUserState = userDetailsStateSelector(state)
  if (!currentUserState.lastUpdated) {
    return undefined
  } else {
    return currentUserState
  }
}

export function* detailsFetchingFlow() {
  yield* itemDetailsFetchingFlowFactory(
    {
      fetch: FETCH_DETAILS,
      request: FETCH_DETAILS_REQUEST,
      success: FETCH_DETAILS_SUCCESS,
      failure: FETCH_DETAILS_FAILURE,
    },
    UsersAPI.fetchDetails,
    selectorFunc
  )()
}
