import { all, call } from "redux-saga/effects"

import { detailsFetchingFlow } from "./details/actions"
import { updatingFlow } from "./updating/actions"

export function* disciplesSaga() {
  yield all([call(detailsFetchingFlow), call(updatingFlow)])
}

export { fetchDiscipleDetails } from "./details/actions"
