import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"

function AlertDialog(props) {
  const {
    description,
    open,
    title,
    agreeButtonText,
    closeButtonText,
    onClose,
    onAgree,
  } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {closeButtonText}
        </Button>
        <Button onClick={onAgree} color="secondary" autoFocus>
          {agreeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  agreeButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  description: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

AlertDialog.defaultProps = {
  agreeButtonText: "Да",
  closeButtonText: "Закрыть",
}

export default AlertDialog
