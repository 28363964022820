const initState = {
  loading: false,
  didInvalidate: false,
  error: null,
}

function creationReducerFactory(actionTypes) {
  const supportedActions = [
    "createRequest",
    "createSuccess",
    "createFailure",
    "reset",
    "invalidate",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = initState, action) => {
    switch (action.type) {
      case actionTypes["createRequest"]:
        return {
          ...state,
          loading: true,
          error: null,
        }

      case actionTypes["createSuccess"]:
        return {
          ...state,
          loading: false,
          didInvalidate: false,
          error: null,
        }

      case actionTypes["createFailure"]:
        console.log(action.error)
        return {
          ...state,
          loading: false,
          didInvalidate: false,
          error: action.error,
        }

      case actionTypes["reset"]:
        return {
          ...state,
          didInvalidate: false,
          error: null,
        }

      case actionTypes["invalidate"]:
        return {
          ...state,
          didInvalidate: true,
        }

      default:
        return state
    }
  }
}

export default creationReducerFactory
