import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class PaymentAPI {
  create(data) {
    return handleErrors(
      fetch(endpoints.paymentList(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }

  getCurrent() {
    return handleErrors(
      fetch(endpoints.paymentCurrent(), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new PaymentAPI()
