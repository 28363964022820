import { createSlice } from "@reduxjs/toolkit"
import { failureSlice } from "common/reducers"

const initialStateForId = {
  loading: false,
  didInvalidate: false,
  completed: false,
}

const participantsDeletionSlice = createSlice({
  name: "participant-deletion",
  initialState: {},
  reducers: {
    startParticipantsDeletion: {
      reducer: (state) => ({ ...state }),
      prepare: (data) => ({ payload: { id: data } }),
    },
    requestParticipantsDeletion: (state, action) => {
      state[action.id] = {
        ...initialStateForId,
        loading: true,
      }
      return state
    },
    finishParticipantsDeletion: (state, action) => {
      state[action.id] = {
        ...initialStateForId,
        completed: true,
      }
      return state
    },
    failParticipantsDeletion: (state, { payload }) =>
      failureSlice({ state: state[payload.id], error: payload.error }),
    resetParticipantsDeletion: (state, { payload }) => {
      state[payload.id] = {}
      return state
    },
  },
})

export const {
  startParticipantsDeletion,
  requestParticipantsDeletion,
  finishParticipantsDeletion,
  failParticipantsDeletion,
  resetParticipantsDeletion,
} = participantsDeletionSlice.actions

export default participantsDeletionSlice.reducer
