export function courseMailoutsSelector(state, id) {
  return state.entities.courses[id].subscribedForMailouts
}

export function courseMailoutsSubscribeStateSelector(state, id) {
  return state.local.courses.mailouts.subscribeAction[id]
}

export function courseMailoutsUnsubscribeStateSelector(state, id) {
  return state.local.courses.mailouts.unsubscribeAction[id]
}
