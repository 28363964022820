import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { resetUserPassword, completeUserPasswordResetting } from "./actions"
import { useFormHandlers } from "core/hooks"

export function useResetPasswordEmailFormHandlers() {
  const dispatch = useDispatch()
  const [values, baseHandlers] = useFormHandlers({
    email: "",
  })

  const onSubmit = useCallback((event) => {
    event.preventDefault()
    dispatch(resetUserPassword(values.email))
  })

  return [values, { ...baseHandlers, onSubmit }]
}

export function useResetPasswordCompletionFormHandlers(uidb64, token) {
  const dispatch = useDispatch()
  const [values, baseHandlers] = useFormHandlers({
    password1: "",
    password2: "",
  })

  const onSubmit = useCallback((event) => {
    event.preventDefault()
    dispatch(
      completeUserPasswordResetting(
        uidb64,
        token,
        values.password1,
        values.password2
      )
    )
  })

  return [values, { ...baseHandlers, onSubmit }]
}
