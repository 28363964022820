import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { updateDisciple } from "./actions"
import { useFormHandlers } from "core/hooks"

export function useDiscipleUpdatingFormHandlers(id, initialValues) {
  const dispatch = useDispatch()
  const [values, baseHandlers] = useFormHandlers(
    {
      firstName: initialValues.firstName,
      lastName: initialValues.lastName,
      email: initialValues.email,
      yearOfBirth: initialValues.yearOfBirth,
      country: initialValues.country,
      mainLanguage: initialValues.mainLanguage,
      visitSchool: initialValues.visitSchool,
      readWriteProblems: initialValues.readWriteProblems,
      otherInfo: initialValues.otherInfo,
      timezone: initialValues.timezone,
    },
    true
  )

  const onSubmit = useCallback((event) => {
    event.preventDefault()
    const data = {
      profile: {
        country: values.country,
        mainLanguage: values.mainLanguage,
        visitSchool: values.visitSchool,
        readWriteProblems: values.readWriteProblems,
        otherInfo: values.otherInfo,
        timezone: values.timezone,
      },
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      yearOfBirth: values.yearOfBirth === 0 ? null : values.yearOfBirth,
    }
    dispatch(updateDisciple(id, data))
  })

  return [values, { ...baseHandlers, onSubmit }]
}
