import { call, fork, put, take } from "redux-saga/effects"

export function* updateItem(actionTypes, apiFunc, id, data) {
  yield put({ type: actionTypes["request"], id })
  try {
    const details = yield call(apiFunc, id, data)
    yield put({
      type: actionTypes["success"],
      details,
      id,
      receivedAt: Date.now(),
    })
  } catch (error) {
    yield put({
      type: actionTypes["failure"],
      error: error.message,
      id,
      payload: {
        error: error.message,
        status: error.statusCode,
        id,
      },
    })
  }
}

export function itemUpdatingFlowFactory(actionTypes, apiFuncFromAction) {
  return function* () {
    while (true) {
      let action = yield take([actionTypes["fetch"], actionTypes["reset"]])
      if (action.type === actionTypes["reset"]) {
        continue
      }
      yield fork(
        updateItem,
        actionTypes,
        apiFuncFromAction(action),
        action.id,
        action.data
      )
      yield take(actionTypes["request"])
      yield take([actionTypes["success"], actionTypes["failure"]])
    }
  }
}
