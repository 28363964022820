import React from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    height: 1,
    backgroundColor: "rgba(201, 193, 159, 0.5)",
    border: 0,
  },
})

function Separator({ className }) {
  const classes = useStyles()
  return <hr className={`${classes.root} ${className}`} />
}

export default Separator
