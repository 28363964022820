import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchLibraryCategoriesList } from "./actions"
import {
  libraryCategoriesListSelector,
  libraryCategoriesListStateSelector,
} from "./selectors"

export function useLibraryCategoriesList() {
  const dispatch = useDispatch()
  const fetchList = useCallback(() => {
    dispatch(fetchLibraryCategoriesList())
  })
  useEffect(fetchList, [])

  const libraryCategoriesListState = useSelector(
    libraryCategoriesListStateSelector
  )
  const libraryCategoriesList = useSelector(libraryCategoriesListSelector)
  return {
    libraryCategoriesList,
    libraryCategoriesListState,
    isLibraryCategoriesListLoaded:
      libraryCategoriesListState.lastUpdated &&
      !libraryCategoriesListState.loading,
  }
}
