export const PARTICIPANT_STATES = {
  subscribed: "subscribed",
  reserved: "reserved",
  waitingList: "waiting_list",
  approved: "approved",
}

export const PARTICIPANT_STATES_DESCRIPTIONS = {
  [PARTICIPANT_STATES.subscribed]: "Подписка",
  [PARTICIPANT_STATES.reserved]: "Резерв",
  [PARTICIPANT_STATES.waitingList]: "Лист ожидания",
  [PARTICIPANT_STATES.approved]: "Подтверждение",
}
