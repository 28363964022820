import React, { useState } from "react"
import { useDispatch } from "react-redux"
import "url-search-params-polyfill"
import { useHistory } from "react-router-dom"
import { Button, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import LibraryListFilters from "./Filters"
import { resetLibraryPages } from "../actions"
import { Loader, Separator } from "core/components"
import { formatTextByNumber } from "core/utils/text"
import { parseSearchString } from "core/utils/url"
import { useCoursesList } from "courses"
import { useLibraryListState, useLibraryPage } from "library"
import { useLibraryCategoriesList } from "library-categories"

const useStyles = makeStyles((theme) => ({
  total: {
    color: theme.colors.lightText,
    fontStyle: "italic",
  },

  categoryButton: {
    fontSize: 12,
    borderRadius: 10,
    color: theme.colors.lightText,
    padding: theme.spacing(0, 1),
  },

  filtersHead: {
    "& div": {
      textAlign: "center",
    },

    "& div:first-child": {
      textAlign: "left",
    },

    "& div:last-child": {
      textAlign: "right",
    },
  },

  filterSeparator: {
    marginTop: theme.spacing(4),
    width: "100vw",
    position: "relative",
    left: "50%",
    right: "50%",
    marginLeft: "-50vw",
    marginRight: "-50vw",
  },

  item: {
    padding: theme.spacing(1, 0),
  },

  itemTitle: {
    fontSize: 22,
  },

  pageNumbers: {
    "& div:not(:first-child)": {
      marginLeft: theme.spacing(2),
    },

    "& button": {
      border: `1px solid ${theme.colors.darkBorder}`,
      color: theme.colors.lightText,
      minWidth: "auto",
      width: 50,
      height: 50,
    },

    "& button.active": {
      backgroundColor: theme.colors.darkBackground,
      color: "white",
    },

    "& button.active:hover": {
      backgroundColor: theme.colors.darkBackgroundHover,
    },
  },

  hidden: {
    display: "none",
  },
}))

const ROWS_PER_PAGE = 15

function Library() {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [filters, setFilters] = useState(
    parseSearchString(history.location.search)
  )
  const [filterOpen, setFilterOpen] = useState(Object.keys(filters).length > 0)
  const { currentPage: currentPageFromState, count } =
    useLibraryListState() || {}
  const [currentPage, setCurrentPage] = useState(currentPageFromState || 0)
  const { libraryPage: items, isLibraryPageLoaded } = useLibraryPage(
    currentPage,
    ROWS_PER_PAGE,
    filters
  )

  const onChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const {
    libraryCategoriesList,
    isLibraryCategoriesListLoaded,
  } = useLibraryCategoriesList()

  const { coursesList, isCoursesListLoaded } = useCoursesList()

  if (
    !isLibraryPageLoaded ||
    !isLibraryCategoriesListLoaded ||
    !isCoursesListLoaded
  ) {
    return <Loader />
  }

  const toggleFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const updateFilters = (filters) => {
    setFilters(filters)
    const params = new URLSearchParams()
    Object.keys(filters).forEach((key) => {
      params.append(key, filters[key].join(","))
    })
    history.push(`?${params.toString()}`)
    dispatch(resetLibraryPages())
  }

  const pagesCount = Math.ceil(count / ROWS_PER_PAGE)

  return (
    <div>
      <div className={classes.filter}>
        <Grid container justify="space-between" className={classes.filtersHead}>
          <Grid item xs={3}>
            <Button
              variant={filterOpen ? "contained" : "outlined"}
              onClick={toggleFilter}
            >
              Фильтр
            </Button>
          </Grid>
          <Grid item className={classes.total} xs={6}>
            Всего{" "}
            {formatTextByNumber(count, "материал", "материалов", "материала")}
          </Grid>
          <Grid item xs={3}>
            <Button variant="outlined" onClick={() => setFilters({})}>
              Все материалы
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={filterOpen ? null : classes.hidden}>
        <LibraryListFilters
          categories={libraryCategoriesList}
          courses={coursesList}
          filters={filters}
          setFilters={updateFilters}
        />
      </div>
      <Separator className={classes.filterSeparator} />
      <div>
        {items.map((item) => (
          <div key={item.id}>
            <div className={classes.item}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    className={classes.itemTitle}
                  >
                    {`${item.author || ""}${item.author ? ". " : ""}${
                      item.title
                    }`}
                  </Typography>
                </Grid>
                {item.externalLink || item.file ? (
                  <Grid item>
                    <Link href={item.externalLink || item.file} target="_blank">
                      Скачать
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={3}>
                {item.categories.map((categoryId) => (
                  <Grid item key={`category${categoryId}`}>
                    <Button
                      className={classes.categoryButton}
                      variant="outlined"
                    >
                      {
                        libraryCategoriesList.find((i) => i.id === categoryId)
                          .name
                      }
                    </Button>
                  </Grid>
                ))}
                {item.courses
                  ? item.courses.map((courseId) => (
                      <Grid item key={`course${courseId}`}>
                        <Button
                          className={classes.categoryButton}
                          variant="outlined"
                        >
                          {coursesList.find((i) => i.id === courseId).title}
                        </Button>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </div>
            <Separator />
          </div>
        ))}
      </div>
      {pagesCount > 1 ? (
        <Grid container className={classes.pageNumbers}>
          {[...Array(pagesCount).keys()].map((pageNumber) => (
            <Grid item key={pageNumber}>
              <Button
                onClick={(event) => onChangePage(event, pageNumber)}
                className={pageNumber === currentPage ? "active" : null}
              >
                {pageNumber + 1}
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </div>
  )
}

export default Library
