import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { PARTICIPANT_STATES } from "participants/constants"

import {
  startParticipantsCreation,
  startParticipantsDeletion,
  createParticipantStateSelector,
  deleteParticipantStateSelector,
} from "features/participants"

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: 21,
    color: theme.colors.lightText,
    fontStyle: "italic",
    marginBottom: 20,
  },
}))

function ParticipantUpdatingForm(props) {
  const classes = useStyles()
  const { participant, courseId } = props

  const dispatch = useDispatch()
  const participate = useCallback((event) => {
    event.preventDefault()
    dispatch(startParticipantsCreation(courseId))
  })
  const unparticipate = useCallback((event) => {
    event.preventDefault()
    dispatch(startParticipantsDeletion(courseId))
  })

  const creationState = useSelector(createParticipantStateSelector)
  const deletionState = useSelector(deleteParticipantStateSelector)

  const handleWaitingListState = () => (
    <div>
      <div className={classes.message}>
        Вы находитесь в очереди на данный курс.
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={unparticipate}
        disabled={deletionState && deletionState.loading}
      >
        Отказаться от участия
      </Button>
    </div>
  )

  const handleReservedState = () => (
    <div>
      <div className={classes.message}>
        За Вами зарезервировано место на данном курсе. Для подтверждения своего
        участия необходимо оплатить обучение
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={unparticipate}
        disabled={deletionState && deletionState.loading}
      >
        Отказаться от участия
      </Button>
    </div>
  )

  const handleApprovedState = () => (
    <div className={classes.message}>
      Ваше участие в данном курсе подтверждено. Ожидайте новостей по почте.
    </div>
  )

  const handleSubscribedState = () => (
    <div>
      <div className={classes.message}>
        Вы получаете новости по данному курсу, но не изъявляли своего желания
        участвовать в нем.
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={participate}
        disabled={creationState && creationState.loading}
      >
        Участвовать в курсе
      </Button>
    </div>
  )

  switch (participant.state) {
    case PARTICIPANT_STATES.waitingList:
      return handleWaitingListState()
    case PARTICIPANT_STATES.reserved:
      return handleReservedState()
    case PARTICIPANT_STATES.approved:
      return handleApprovedState()
    case PARTICIPANT_STATES.subscribed:
      return handleSubscribedState()

    default:
      return null
  }
}

ParticipantUpdatingForm.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    participated_at: PropTypes.string,
    expired_at: PropTypes.string,
    state: PropTypes.string,
  }),
  courseId: PropTypes.number.isRequired,
}

export default ParticipantUpdatingForm
