import React, { useRef } from "react"
import { Redirect } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  Button,
  Grid,
  Link,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { resetUserPasswordUpdating } from "../actions"
import {
  usePasswordUpdateFormHandlers,
  usePasswordUpdatingState,
} from "../hooks"
import { FormError, RouterLink } from "core/components"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },

  input: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(0),
    },
  },
}))

function UserPasswordUpdateForm() {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  const redirect = useRef(false)
  const [values, formHandlers] = usePasswordUpdateFormHandlers()
  const dispatch = useDispatch()
  const { loading, didInvalidate, error } = usePasswordUpdatingState()

  if (didInvalidate) {
    redirect.current = true
    dispatch(resetUserPasswordUpdating())
  }

  if (redirect.current) {
    return <Redirect to={urls.profile()} />
  }

  return (
    <form onSubmit={formHandlers.onSubmit} className={classes.form}>
      <Grid
        container
        spacing={mediumOrHigherScreen ? 5 : 0}
        alignItems="center"
      >
        <Grid item xs={12} lg={4}>
          <TextField
            name="oldPassword"
            label="Текущий пароль"
            value={values.oldPassword}
            type="password"
            onChange={formHandlers.setValueFromEvent("oldPassword")}
            error={!!error.byFields.oldPassword}
            helperText={error.byFields.oldPassword}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            name="newPassword1"
            label="Новый пароль"
            value={values.newPassword1}
            type="password"
            onChange={formHandlers.setValueFromEvent("newPassword1")}
            error={!!error.byFields.newPassword1}
            helperText={error.byFields.newPassword1}
            fullWidth
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            name="newPassword2"
            label="Новый пароль еще раз"
            value={values.newPassword2}
            type="password"
            onChange={formHandlers.setValueFromEvent("newPassword2")}
            error={!!error.byFields.newPassword2}
            helperText={error.byFields.newPassword2}
            fullWidth
            className={classes.input}
          />
        </Grid>
      </Grid>
      {error.byFields.nonFieldErrors ? (
        <FormError>{error.byFields.nonFieldErrors}</FormError>
      ) : null}
      <Grid container spacing={2} style={{ marginTop: 30 }} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            color="primary"
          >
            Сохранить
          </Button>
        </Grid>
        <Grid item>
          <Link
            component={RouterLink}
            to={urls.profile()}
            color="secondary"
            underline="none"
          >
            Отменить
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

export default UserPasswordUpdateForm
