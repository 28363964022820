import { call, put, fork, take } from "redux-saga/effects"

export function* deleteItem(actionTypes, apiFunc, id) {
  yield put({ type: actionTypes.request, id })
  try {
    const details = yield call(apiFunc, id)
    yield put({
      type: actionTypes.success,
      id,
      deletedAt: Date.now(),
      payload: { id, details, deletedAt: Date.now() },
    })
  } catch (error) {
    yield put({
      type: actionTypes.failure,
      error: error.message,
      id,
      payload: { error: error.message, status: error.statusCode, id },
    })
  }
}

export function itemDeletionFlowFactory(actionTypes, apiFunc) {
  return function* () {
    while (true) {
      const action = yield take(actionTypes.fetch)
      yield fork(
        deleteItem,
        actionTypes,
        apiFunc(action),
        action.data || action.payload.id
      )
      yield take(actionTypes.request)
      yield take([actionTypes.success, actionTypes.failure])
    }
  }
}
