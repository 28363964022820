import { useState } from "react"

export function useFormHandlers(initialState, replaceNullWithUndefined) {
  if (replaceNullWithUndefined) {
    Object.keys(initialState).forEach((key) => {
      if (initialState[key] === null) {
        initialState[key] = undefined
      }
    })
  }
  const [values, setValues] = useState(initialState)

  const setValueFromEvent = (name) => (event) => {
    const target = event.target
    let value = null
    if (target.type === "select-multiple") {
      value = []
      for (let option of target.options) {
        if (option.selected) value.push(option.value)
      }
    } else if (target.type === "checkbox") {
      value = target.checked
    } else {
      value = target.value
    }
    setValues((values) => ({
      ...values,
      [name]: value,
    }))
  }

  const resetValues = () => {
    setValues(() => initialState)
  }

  return [
    values,
    {
      setValueFromEvent,
      resetValues,
    },
  ]
}
