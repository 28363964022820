import { libraryEntitiesSelector } from "../entities"

export function libraryListPageStateSelector(state, page) {
  return state.local.library.list.pages[page]
}

export function libraryCurrentPageSelector(state) {
  return state.local.library.list.currentPage
}

export function libraryListStateSelector(state) {
  return state.local.library.list
}

export function libraryPageSelector(state, page) {
  const pageState = libraryListPageStateSelector(state, page)
  const items = pageState ? pageState.items : []
  const entities = libraryEntitiesSelector(state)
  return items.map((id) => entities[id])
}
