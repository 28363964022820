import React from "react"
import PropTypes from "prop-types"

import BigHeader from "./BigHeader"
import Error from "./Error"
import Loader from "./Loader"
import { useDiscipleDetails } from "disciples"
import { useLoginInfo, useUserDetails } from "users"

function BigHeaderLayout({ children, isDataLoaded, isErrorHappened }) {
  const { isLoggedIn } = useLoginInfo()
  const { userDetails, isUserDetailsLoaded } = useUserDetails()
  const { discipleDetailsState, isDiscipleDetailsLoaded } = useDiscipleDetails()

  let accountInfoLoaded = !isLoggedIn
  if (isLoggedIn && isUserDetailsLoaded) {
    const { author } = userDetails
    if (author) {
      accountInfoLoaded = true
    } else {
      accountInfoLoaded = isDiscipleDetailsLoaded
    }
  }

  let mainContent
  if (isDataLoaded && accountInfoLoaded) {
    if (
      isErrorHappened ||
      (isLoggedIn && discipleDetailsState && discipleDetailsState.error)
    ) {
      mainContent = <Error errorText="Упс... что-то пошло не так" />
    } else {
      mainContent = children
    }
  } else {
    mainContent = <Loader />
  }

  return (
    <React.Fragment>
      <BigHeader
        isLoggedIn={isLoggedIn}
        firstName={userDetails && userDetails.firstName}
        lastName={userDetails && userDetails.lastName}
        author={!!userDetails && !!userDetails.author}
      />
      {mainContent}
    </React.Fragment>
  )
}

BigHeaderLayout.propTypes = {
  isDataLoaded: PropTypes.bool.isRequired,
  isErrorHappened: PropTypes.bool.isRequired,
}

export default BigHeaderLayout
