import React from "react"
import PropTypes from "prop-types"
import { Button, Grid, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { RouterLink } from "core/components"
import translate from "core/translate"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  profile: {
    marginTop: 60,
  },
  buttons: {
    marginTop: 40,
  },
  exitButton: {
    color: "white",
    backgroundColor: theme.colors.lightButtonBackground,

    "&:hover": {
      backgroundColor: theme.colors.lightButtonBackgroundHover,
      color: "white",
    },
  },
}))

function AdultProfile(props) {
  const classes = useStyles()
  const {
    firstName,
    lastName,
    email,
    yearOfBirth,
    country,
    language,
    timezone,
    otherInfo,
    onLogout,
  } = props
  return (
    <div className={classes.profile}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Имя участника"
            value={firstName}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Фамилия участника"
            value={lastName}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="E-mail"
            value={email}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Год рождения"
            value={
              yearOfBirth === 0
                ? `раньше ${new Date().getFullYear() - 18}го`
                : yearOfBirth
            }
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Страна проживания"
            value={country}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Основной язык общения"
            value={translate(language)}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Часовой пояс"
            value={timezone}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Другая важная информация"
            value={otherInfo}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        className={classes.buttons}
        spacing={2}
      >
        <Button
          component={RouterLink}
          to={urls.profileEditor()}
          variant="contained"
          color="primary"
        >
          Редактировать
        </Button>
        <form id="logout-form" onSubmit={onLogout}>
          <Button
            variant="contained"
            type="submit"
            className={classes.exitButton}
          >
            Выйти
          </Button>
        </form>
      </Grid>
    </div>
  )
}

AdultProfile.propTypes = {
  country: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  language: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  otherInfo: PropTypes.string,
  timezone: PropTypes.string,
  yearOfBirth: PropTypes.number,
}

AdultProfile.defaultProps = {
  country: "-",
  language: "-",
  otherInfo: "-",
  timezone: "-",
  yearOfBirth: 0,
}

export default AdultProfile
