export function courseLessonsListSelector(state, courseId) {
  const { lessons } = state.entities
  const courseLessonsState = state.local.lessons.byCourseId[courseId]
  return courseLessonsState
    ? courseLessonsState.items.map((lessonId) => lessons[lessonId])
    : null
}

export function courseLessonsListStateSelector(state, courseId) {
  return state.local.lessons.byCourseId[courseId]
}
