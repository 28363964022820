import listActionTypes from "./actiontypes"

const { FETCH_LIST, RESET_PAGES } = listActionTypes

export function fetchLibraryList(page, limit, filters) {
  return {
    type: FETCH_LIST,
    page,
    limit,
    filters,
  }
}

export function resetLibraryPages() {
  return {
    type: RESET_PAGES,
  }
}
