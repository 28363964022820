export function dictionariesStateSelector(state) {
  return state.local.dictionaries
}

export function countriesSelector(state) {
  let countries = dictionariesStateSelector(state).countries.concat()
  return countries.sort((c1, c2) => {
    if (c1[1] < c2[1]) return -1
    else return 1
  })
}

export function localizedCountryNameSelector(state, country) {
  const countries = dictionariesStateSelector(state).countries
  const countryDescription = countries.find((c) => c[0] === country)
  return countryDescription ? countryDescription[1] : country
}

export function timezonesSelector(state) {
  return dictionariesStateSelector(state).timezones
}
