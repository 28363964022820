import React from "react"
import PropTypes from "prop-types"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import FeedbacksListItem from "./Item"
import FeedbacksListLoadMoreButton from "./LoadMoreButton"
import { FeedbackType } from "../../types"
import { AuthorType } from "authors"
import { Separator } from "core/components"
import { CourseType } from "courses"

const useStyles = makeStyles((theme) => ({
  itemsWrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
      padding: theme.spacing(0),
    },
  },
  separator: {
    width: "100%",
    marginTop: theme.spacing(5),
  },
}))

function FeedbacksList(props) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  const { feedbacks, loadNextPage, loading } = props

  const feedbacksItems = feedbacks.map((feedback, index) => {
    const itemProps = {
      author: feedback.author,
      authorAge: feedback.authorAge,
      course: feedback.course,
      courseAuthors: feedback.courseAuthors,
      footer: feedback.footer,
      text: feedback.text,
    }
    if (index === feedbacks.length - 1) {
      return <FeedbacksListItem key={feedback.id} {...itemProps} />
    } else {
      return (
        <FeedbacksListItem key={feedback.id} {...itemProps}>
          {mediumOrHigherScreen ? (
            <Separator className={classes.separator} />
          ) : null}
        </FeedbacksListItem>
      )
    }
  })
  return (
    <React.Fragment>
      <section className={classes.itemsWrapper}>{feedbacksItems}</section>
      {loadNextPage ? (
        <FeedbacksListLoadMoreButton
          loadNextPage={loadNextPage}
          loading={loading}
        />
      ) : null}
    </React.Fragment>
  )
}

FeedbacksList.propTypes = {
  feedbacks: PropTypes.arrayOf(
    PropTypes.shape({
      ...FeedbackType,
      course: PropTypes.shape(CourseType),
      courseAuthors: PropTypes.arrayOf(PropTypes.shape(AuthorType)),
    })
  ).isRequired,
  loadNextPage: PropTypes.func,
  loading: PropTypes.bool.isRequired,
}

export default FeedbacksList
