import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { BigHeaderLayout, Footer } from "core/components"
import { UserSignUpForm } from "users"

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 570,
    margin: "0 auto",
    padding: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  title: theme.presets.title,
}))

function ScreensUserSignUp() {
  const classes = useStyles()
  return (
    <BigHeaderLayout isDataLoaded isErrorHappened={false}>
      <div className={classes.content}>
        <Typography variant="h1" align="center" className={classes.title}>
          РЕГИСТРАЦИЯ НОВОГО УЧАСТНИКА
        </Typography>
        <UserSignUpForm />
      </div>
      <Footer />
    </BigHeaderLayout>
  )
}

export default ScreensUserSignUp
