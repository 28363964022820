import UPDATING_ACTION_TYPES from "./actiontypes"
import { updatingReducerFactory } from "core/factories/reducers"

const {
  UPDATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  RESET,
  INVALIDATE,
} = UPDATING_ACTION_TYPES

const updatingReducer = updatingReducerFactory({
  updateRequest: UPDATE_REQUEST,
  updateSuccess: UPDATE_SUCCESS,
  updateFailure: UPDATE_FAILURE,
  reset: RESET,
  invalidate: INVALIDATE,
})

export default updatingReducer
