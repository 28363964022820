import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import AdultProfile from "./AdultProfile"
import ChildProfile from "./ChildProfile"
import { logoutUser } from "users"

function DiscipleProfile(props) {
  const dispatch = useDispatch()
  const logout = (event) => {
    event.preventDefault()
    dispatch(logoutUser())
  }

  const currentYear = new Date().getFullYear()
  if (props.yearOfBirth && currentYear - props.yearOfBirth < 18) {
    return <ChildProfile {...props} onLogout={logout} />
  } else {
    return <AdultProfile {...props} onLogout={logout} />
  }
}

DiscipleProfile.propTypes = {
  country: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  language: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  otherInfo: PropTypes.string,
  timezone: PropTypes.string,
  yearOfBirth: PropTypes.number,
}

export default DiscipleProfile
