import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { STATE_IN_PROGRESS } from "../constants"

import { fetchCurrentPayment, invalidateCurrentPayment } from "./actions"
import { paymentCurrentStateSelector } from "./selectors"
import { useLoginInfo } from "users"

export function useCurrentPayment() {
  const dispatch = useDispatch()

  const { isLoggedIn } = useLoginInfo()
  const getCurrent = () => {
    if (isLoggedIn) {
      dispatch(fetchCurrentPayment())
    }
  }

  const { didInvalidate, lastUpdated } = useSelector(
    paymentCurrentStateSelector
  )
  useEffect(getCurrent, [didInvalidate])

  const currentState = useSelector(paymentCurrentStateSelector)

  useEffect(() => {
    let refresher = false

    if (
      currentState.details &&
      currentState.details.state === STATE_IN_PROGRESS
    ) {
      refresher = setTimeout(() => {
        dispatch(invalidateCurrentPayment())
      }, 5000)
    }

    return () => {
      if (refresher) clearTimeout(refresher)
    }
  }, [lastUpdated])

  return {
    ...currentState,
    isCurrentPaymentLoaded: !!currentState && !currentState.loading,
  }
}
