import React from "react"
import { NavLink, Switch, Redirect, Route } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import ScreensAccountCourses from "./Courses"
import ScreensAccountProfileEditor from "./ProfileEditor"
import ScreensAccountProfile from "./Profile"
import { BigHeaderLayout, Footer } from "core/components"
import urls from "core/urls"
import { useLoginInfo } from "users"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  content: {
    borderBottom: `1px solid ${theme.colors.lightBorder}`,
    borderLeft: `1px solid ${theme.colors.lightBorder}`,
    borderRight: `1px solid ${theme.colors.lightBorder}`,
    maxWidth: 1167,
    margin: "0 auto",
    padding: theme.spacing(6, 0, 9, 0),
  },

  tab: {
    textAlign: "center",
    backgroundColor: theme.colors.lightBackground,
    height: 60,
    lineHeight: "60px",
    color: theme.colors.darkText,
    fontWeight: 500,
    fontSize: 21,
    cursor: "pointer",
    textDecoration: "none",

    "&.active": {
      borderTop: `1px solid ${theme.colors.lightBorder}`,
      borderLeft: `1px solid ${theme.colors.lightBorder}`,
      borderRight: `1px solid ${theme.colors.lightBorder}`,
      backgroundColor: "white",
      height: 72,
      lineHeight: "72px",
    },

    "&:hover": {
      color: theme.colors.darkText,
      textDecoration: "none",
    },
  },

  tabBar: {
    margin: `${theme.spacing(5)}px auto 0 auto`,
    maxWidth: 1167,

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(10)}px auto 0 auto`,
    },
  },
}))

function ScreensAccount() {
  const classes = useStyles()
  const { isLoggedIn } = useLoginInfo()
  if (!isLoggedIn) {
    return <Redirect to={urls.login()} />
  }

  return (
    <React.Fragment>
      <BigHeaderLayout isDataLoaded isErrorHappened={false}>
        <div className={classes.root}>
          <Grid container alignItems="flex-end" className={classes.tabBar}>
            <Grid
              item
              xs={6}
              className={classes.tab}
              component={NavLink}
              to={urls.profile()}
            >
              Личный кабинет
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.tab}
              component={NavLink}
              to={urls.discipleCourses()}
            >
              Курсы
            </Grid>
          </Grid>
          <div className={classes.content}>
            <Switch>
              <Route
                exact
                path={`${urls.profile()}`}
                component={ScreensAccountProfile}
              />
              <Route
                exact
                path={`${urls.profileEditor()}`}
                component={ScreensAccountProfileEditor}
              />
              <Route
                exact
                path={`${urls.discipleCourses()}`}
                component={ScreensAccountCourses}
              />
            </Switch>
          </div>
        </div>
        <Footer />
      </BigHeaderLayout>
    </React.Fragment>
  )
}

export default ScreensAccount
