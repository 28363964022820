import React from "react"
import PropTypes from "prop-types"
import { CourseTypeNested } from "courses"
import { LessonType } from "lessons"
import { makeStyles } from "@material-ui/styles"

import { useLoginInfo } from "users"
import {
  MailoutsSubscribeButton,
  MailoutsUnsubscribeButton,
} from "courses/mailouts"
import PaymentCreationForm from "payments/components/CreationForm"
import ParticipantCreationForm from "./CreationForm"
import ParticipantUpdatingForm from "./UpdatingForm"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  header: {
    fontSize: 27,
    lineHeight: 1.33,
    marginBottom: 20,
  },

  subSpace: {
    paddingTop: 20,
  },

  status: {
    paddingBottom: 14,
  },
}))

function calculateLessonsNumber(blockSize, listLength) {
  return blockSize > 1 ? listLength - (listLength % blockSize) : listLength
}

function ParticipantForm(props) {
  const classes = useStyles()
  const { isLoggedIn } = useLoginInfo()
  const {
    courseDetails,
    lessonsList,
    availableLessons,
    paymentHandlers,
    loading,
    purchasedLessons,
    allLessons,
  } = props

  const isManualSubscriptionCourse = courseDetails.prices.length === 0
  const isSubscribableForMailouts = purchasedLessons.length === 0
  const isCoursePriceAvailable =
    courseDetails.lessonsBlockSize > 1
      ? availableLessons.length ===
        calculateLessonsNumber(
          courseDetails.lessonsBlockSize,
          lessonsList.length
        )
      : true
  const areLessonsFull = (list) => !list.find((lesson) => lesson.hasFreePlaces)

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {purchasedLessons.length > 0
          ? "Участник курса"
          : areLessonsFull(allLessons)
          ? "Все места раскуплены"
          : "Запись на курс открыта"}
      </div>
      {purchasedLessons.length > 0 && (
        <div className={classes.status}>
          Вам доступн
          {purchasedLessons.length === lessonsList.length
            ? "ы все лекции"
            : `о ${purchasedLessons.length} из ${calculateLessonsNumber(
                courseDetails.lessonsBlockSize,
                lessonsList.length
              )} лекций${
                paymentHandlers.canMakePayment
                  ? ", и при желании вы можете приобрести остальные за"
                  : ""
              }`}
        </div>
      )}
      {paymentHandlers.canMakePayment && (
        <PaymentCreationForm
          courseDetails={courseDetails}
          lessonsList={availableLessons}
          isCoursePriceAvailable={isCoursePriceAvailable}
          selectedLessons={paymentHandlers.selectedLessons}
          canMakePayment={paymentHandlers.canMakePayment}
          canSelectLessons={paymentHandlers.canSelectLessons}
          selectedCurrency={paymentHandlers.selectedCurrency}
          setSelectedCurrency={paymentHandlers.setSelectedCurrency}
          createPayment={paymentHandlers.createPayment}
          loading={loading}
        />
      )}
      {isManualSubscriptionCourse &&
        isLoggedIn &&
        allLessons.length > 0 &&
        (allLessons[0].participationState ? (
          <ParticipantUpdatingForm
            participationState={allLessons[0].participationState}
            courseId={courseDetails.id}
          />
        ) : (
          <ParticipantCreationForm courseId={courseDetails.id} />
        ))}
      <div className={classes.subSpace} />
      {isSubscribableForMailouts &&
        isLoggedIn &&
        (courseDetails.subscribedForMailouts ? (
          <MailoutsUnsubscribeButton course={courseDetails.id} />
        ) : (
          <MailoutsSubscribeButton course={courseDetails.id} />
        ))}
    </div>
  )
}

ParticipantForm.propTypes = {
  courseDetails: PropTypes.shape(CourseTypeNested).isRequired,
  lessonsList: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
  availableLessons: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
  paymentHandlers: PropTypes.object.isRequired,
  purchasedLessons: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export default ParticipantForm
