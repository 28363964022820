import DICTIONARIES_ACTION_TYPES from "./actiontypes"

const {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
} = DICTIONARIES_ACTION_TYPES

function dictionariesReducer(
  state = {
    loading: false,
    didInvalidate: false,
    lastUpdated: undefined,
    timezones: [],
    countries: [],
  },
  action
) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastUpdated: action.receivedAt,
        timezones: action.dictionaries.timezones,
        countries: action.dictionaries.countries,
      }
    }

    case FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
        lastUpdated: Date.now(),
      }
    }

    default:
      return state
  }
}

export default dictionariesReducer
