import { omit } from "lodash"
import { normalize } from "normalizr"

const initState = {
  loading: false,
  didInvalidate: false,
  items: [],
}

function pageReducer(state = initState, action, actionTypes, schema) {
  switch (action.type) {
    case actionTypes["listRequest"]:
      return {
        ...state,
        loading: true,
      }

    case actionTypes["listSuccess"]:
      const normalizedData = normalize(action.items, [schema])
      return {
        ...omit(state, ["error"]),
        loading: false,
        didInvalidate: false,
        items: normalizedData.result,
        lastUpdated: action.receivedAt,
      }

    case actionTypes["listFailure"]:
      return {
        ...state,
        loading: false,
        error: action.error,
        lastUpdated: action.receivedAt,
      }

    case actionTypes["invalidateList"]:
      return {
        ...omit(state, ["error"]),
        didInvalidate: true,
      }

    default:
      return state
  }
}

export default function paginatedListReducerFactory(actionTypes, schema) {
  const supportedActions = [
    "listRequest",
    "listSuccess",
    "listFailure",
    "invalidateList",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return function (
    state = {
      pages: {},
      currentPage: null,
      nextPage: null,
    },
    action
  ) {
    switch (action.type) {
      case actionTypes["listRequest"]:
      case actionTypes["listFailure"]:
      case actionTypes["invalidateList"]:
        return {
          ...state,
          pages: {
            ...state.pages,
            [action.page]: pageReducer(state.page, action, actionTypes, schema),
          },
        }

      case actionTypes["listSuccess"]:
        return {
          ...state,
          pages: {
            ...state.pages,
            [action.page]: pageReducer(state.page, action, actionTypes, schema),
          },
          currentPage: action.page,
          nextPage: action.nextPage,
          count: action.count,
        }

      case actionTypes["resetPages"]:
        return {
          ...state,
          pages: {},
        }

      default:
        return state
    }
  }
}
