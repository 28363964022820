import React from "react"
import PropTypes from "prop-types"
import { Button, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import {
  DISCIPLES_AGE_ADULTS,
  DISCIPLES_AGE_CHILDREN,
} from "disciples/constants"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  buttons: {
    "& > div": {
      padding: theme.spacing(1, 3),
    },
  },

  active: {
    backgroundColor: theme.colors.lightBackground,
  },
}))

function CoursesFilter({ filter, setFilter }) {
  const classes = useStyles()
  const onButtonClick = (event, value) => {
    event.preventDefault()
    setFilter(value)
  }
  return (
    <div className={classes.root}>
      <Grid container justify="center" className={classes.buttons}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(event) => onButtonClick(event, "")}
            className={filter === "" ? classes.active : null}
          >
            Все
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(event) => onButtonClick(event, DISCIPLES_AGE_CHILDREN)}
            className={
              filter === DISCIPLES_AGE_CHILDREN ? classes.active : null
            }
          >
            Для детей
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={(event) => onButtonClick(event, DISCIPLES_AGE_ADULTS)}
            className={filter === DISCIPLES_AGE_ADULTS ? classes.active : null}
          >
            Для взрослых
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

CoursesFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default CoursesFilter
