import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchDiscipleDetails } from "./actions"
import {
  discipleDetailsSelector,
  discipleDetailsStateSelector,
  discipleProfileSelector,
} from "./selectors"
import { useUserDetails } from "users"

const emptyDetails = {
  discipleDetails: undefined,
  discipleDetailsState: undefined,
  discipleProfile: undefined,
  isDiscipleDetailsLoaded: false,
}

export function useDiscipleDetails(replaceNullWithUndefined) {
  const { userDetails } = useUserDetails()
  const dispatch = useDispatch()
  const getDetails = useCallback(() => {
    if (userDetails && userDetails.disciple) {
      dispatch(fetchDiscipleDetails(userDetails.disciple))
    }
  }, [userDetails])
  useEffect(getDetails, [getDetails])

  const detailsState = useSelector((state) =>
    discipleDetailsStateSelector(
      state,
      userDetails ? userDetails.disciple : undefined
    )
  )
  // get copy otherwise state mutation will happen later
  const discipleDetails = {
    ...useSelector((state) =>
      discipleDetailsSelector(
        state,
        userDetails ? userDetails.disciple : undefined
      )
    ),
  }
  const discipleProfile = useSelector((state) =>
    discipleProfileSelector(
      state,
      userDetails ? userDetails.disciple : undefined
    )
  )

  if (!userDetails) {
    return emptyDetails
  }
  if (!detailsState) {
    return emptyDetails
  }
  if (!discipleDetails) {
    return emptyDetails
  }

  if (replaceNullWithUndefined) {
    Object.keys(discipleDetails).forEach((key) => {
      if (discipleDetails[key] === null) {
        discipleDetails[key] = undefined
      }
    })
  }

  return {
    discipleDetails,
    discipleDetailsState: detailsState,
    discipleProfile,
    isDiscipleDetailsLoaded:
      !!detailsState.lastUpdated && !detailsState.loading,
  }
}
