import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { UserPasswordResetCompletionForm } from "users"
import { BigHeaderLayout, Footer } from "core/components"

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 570,
    margin: "0 auto",
    padding: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  title: theme.presets.title,
}))

function ScreensUserPasswordResetCompletion(props) {
  const classes = useStyles()
  const { uidb64, token } = props.match.params
  return (
    <BigHeaderLayout isDataLoaded isErrorHappened={false}>
      <div className={classes.content}>
        <Typography variant="h1" align="center" className={classes.title}>
          ВОССТАНОВЛЕНИЕ ПАРОЛЯ
        </Typography>
        <UserPasswordResetCompletionForm token={token} uidb64={uidb64} />
      </div>
      <Footer />
    </BigHeaderLayout>
  )
}

export default ScreensUserPasswordResetCompletion
