import React, { useRef } from "react"
import { Redirect } from "react-router-dom"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { useLoginInfo } from "../login"
import { useSignUpFormHandlers, useSignUpState } from "../signup"
import { FormError, RouterLink } from "core/components"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > div:not(:first-child)": {
      marginTop: theme.spacing(1),

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
      },
    },

    "& > button": {
      marginTop: theme.spacing(1.5),
    },
  },

  agreement: {
    marginTop: theme.spacing(1.5),
  },
}))

function UserSignUpForm() {
  const classes = useStyles()
  const [
    {
      email,
      firstName,
      lastName,
      password1,
      password2,
      yearOfBirth,
      yearOfBirthOptions,
      agreement,
    },
    { setValueFromEvent, reset, signUp },
  ] = useSignUpFormHandlers()

  const { loading, completed, error } = useSignUpState()
  const { isLoggedIn } = useLoginInfo()
  const signupCompleted = useRef(false)

  if (isLoggedIn) {
    return <Redirect to={urls.home()} />
  }

  if (completed) {
    signupCompleted.current = true
    reset()
  }

  if (signupCompleted.current) {
    return <Redirect to={urls.signupEmailSent()} />
  }

  return (
    <form onSubmit={signUp} className={classes.form}>
      <TextField
        name="email"
        label="E-mail"
        value={email}
        onChange={setValueFromEvent("email")}
        fullWidth
        error={!!error.byFields.email}
        helperText={error.byFields.email}
        autoComplete="username"
      />
      <TextField
        name="firstName"
        label="Имя"
        value={firstName}
        onChange={setValueFromEvent("firstName")}
        fullWidth
        error={!!error.byFields.firstName}
        helperText={error.byFields.lastName}
        autoComplete="name"
      />
      <TextField
        name="lastName"
        label="Фамилия"
        value={lastName}
        onChange={setValueFromEvent("lastName")}
        fullWidth
        error={!!error.byFields.lastName}
        helperText={error.byFields.lastName}
        autoComplete="family-name"
      />
      <TextField
        name="password1"
        label="Пароль"
        value={password1}
        onChange={setValueFromEvent("password1")}
        error={!!error.byFields.password1}
        helperText={error.byFields.password1}
        fullWidth
        type="password"
        autoComplete="new-password"
      />
      <TextField
        name="password2"
        label="Ещё раз пароль"
        value={password2}
        onChange={setValueFromEvent("password2")}
        error={!!error.byFields.password2}
        helperText={error.byFields.password2}
        fullWidth
        type="password"
        autoComplete="new-password"
      />
      <FormControl fullWidth error={!!error.byFields.yearOfBirth}>
        <InputLabel htmlFor="year-of-birth">Год рождения</InputLabel>
        <Select
          onChange={setValueFromEvent("yearOfBirth")}
          inputProps={{
            name: "yearOfBirth",
            id: "year-of-birth",
          }}
          value={yearOfBirth}
        >
          {yearOfBirthOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year === 0
                ? `раньше ${Math.min(
                    ...yearOfBirthOptions.filter((el) => el !== 0)
                  )}го`
                : year}
            </MenuItem>
          ))}
        </Select>
        {error.byFields.yearOfBirth ? (
          <FormHelperText>{error.byFields.yearOfBirth}</FormHelperText>
        ) : null}
      </FormControl>
      <FormControl error={!!error.byFields.agreement}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="agreement"
              checked={agreement}
              onChange={setValueFromEvent("agreement")}
            />
          }
          label={
            <div>
              <span>Принимаю условия </span>
              <Link component={RouterLink} to={urls.policy()}>
                политики конфиденциальности
              </Link>
            </div>
          }
          className={classes.agreement}
        />
        {error.byFields.agreement ? (
          <FormHelperText style={{ textAlign: "left", marginLeft: 34 }}>
            {error.byFields.agreement}
          </FormHelperText>
        ) : null}
      </FormControl>
      {error.byFields.nonFieldErrors ? (
        <FormError>{error.byFields.nonFieldErrors}</FormError>
      ) : null}
      <Button variant="contained" type="submit" fullWidth disabled={loading}>
        Зарегистрироваться
      </Button>
    </form>
  )
}

export default UserSignUpForm
