import React, { useState } from "react"
import PropTypes from "prop-types"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import CoursesFilter from "./Filter"
import CoursesGridItem from "./GridItem"
import { CourseType } from "../../types"
import { COURSE_STATES } from "../../constants"
import { AuthorType } from "authors"

const useStyles = makeStyles((theme) => ({
  grid: {
    maxWidth: 1167,
    margin: "0 auto",
  },
  filters: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
}))

function CoursesGrid({ courses, withFilters }) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))

  const [filter, setFilter] = useState("")

  const randomizeOrder = (courses) => {
    const canBeSubscribedCourses = []
    const announcedCourses = []
    courses.forEach((course) => {
      if (course.state === COURSE_STATES.canBeSubscribed) {
        canBeSubscribedCourses.push(course)
      } else if (course.state === COURSE_STATES.announced) {
        announcedCourses.push(course)
      }
    })
    return canBeSubscribedCourses.concat(announcedCourses)
  }

  const randomizedCourses = randomizeOrder(courses)

  return (
    <div>
      {withFilters ? (
        <div className={classes.filters}>
          <CoursesFilter filter={filter} setFilter={setFilter} />
        </div>
      ) : null}
      <Grid
        className={classes.grid}
        container
        spacing={mediumOrHigherScreen ? 3 : 1}
      >
        {randomizedCourses
          .filter((course) => !course.title.toLowerCase().includes("тестовый"))
          .map((course) => {
            return !filter || course.disciplesAge === filter ? (
              <CoursesGridItem
                key={course.id}
                id={course.id}
                age={course.disciplesAge}
                authors={course.authors}
                start={course.start}
                state={course.state}
                subtitle={course.subtitle}
                thumbnail={course.thumbnail}
                title={course.title}
              />
            ) : null
          })}
      </Grid>
    </div>
  )
}

CoursesGrid.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      ...CourseType,
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: AuthorType.firstName,
          lastName: AuthorType.lastName,
        })
      ).isRequired,
    })
  ),
  withFilters: PropTypes.bool,
}

CoursesGrid.defaultProps = {
  withFilters: false,
}

export default CoursesGrid
