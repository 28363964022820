import PropTypes from "prop-types"

export const LessonType = {
  id: PropTypes.number.isRequired,
  course: PropTypes.number.isRequired,
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    participated_at: PropTypes.string,
    expired_at: PropTypes.string,
    state: PropTypes.string,
  }),
  duration: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
}
