import { call, fork, put, select, take } from "redux-saga/effects"

import RESET_PASSWORD_ACTION_TYPES from "./actiontypes"
import UsersAPI from "../api"
import { resetPasswordStateSelector } from "./selectors"

const {
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_RESETTING,
  RESET_PASSWORD_COMPLETE,
  RESET_PASSWORD_COMPLETE_REQUEST,
  RESET_PASSWORD_COMPLETE_SUCCESS,
  RESET_PASSWORD_COMPLETE_FAILURE,
  RESET_PASSWORD_RESETTING_COMPLETION,
} = RESET_PASSWORD_ACTION_TYPES

export function resetUserPassword(email) {
  return {
    type: RESET_PASSWORD,
    email,
  }
}

export function resetUserPasswordResetState() {
  return {
    type: RESET_PASSWORD_RESETTING,
  }
}

export function completeUserPasswordResetting(
  uidb64,
  token,
  password1,
  password2
) {
  return {
    type: RESET_PASSWORD_COMPLETE,
    uidb64,
    token,
    password1,
    password2,
  }
}

export function resetUserPasswordResettingCompletion() {
  return {
    type: RESET_PASSWORD_RESETTING_COMPLETION,
  }
}

function* startResetPassword(email) {
  yield put({ type: RESET_PASSWORD_REQUEST })
  try {
    yield call(UsersAPI.resetPassword, email)
    yield put({ type: RESET_PASSWORD_SUCCESS })
  } catch (error) {
    console.debug(error)
    yield put({ type: RESET_PASSWORD_FAILURE, error })
  }
}

function shouldStartResetPassword(resetPasswordState) {
  return !resetPasswordState.start.loading
}

function* startResetPasswordIfNeeded(email) {
  const resetPasswordState = yield select(resetPasswordStateSelector)
  if (shouldStartResetPassword(resetPasswordState)) {
    yield call(startResetPassword, email)
  }
}

export function* startingPasswordResetFlow() {
  while (true) {
    let action = yield take(RESET_PASSWORD)
    yield fork(startResetPasswordIfNeeded, action.email)
    yield take(RESET_PASSWORD_REQUEST)
    yield take([RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE])
  }
}

function* completePasswordReset(uidb64, token, password1, password2) {
  yield put({ type: RESET_PASSWORD_COMPLETE_REQUEST })
  try {
    yield call(
      UsersAPI.resetPasswordComplete,
      uidb64,
      token,
      password1,
      password2
    )
    yield put({ type: RESET_PASSWORD_COMPLETE_SUCCESS })
  } catch (error) {
    console.debug(error)
    yield put({ type: RESET_PASSWORD_COMPLETE_FAILURE, error })
  }
}

function shouldCompletePasswordReset(resetPasswordState) {
  return !resetPasswordState.completion.loading
}

function* completePasswordResetIfNeeded(uidb64, token, password1, password2) {
  const resetPasswordState = yield select(resetPasswordStateSelector)
  if (shouldCompletePasswordReset(resetPasswordState)) {
    yield call(completePasswordReset, uidb64, token, password1, password2)
  }
}

export function* passwordResetCompletionFlow() {
  while (true) {
    let action = yield take(RESET_PASSWORD_COMPLETE)
    const { uidb64, token, password1, password2 } = action
    yield fork(
      completePasswordResetIfNeeded,
      uidb64,
      token,
      password1,
      password2
    )
    yield take(RESET_PASSWORD_COMPLETE_REQUEST)
    yield take([
      RESET_PASSWORD_COMPLETE_SUCCESS,
      RESET_PASSWORD_COMPLETE_FAILURE,
    ])
  }
}
