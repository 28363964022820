import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchFeedbacksList } from "./actions"
import {
  currentFeedbacksPageSelector,
  feedbacksListStateSelector,
  feedbacksPageStateSelector,
  feedbacksForPagesSelector,
} from "./selectors"

export function useFeedbacksPage(pageNumber) {
  const dispatch = useDispatch()

  const fetchFeedbacks = useCallback(() => {
    dispatch(fetchFeedbacksList(pageNumber))
  }, [pageNumber])
  useEffect(fetchFeedbacks, [pageNumber])

  const feedbacksListState = useSelector((state) =>
    feedbacksPageStateSelector(state, pageNumber)
  )
  const feedbacksList = useSelector((state) =>
    feedbacksForPagesSelector(state, [pageNumber])
  )
  return {
    feedbacksList,
    feedbacksListState,
    isFeedbacksListLoaded: !!feedbacksListState && !feedbacksListState.loading,
    nextPage: useSelector(feedbacksListStateSelector).nextPage,
  }
}

export function useFetchedFeedbacks() {
  const currentPage = useSelector(currentFeedbacksPageSelector) || 1
  const feedbacksList = useSelector((state) =>
    feedbacksForPagesSelector(
      state,
      [...Array(currentPage).keys()].map((n) => n + 1)
    )
  )
  return feedbacksList
}

export function useCurrentFeedbacksPage() {
  return useSelector(currentFeedbacksPageSelector) || 1
}
