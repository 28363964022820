import LIST_ACTION_TYPES from "../list/actiontypes"
import { lessonSchema } from "../schema"
import { entitiesReducerFactory } from "core/factories/reducers"
import courseActionTypes from "courses/actiontypes"
import courseSchema from "courses/schema"

import { normalize } from "normalizr"
import { finishParticipantsCreation } from "features/participants/creation/slice"
import { finishParticipantsDeletion } from "features/participants/deletion/slice"

const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES
const {
  FETCH_DETAILS_SUCCESS: FETCH_COURSE_DETAILS_SUCCESS,
  FETCH_LIST_SUCCESS: FETCH_COURSE_LIST_SUCCESS,
} = courseActionTypes

const baseReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_LIST_SUCCESS,
  },
  lessonSchema,
  (normalizedData) => normalizedData.entities.lessons
)

const lessonsFromCourseDetailsReducer = entitiesReducerFactory(
  {
    listFromDetailsSuccess: FETCH_COURSE_DETAILS_SUCCESS,
  },
  courseSchema,
  (normalizedData) => normalizedData.entities.lessons
)

const lessonsFromCourseListReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_COURSE_LIST_SUCCESS,
  },
  courseSchema,
  (normalizedData) => normalizedData.entities.lessons
)

export default function entitiesReducer(state = {}, action) {
  let normalizedData = null
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return baseReducer(state, action)

    case FETCH_COURSE_DETAILS_SUCCESS:
      return lessonsFromCourseDetailsReducer(state, action)

    case FETCH_COURSE_LIST_SUCCESS:
      return lessonsFromCourseListReducer(state, action)

    case finishParticipantsCreation.type:
      normalizedData = normalize(action.payload.details, courseSchema)
      return {
        ...state,
        ...normalizedData.entities.lessons,
      }

    case finishParticipantsDeletion.type:
      normalizedData = normalize(action.payload.details, courseSchema)
      return {
        ...state,
        ...normalizedData.entities.lessons,
      }

    default:
      return state
  }
}
