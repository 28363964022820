import { all, call } from "redux-saga/effects"

import { detailsFetchingFlow } from "./details/actions"
import { loginFlow, logoutFlow } from "./login/actions"
import {
  passwordResetCompletionFlow,
  startingPasswordResetFlow,
} from "./password-reset"
import { updatingPasswordFlow } from "./password-update"
import { signUpConfirmationFlow, signUpFlow } from "./signup/actions"

export function* usersSaga() {
  yield all([
    call(detailsFetchingFlow),
    call(loginFlow),
    call(logoutFlow),
    call(passwordResetCompletionFlow),
    call(signUpConfirmationFlow),
    call(signUpFlow),
    call(startingPasswordResetFlow),
    call(updatingPasswordFlow),
  ])
}

export { fetchUserDetails } from "./details/actions"
export { loginUser, logoutUser } from "./login/actions"
