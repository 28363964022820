import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import AdultProfileForm from "./AdultProfileForm"
import ChildProfileForm from "./ChildProfileForm"
import { resetDiscipleUpdating } from "../../updating/actions"
import { useDiscipleUpdatingFormHandlers } from "../../updating/hooks"
import { updateDiscipleStateSelector } from "../../updating/selectors"
import urls from "core/urls"

function DiscipleProfileForm(props) {
  const redirect = useRef(false)
  const [values, formHandlers] = useDiscipleUpdatingFormHandlers(props.id, {
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    yearOfBirth: props.yearOfBirth,
    country: props.country,
    mainLanguage: props.mainLanguage,
    visitSchool: props.visitSchool,
    readWriteProblems: props.readWriteProblems,
    otherInfo: props.otherInfo,
    timezone: props.timezone,
  })
  const dispatch = useDispatch()
  const updateState = useSelector((state) =>
    updateDiscipleStateSelector(state, props.id)
  )

  if (updateState && updateState.didInvalidate) {
    redirect.current = true
    dispatch(resetDiscipleUpdating(props.id))
  }

  if (redirect.current) {
    return <Redirect to={urls.profile()} />
  }

  const yearsOfBirth = [...Array(11).keys()].map(
    (n) => new Date().getFullYear() - 8 - n
  )
  yearsOfBirth.push(0)

  const currentYear = new Date().getFullYear()
  if (values.yearOfBirth && currentYear - values.yearOfBirth < 18) {
    return (
      <ChildProfileForm
        {...values}
        countries={props.countries}
        timezones={props.timezones}
        yearsOfBirth={yearsOfBirth}
        error={updateState && updateState.error}
        setValueFromEvent={formHandlers.setValueFromEvent}
        onSubmit={formHandlers.onSubmit}
      />
    )
  } else {
    return (
      <AdultProfileForm
        {...values}
        countries={props.countries}
        timezones={props.timezones}
        yearsOfBirth={yearsOfBirth}
        error={updateState && updateState.error}
        setValueFromEvent={formHandlers.setValueFromEvent}
        onSubmit={formHandlers.onSubmit}
      />
    )
  }
}

DiscipleProfileForm.propTypes = {
  country: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string).isRequired)
    .isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  language: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  otherInfo: PropTypes.string,
  timezone: PropTypes.string,
  timezones: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string).isRequired)
    .isRequired,
  visitSchool: PropTypes.bool,
  yearOfBirth: PropTypes.number,
}

export default DiscipleProfileForm
