import React, { useCallback, useSelector } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import {
  startParticipantsCreation,
  createParticipantStateSelector,
} from "features/participants"

const useStyles = makeStyles({
  header: {
    fontSize: 27,
    lineHeight: 1.33,
    marginBottom: 20,
  },
  buttonSpace: {
    height: 20,
  },
})

function ParticipantCreationForm({ courseId }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const participate = useCallback((event) => {
    event.preventDefault()
    dispatch(startParticipantsCreation(courseId))
  })

  const creationState = useSelector(createParticipantStateSelector)

  return (
    <div>
      <div className={classes.buttonSpace} />
      <Button
        variant="contained"
        color="primary"
        onClick={participate}
        disabled={creationState && creationState.loading}
      >
        Участвовать в курсе
      </Button>
    </div>
  )
}

ParticipantCreationForm.propTypes = {
  courseId: PropTypes.number.isRequired,
}

export default ParticipantCreationForm
