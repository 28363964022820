import { call, put, fork, select, take } from "redux-saga/effects"

import SIGNUP_ACTION_TYPES from "./actiontypes"
import { signUpConfirmationStateSelector } from "./selectors"
import UsersAPI from "../api"

const {
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  RESET_SIGNUP,

  CONFIRM_SIGNUP,
  CONFIRM_SIGNUP_FAILURE,
  CONFIRM_SIGNUP_REQUEST,
  CONFIRM_SIGNUP_SUCCESS,
} = SIGNUP_ACTION_TYPES

export function signUpUser(data) {
  return {
    type: SIGNUP,
    data,
  }
}

export function resetUserSignUp() {
  return {
    type: RESET_SIGNUP,
  }
}

export function* signUp(data) {
  yield put({ type: SIGNUP_REQUEST })
  try {
    const details = yield call(UsersAPI.signUp, data)
    yield put({
      type: SIGNUP_SUCCESS,
      receivedAt: Date.now(),
      details,
    })
  } catch (error) {
    yield put({ type: SIGNUP_FAILURE, error })
  }
}

export function* signUpFlow() {
  while (true) {
    let { data, type } = yield take([SIGNUP, RESET_SIGNUP])
    if (type === RESET_SIGNUP) {
      continue
    }
    yield fork(signUp, data)
    yield take(SIGNUP_REQUEST)
    yield take([SIGNUP_SUCCESS, SIGNUP_FAILURE])
  }
}

export function confirmUserSignUp(uidb64, token) {
  return {
    type: CONFIRM_SIGNUP,
    uidb64,
    token,
  }
}

export function* confirmSignUp(uidb64, token) {
  yield put({ type: CONFIRM_SIGNUP_REQUEST })
  try {
    yield call(UsersAPI.confirmSignUp, uidb64, token)
    yield put({ type: CONFIRM_SIGNUP_SUCCESS, receivedAt: Date.now() })
  } catch (error) {
    yield put({ type: CONFIRM_SIGNUP_FAILURE, error, receivedAt: Date.now() })
  }
}

export function shouldConfirmSignUp({ loading, lastUpdated }) {
  return !loading && !lastUpdated
}

export function* confirmSignUpIfNeeded(uidb64, token) {
  const confirmationState = yield select(signUpConfirmationStateSelector)
  if (shouldConfirmSignUp(confirmationState)) {
    yield call(confirmSignUp, uidb64, token)
  }
}

export function* signUpConfirmationFlow() {
  while (true) {
    let action = yield take(CONFIRM_SIGNUP)
    const { uidb64, token } = action
    yield fork(confirmSignUpIfNeeded, uidb64, token)
    yield take(CONFIRM_SIGNUP_REQUEST)
    yield take([CONFIRM_SIGNUP_SUCCESS, CONFIRM_SIGNUP_FAILURE])
  }
}
