import { DETAILS_ACTION_TYPES } from "../details"
import { AUTH_ACTION_TYPES } from "../login"
import userSchema from "../schema"
import { SIGNUP_ACTION_TYPES } from "../signup"
import { entitiesReducerFactory } from "core/factories/reducers"

const { LOGOUT_SUCCESS } = AUTH_ACTION_TYPES
const { FETCH_DETAILS_SUCCESS } = DETAILS_ACTION_TYPES
const { SIGNUP_SUCCESS } = SIGNUP_ACTION_TYPES

const userEntitiesReducer = entitiesReducerFactory(
  {
    createSuccess: SIGNUP_SUCCESS,
    detailsSuccess: FETCH_DETAILS_SUCCESS,
    logoutSuccess: LOGOUT_SUCCESS,
  },
  userSchema,
  (normalizedData) => normalizedData.entities.users
)

export default userEntitiesReducer
