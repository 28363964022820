import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { BigHeaderLayout, Footer } from "core/components"
import { UserSignUpConfirmation } from "users"

const useStyles = makeStyles({
  content: {
    maxWidth: 600,
    margin: "0 auto",
  },
  header: {
    margin: "22px 0 50px 0",
    fontStyle: "normal",
  },
})

function ScreensUserSignUpConfirmation(props) {
  const classes = useStyles()
  const { uidb64, token } = props.match.params
  return (
    <React.Fragment>
      <BigHeaderLayout isDataLoaded isErrorHappened={false}>
        <div className={classes.content}>
          <Typography variant="h1" align="center" className={classes.header}>
            ПОДТВЕРЖДЕНИЕ РЕГИСТРАЦИИ
          </Typography>
          <UserSignUpConfirmation
            uidb64={uidb64}
            token={token}
            css={"margin-top: 30px;"}
          />
        </div>
        <Footer />
      </BigHeaderLayout>
    </React.Fragment>
  )
}

export default ScreensUserSignUpConfirmation
