import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { useAuthorsList } from "authors"
import { BigHeaderLayout, Footer, Separator } from "core/components"
import { useCoursesList } from "courses"
import {
  FeedbacksList,
  useCurrentFeedbacksPage,
  useFetchedFeedbacks,
  useFeedbacksPage,
} from "feedbacks"

const useStyles = makeStyles({
  headerSeparator: {
    maxWidth: "90%",
    marginTop: 30,
  },

  content: {
    maxWidth: 900,
    margin: "0 auto",
    textAlign: "center",
  },
})

function ScreensFeedbacks() {
  const classes = useStyles()
  const currentPageFromState = useCurrentFeedbacksPage()
  const [currentPage, setCurrentPage] = useState(currentPageFromState)
  const {
    authorsList,
    authorsListState,
    isAuthorsListLoaded,
  } = useAuthorsList()
  const {
    coursesList,
    coursesListState,
    isCoursesListLoaded,
  } = useCoursesList()
  const { isFeedbacksListLoaded: isFirstPageLoaded } = useFeedbacksPage(1)
  const {
    feedbacksListState,
    isFeedbacksListLoaded: isCurrentPageLoaded,
    nextPage,
  } = useFeedbacksPage(currentPage)
  const feedbacks = useFetchedFeedbacks()

  let isErrorHappened = false
  if (isCurrentPageLoaded) {
    isErrorHappened =
      authorsListState.error ||
      coursesListState.error ||
      feedbacksListState.error
  }

  let mainContent
  const isDataLoaded =
    isAuthorsListLoaded && isCoursesListLoaded && isFirstPageLoaded
  if (isDataLoaded && !isErrorHappened) {
    const formattedFeedbacks = feedbacks.map((feedback) => {
      const formattedFeedback = { ...feedback }
      if (feedback.course) {
        formattedFeedback.course = coursesList.find(
          (c) => c.id === feedback.course
        )
      }
      if (feedback.courseAuthors) {
        formattedFeedback.courseAuthors = feedback.courseAuthors.map(
          (authorId) => authorsList.find((a) => a.id === authorId)
        )
      }
      return formattedFeedback
    })
    mainContent = (
      <React.Fragment>
        <div className={classes.content}>
          <Typography
            variant="h1"
            align="center"
            style={{ margin: "22px 0 50px 0" }}
          >
            Отзывы
          </Typography>
          <FeedbacksList
            feedbacks={formattedFeedbacks}
            loadNextPage={
              nextPage ? () => setCurrentPage(currentPage + 1) : null
            }
            loading={!isCurrentPageLoaded}
          />
        </div>
        <Footer />
      </React.Fragment>
    )
  }

  return (
    <BigHeaderLayout
      isDataLoaded={isDataLoaded}
      isErrorHappened={!!isErrorHappened}
    >
      <Separator className={classes.headerSeparator} />
      {mainContent}
    </BigHeaderLayout>
  )
}

export default ScreensFeedbacks
