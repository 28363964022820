import { call, put, select, take, fork } from "redux-saga/effects"

export function* fetchItems(actionsTypes, apiFunc) {
  yield put({ type: actionsTypes["request"] })
  try {
    const items = yield call(apiFunc)
    yield put({ type: actionsTypes["success"], items, receivedAt: Date.now() })
  } catch (error) {
    yield put({
      type: actionsTypes["failure"],
      error: error.message,
      receivedAt: Date.now(),
      payload: {
        error: error.message,
        status: error.statusCode,
        receivedAt: Date.now(),
      },
    })
  }
}

function shouldFetchItems(listState) {
  if (!listState) {
    return true
  } else if (listState.loading) {
    return false
  } else if (!listState.lastUpdated) {
    return true
  } else {
    return listState.didInvalidate
  }
}

export function* fetchItemsIfNeeded(actionTypes, apiFunc, selectorFunc) {
  const listState = yield select(selectorFunc)
  if (shouldFetchItems(listState)) {
    yield call(fetchItems, actionTypes, apiFunc)
  }
}

/**
 *
 * @param {object} actionTypes - object containing fetch, request, failure and success action types
 * @param {function} apiFuncFromAction - function that takes an action as parameter
 *                             and returns API function for making request
 * @param {function} selectorFuncFromAction - function that takes an action as parameter
 *                                  and returns selector function for getting list state
 */
export function fetchingItemsFlowFactory(
  actionTypes,
  apiFuncFromAction,
  selectorFuncFromAction
) {
  return function* () {
    let action = yield take(actionTypes["fetch"])
    while (true) {
      yield fork(
        fetchItemsIfNeeded,
        actionTypes,
        apiFuncFromAction(action),
        selectorFuncFromAction(action)
      )
      const { type } = yield take([
        actionTypes["fetch"],
        actionTypes["request"],
      ])
      if (type === actionTypes["fetch"]) {
        continue
      }
      yield take([actionTypes["success"], actionTypes["failure"]])
      yield take(actionTypes["fetch"])
    }
  }
}
