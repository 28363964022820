import { omit } from "lodash"
import { combineReducers } from "redux"

import SIGNUP_ACTION_TYPES from "./actiontypes"
import AUTH_ACTION_TYPES from "../login/actiontypes"

const {
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  RESET_SIGNUP,

  CONFIRM_SIGNUP_FAILURE,
  CONFIRM_SIGNUP_REQUEST,
  CONFIRM_SIGNUP_SUCCESS,
} = SIGNUP_ACTION_TYPES

const { LOGIN_SUCCESS } = AUTH_ACTION_TYPES

const initState = {
  loading: false,
  completed: false,
}

export const creationReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case SIGNUP_SUCCESS:
      return {
        ...omit(state, ["error"]),
        loading: false,
        completed: true,
      }

    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case RESET_SIGNUP:
      return { ...initState }

    default:
      return state
  }
}

export const confirmationReducer = (
  state = {
    loading: false,
    error: null,
    lastUpdated: null,
  },
  action
) => {
  switch (action.type) {
    case CONFIRM_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CONFIRM_SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lastUpdated: action.receivedAt,
      }

    case CONFIRM_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        lastUpdated: action.receivedAt,
      }

    default:
      return state
  }
}

export default combineReducers({
  creation: creationReducer,
  confirmation: confirmationReducer,
})
