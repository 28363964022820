import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class ParticipantsAPI {
  createFromCourse(id) {
    return handleErrors(
      fetch(endpoints.courseCreateParticipants(id), {
        method: "post",
        headers: getDefaultHeaders(),
      })
    )
  }

  deleteFromCourse(id) {
    return handleErrors(
      fetch(endpoints.courseDeleteParticipants(id), {
        method: "delete",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new ParticipantsAPI()
