export function discipleDetailsSelector(state, id) {
  return state.entities.disciples[id]
}

export function discipleDetailsStateSelector(state, id) {
  return state.local.disciples.byId[id]
}

export function discipleProfileSelector(state, id) {
  const discipleDetails = discipleDetailsSelector(state, id)
  if (discipleDetails) {
    return state.entities.profiles[discipleDetails.profile]
  } else {
    return undefined
  }
}
