import CURRENT_ACTION_TYPES from "./actiontypes"
import { paymentCurrentStateSelector } from "./selectors"
import PaymentAPI from "../api"
import { currentFetchingFlowFactory } from "core/factories/actions"

const {
  FETCH_CURRENT,
  FETCH_CURRENT_REQUEST,
  FETCH_CURRENT_SUCCESS,
  FETCH_CURRENT_FAILURE,
  INVALIDATE_CURRENT,
} = CURRENT_ACTION_TYPES

export function fetchCurrentPayment() {
  return { type: FETCH_CURRENT }
}

export function invalidateCurrentPayment() {
  return { type: INVALIDATE_CURRENT }
}

export function* currentFetchingFlow() {
  yield* currentFetchingFlowFactory(
    {
      fetch: FETCH_CURRENT,
      request: FETCH_CURRENT_REQUEST,
      success: FETCH_CURRENT_SUCCESS,
      failure: FETCH_CURRENT_FAILURE,
    },
    PaymentAPI.getCurrent,
    paymentCurrentStateSelector
  )()
}
