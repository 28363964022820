import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

import LessonsListItem from "./Item"
import { LessonType } from "../../types"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      marginTop: 50,
      padding: 0,
    },
  },
  title: {
    fontSize: 27,
    lineHeight: 1,

    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
}))

function LessonsList({ lessons }) {
  const classes = useStyles()
  const listItems = lessons
    .filter((lesson) => lesson.state !== "draft")
    .map((lesson) => {
      return <LessonsListItem lesson={lesson} key={lesson.id} />
    })
  if (!listItems.length) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>Содержание</div>
      <div>{listItems}</div>
    </div>
  )
}

LessonsList.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
}

export default LessonsList
