import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    fontSize: 18,
    fontStyle: "italic",
  },
})

export default function FeedbacksListItemAuthor({ name, age }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <span>{name}</span>
      {age ? <span>, {age} лет</span> : null}
    </div>
  )
}

FeedbacksListItemAuthor.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number,
}
