export const COURSE_STATES = {
  draft: "draft",
  announced: "announced",
  canBeSubscribed: "can_be_subscribed",
}

export const COURSE_STATES_DESCRIPTIONS = {
  [COURSE_STATES.draft]: "Черновик",
  [COURSE_STATES.announced]: "Анонсирован",
  [COURSE_STATES.canBeSubscribed]: "Открыта запись",
}
