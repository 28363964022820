import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

import LessonsListItem from "./Item"
import { LessonType } from "../../types"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      marginTop: 50,
      padding: 0,
    },
  },
  title: {
    fontSize: 27,
    lineHeight: 1,
    paddingBottom: 7,

    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
}))

function SelectableLessonsList(props) {
  const classes = useStyles()
  const {
    lessons,
    selectedLessons,
    availableForSelection,
    selectLessonFromEvent,
    error,
  } = props
  const listItems = lessons
    .filter((lesson) => lesson.state !== "draft")
    .map((lesson) => {
      return (
        <LessonsListItem
          key={lesson.id}
          lesson={lesson}
          selected={selectedLessons[lesson.id] || false}
          selectable={availableForSelection}
          selectLessonFromEvent={selectLessonFromEvent}
          error={error ? error.lessons.includes(lesson.id) : false}
        />
      )
    })
  if (!listItems.length) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>Содержание</div>
      <div>{listItems}</div>
    </div>
  )
}

SelectableLessonsList.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
  selectedLessons: PropTypes.object.isRequired,
  availableForSelection: PropTypes.bool.isRequired,
  selectLessonFromEvent: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

SelectableLessonsList.defaultProps = {
  error: null,
}

export default SelectableLessonsList
