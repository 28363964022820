import React from "react"
import PropTypes from "prop-types"
import { Button, SvgIcon } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  svgIconWrapper: {
    fontSize: 200,
  },
  errorText: {
    fontSize: "1.1rem",
    margin: "-60px auto 0 auto",
    maxWidth: 500,
  },
  reloadButton: {
    marginTop: 20,
  },
})

function Error(props) {
  const { errorText, fontSize, ...svgIconProps } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.svgIconWrapper}>
        <SvgIcon fontSize="inherit" {...svgIconProps}>
          <path fill="none" d="M0 0h24v24H0V0z" />
          <g>
            <path d="M12 13.5c-2.33 0-4.31 1.46-5.11 3.5h10.22c-.8-2.04-2.78-3.5-5.11-3.5zM7.82 12l1.06-1.06L9.94 12 11 10.94 9.94 9.88 11 8.82 9.94 7.76 8.88 8.82 7.82 7.76 6.76 8.82l1.06 1.06-1.06 1.06z" />
            <path d="M11.99 2C6.47 2 2 6.47 2 12s4.47 10 9.99 10S22 17.53 22 12 17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            <path d="M16.18 7.76l-1.06 1.06-1.06-1.06L13 8.82l1.06 1.06L13 10.94 14.06 12l1.06-1.06L16.18 12l1.06-1.06-1.06-1.06 1.06-1.06z" />
          </g>
        </SvgIcon>
      </div>
      <div className={classes.errorText}>{errorText}</div>
      <Button
        className={classes.reloadButton}
        onClick={() => document.location.reload()}
        variant="contained"
      >
        Перезагрузить страницу
      </Button>
    </div>
  )
}

Error.propTypes = {
  errorText: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  svgIconProps: PropTypes.object,
}

export default Error
