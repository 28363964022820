import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchTimetable } from "./actions"
import { timetableStateSelector } from "./selectors"

export function useTimetable() {
  const dispatch = useDispatch()
  const fetch = useCallback(() => {
    dispatch(fetchTimetable())
  })

  const { didInvalidate } = useSelector(timetableStateSelector)
  useEffect(fetch, [didInvalidate])

  const timetableState = useSelector(timetableStateSelector)
  return {
    timetableState,
    isTimetableLoaded: !!timetableState.lastUpdated && !timetableState.loading,
    timetable: useSelector(timetableStateSelector).timetable,
  }
}
