import { combineReducers } from "redux"
import MAILOUTS_ACTION_TYPES from "./actiontypes"
import { updatingReducerFactory } from "core/factories/reducers"

const {
  SUBSCRIBE_FOR_MAILOUTS_REQUEST,
  SUBSCRIBE_FOR_MAILOUTS_SUCCESS,
  SUBSCRIBE_FOR_MAILOUTS_FAILURE,
  UNSUBSCRIBE_FROM_MAILOUTS_REQUEST,
  UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS,
  UNSUBSCRIBE_FROM_MAILOUTS_FAILURE,
} = MAILOUTS_ACTION_TYPES

const subscribeReducer = updatingReducerFactory({
  updateRequest: SUBSCRIBE_FOR_MAILOUTS_REQUEST,
  updateSuccess: SUBSCRIBE_FOR_MAILOUTS_SUCCESS,
  updateFailure: SUBSCRIBE_FOR_MAILOUTS_FAILURE,
})

const unsubscribeReducer = updatingReducerFactory({
  updateRequest: UNSUBSCRIBE_FROM_MAILOUTS_REQUEST,
  updateSuccess: UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS,
  updateFailure: UNSUBSCRIBE_FROM_MAILOUTS_FAILURE,
})

const mailoutsReducer = combineReducers({
  subscribeAction: subscribeReducer,
  unsubscribeAction: unsubscribeReducer,
})

export default mailoutsReducer
