import { mapValues } from "lodash"

const endpoints = {
  // authors
  authorsList: () => "/api/v1/authors/",

  // courses
  coursesList: () => "/api/v1/courses/",
  courseDetails: (id) => `/api/v1/courses/${id}/`,
  courseMailoutsSubscribe: (id) =>
    `/api/v1/courses/${id}/subscribe-for-mailouts/`,
  courseMailoutsUnsubscribe: (id) =>
    `/api/v1/courses/${id}/unsubscribe-from-mailouts/`,
  courseCreateParticipants: (id) =>
    `/api/v1/courses/${id}/create-participants/`,
  courseDeleteParticipants: (id) =>
    `/api/v1/courses/${id}/delete-participants/`,

  // timetable
  timetable: () => "/api/v2/user/pages-content/timetable/",

  // dictionaries
  dictionaries: () => "/api/v1/dictionaries/",

  // disciples
  discipleDetails: (id) => `/api/v1/disciples/${id}/`,

  // feedbacks
  feedbacksList: () => "/api/v1/feedback/",

  // lessons
  lessonsList: () => "/api/v1/lessons/",

  // library
  libraryItemsList: () => "/api/v1/library-items/",
  libraryItemDetails: (id) => `/api/v1/library-items/${id}`,
  libraryCategoriesList: () => "/api/v1/library-categories/",

  // quotations
  quotationsList: () => "/api/v1/quotations/",

  // participants
  participantsList: () => "/api/v1/participants/",
  participantsManagerList: () => "/api/v1/manager/participants/",
  participantDetails: (id) => `/api/v1/participants/${id}/`,
  participantManagerDetails: (id) => `/api/v1/manager/participants/${id}/`,

  // users
  userDetails: (id) => `/api/v1/users/${id}/`,
  userPasswordUpdate: () => "/api/v1/users/update-password/",
  userPasswordReset: () => "/api/v1/users/reset-password/",
  userPasswordResetComplete: () => "/api/v1/users/reset-password/complete/",
  userSignIn: () => "/api/v1/token/",
  userSignUpConfirmation: () => "/api/v1/users/registration-confirm/",
  usersList: () => "/api/v1/users/",

  // payments
  paymentList: () => "/api/v1/payments/",
  paymentCurrent: () => "/api/v1/payments/current/",
}

const BACKEND = process.env.REACT_APP_BACKEND
const absEndpoints = mapValues(endpoints, (endpoint) => (...args) =>
  BACKEND + endpoint(...args)
)

export default absEndpoints
