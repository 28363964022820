import React, { useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "@material-ui/core"

import { confirmUserSignUp } from "../../signup/actions"
import { signUpConfirmationStateSelector } from "../../signup/selectors"
import { Loader, RouterLink } from "core/components"
import urls from "core/urls"
import { useLoginInfo } from "users"

function UserSignUpConfirmation({ token, uidb64 }) {
  const dispatch = useDispatch()
  const { error, lastUpdated, loading } = useSelector(
    signUpConfirmationStateSelector
  )
  const { isLoggedIn } = useLoginInfo()
  const confirmed = useRef(false)
  const confirm = useCallback(() => {
    dispatch(confirmUserSignUp(uidb64, token))
    confirmed.current = !loading && lastUpdated
  }, [uidb64, token, loading, lastUpdated])

  if (!confirmed.current && !error && !loading) {
    confirm()
  }

  if (isLoggedIn) {
    return <Redirect to={urls.home()} />
  }

  if (confirmed.current) {
    return (
      <div>
        Регистрация подтверждена. Теперь Вы можете&nbsp;
        <Link component={RouterLink} to={urls.login()}>
          авторизоваться
        </Link>{" "}
        в личном кабинете.
      </div>
    )
  }

  if (error) {
    const errorText = error.byFields.nonFieldErrors.toLowerCase()
    return <div>Ошибка подверждения регистрации: {errorText}</div>
  }

  return <Loader />
}

UserSignUpConfirmation.propTypes = {
  token: PropTypes.string.isRequired,
  uidb64: PropTypes.string.isRequired,
}

export default UserSignUpConfirmation
