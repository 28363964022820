import React from "react"
import { makeStyles } from "@material-ui/styles"

import { BigHeaderLayout, Footer } from "core/components"

const useStyles = makeStyles({
  content: {
    maxWidth: 600,
    margin: "0 auto",
  },
})

function ScreensUserSignUpConfirmEmailSent(props) {
  const classes = useStyles()
  return (
    <BigHeaderLayout isDataLoaded isErrorHappened={false}>
      <div className={classes.content}>
        На указанный Вами адрес электронной почты отправлен email со ссылкой для
        подтверждения Вашей регистрации. Если Вы не получили письмо, проверьте
        папку спам, возможно оно находится там.
      </div>
      <Footer />
    </BigHeaderLayout>
  )
}

export default ScreensUserSignUpConfirmEmailSent
