const initState = {
  loading: false,
  didInvalidate: false,
}

function itemState(actionTypes, state = initState, action) {
  switch (action.type) {
    case actionTypes["detailsRequest"]:
      return {
        ...state,
        loading: true,
      }

    case actionTypes["detailsSuccess"]:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
      }

    case actionTypes["detailsFailure"]:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: action.error,
      }

    case actionTypes["invalidateDetails"]:
      return {
        ...state,
        didInvalidate: true,
      }

    default:
      return state
  }
}

function detailsReducerFactory(actionTypes) {
  const supportedActions = [
    "detailsRequest",
    "detailsSuccess",
    "detailsFailure",
    "invalidateDetails",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes["detailsRequest"]:
      case actionTypes["detailsSuccess"]:
      case actionTypes["detailsFailure"]:
      case actionTypes["invalidateDetails"]:
        return {
          ...state,
          [action.id]: itemState(actionTypes, state[action.id], action),
        }

      default:
        return state
    }
  }
}

export default detailsReducerFactory
