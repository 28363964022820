import React from "react"
import { Grid, Link, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { RouterLink, RouterHashLink } from "core/components"
import urls from "core/urls"

import logo from "assets/images/logo.svg"

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.5, 5),
    },
  },

  logo: {
    width: 50,
    height: 50,

    "& > a": {
      border: "none",
    },
  },

  siteName: {
    marginLeft: 20,
    fontSize: 24,
    textDecoration: "none",
    border: "none",
    color: "black",

    "&:hover": {
      border: "none",
    },
  },

  belongs: {
    margin: theme.spacing(3, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 auto",
    },
  },
}))

export default function SmallHeader() {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <Grid
      container
      component="header"
      className={classes.header}
      alignItems={mediumOrHigherScreen ? "center" : "flex-start"}
      direction={mediumOrHigherScreen ? "row" : "column"}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Grid item className={classes.logo}>
            <Link component={RouterLink} to="/">
              <img src={logo} width="100%" height="*" alt="logo" />
            </Link>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to="/"
              underline="none"
              className={classes.siteName}
            >
              VAGANTES - ВАГАНТЫ
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.belongs}>
        Образовательный проект&nbsp;
        <Link component={RouterHashLink} to={`${urls.about()}#ashtein`}>
          Аси Штейн
        </Link>{" "}
        и&nbsp;
        <Link component={RouterHashLink} to={`${urls.about()}#adesnitsky`}>
          Андрея Десницкого
        </Link>
      </Grid>
    </Grid>
  )
}
