const UPDATING_ACTION_TYPES = {
  UPDATE: "UPDATE_PARTICIPANT",
  UPDATE_REQUEST: "UPDATE_PARTICIPANT_REQUEST",
  UPDATE_SUCCESS: "UPDATE_PARTICIPANT_SUCCESS",
  UPDATE_FAILURE: "UPDATE_PARTICIPANT_FAILURE",
  RESET: "RESET_PARTICIPANT_UPDATE",
  INVALIDATE: "INVALIDATE_PARTICIPANT_UPDATE",
}

export default UPDATING_ACTION_TYPES
