import LIST_ACTION_TYPES from "./actiontypes"
import { quotationsListStateSelector } from "./selectors"
import QuotationsAPI from "../api"
import { fetchingItemsFlowFactory } from "core/factories/actions"

const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_LIST,
} = LIST_ACTION_TYPES

export function fetchQuotationsList() {
  return {
    type: FETCH_LIST,
  }
}

export function* listFetchingFlow() {
  yield* fetchingItemsFlowFactory(
    {
      fetch: FETCH_LIST,
      request: FETCH_LIST_REQUEST,
      success: FETCH_LIST_SUCCESS,
      failure: FETCH_LIST_FAILURE,
    },
    () => QuotationsAPI.fetchList,
    () => quotationsListStateSelector
  )()
}
