export const CURRENCY_CHOICE_RUB = "CURRENCY_CHOICE_RUB"
export const CURRENCY_CHOICE_EURO = "CURRENCY_CHOICE_EURO"
export const CURRENCY_CHOICE_USD = "CURRENCY_CHOICE_USD"

export const CURRENCY_CHOICE_OPTIONS = {
  [CURRENCY_CHOICE_RUB]: "₽",
  [CURRENCY_CHOICE_EURO]: "€",
}

export const CURRENCY_CHOICE_NAMES = {
  [CURRENCY_CHOICE_RUB]: "в рублях",
  [CURRENCY_CHOICE_EURO]: "в евро",
}

export const SYSTEM_YANDEX = "yookassa"
export const SYSTEM_PAYPAL = "paypal"

export const STATE_IN_PROGRESS = "STATE_IN_PROGRESS"
export const STATE_COMPLETED = "STATE_COMPLETED"
export const STATE_FAILURE = "STATE_FAILURE"

export const WHOLESALE_SCORE = 5
