import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchUserDetails } from "./actions"
import { userDetailsSelector, userDetailsStateSelector } from "./selectors"
import { useLoginInfo } from "../login/hooks"

export function useUserDetails() {
  const { isLoggedIn } = useLoginInfo()
  const dispatch = useDispatch()
  const getDetails = useCallback(() => {
    if (isLoggedIn) {
      dispatch(fetchUserDetails())
    }
  }, [isLoggedIn])
  useEffect(getDetails, [])

  const detailsState = useSelector(userDetailsStateSelector)
  return {
    userDetails: useSelector(userDetailsSelector),
    userDetailsState: detailsState,
    isUserDetailsLoaded: !!detailsState.lastUpdated && !detailsState.loading,
  }
}
