import MAILOUTS_ACTION_TYPES from "./actiontypes"
import CoursesAPI from "../api"
import { itemUpdatingFlowFactory } from "core/factories/actions"

const {
  SUBSCRIBE_FOR_MAILOUTS,
  SUBSCRIBE_FOR_MAILOUTS_REQUEST,
  SUBSCRIBE_FOR_MAILOUTS_SUCCESS,
  SUBSCRIBE_FOR_MAILOUTS_FAILURE,
  UNSUBSCRIBE_FROM_MAILOUTS,
  UNSUBSCRIBE_FROM_MAILOUTS_REQUEST,
  UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS,
  UNSUBSCRIBE_FROM_MAILOUTS_FAILURE,
  RESET,
} = MAILOUTS_ACTION_TYPES

export function subscribeForMailouts(id) {
  return { type: SUBSCRIBE_FOR_MAILOUTS, id }
}

export function unsubscribeFromMailouts(id) {
  return { type: UNSUBSCRIBE_FROM_MAILOUTS, id }
}

export function* subscribeFlow() {
  yield* itemUpdatingFlowFactory(
    {
      fetch: SUBSCRIBE_FOR_MAILOUTS,
      request: SUBSCRIBE_FOR_MAILOUTS_REQUEST,
      success: SUBSCRIBE_FOR_MAILOUTS_SUCCESS,
      failure: SUBSCRIBE_FOR_MAILOUTS_FAILURE,
      reset: RESET,
    },
    () => CoursesAPI.subscribeForMailouts
  )()
}

export function* unsubscribeFlow() {
  yield* itemUpdatingFlowFactory(
    {
      fetch: UNSUBSCRIBE_FROM_MAILOUTS,
      request: UNSUBSCRIBE_FROM_MAILOUTS_REQUEST,
      success: UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS,
      failure: UNSUBSCRIBE_FROM_MAILOUTS_FAILURE,
      reset: RESET,
    },
    () => CoursesAPI.unsubscribeFromMailouts
  )()
}
