import { call, put, select, take, fork } from "redux-saga/effects"

export function* fetchItemDetails(actionTypes, apiFunc, id) {
  yield put({ type: actionTypes["request"], id })
  try {
    const details = yield call(apiFunc, id)
    yield put({
      type: actionTypes["success"],
      id,
      details,
      receivedAt: Date.now(),
      // TODO: refactor according to FSA
      payload: {
        id,
        details,
        receivedAt: Date.now(),
      },
    })
  } catch (error) {
    yield put({
      type: actionTypes["failure"],
      id,
      error: error.message,
      // TODO: refactor according to FSA
      payload: { id, error: error.message, status: error.statusCode },
    })
  }
}

export function shouldFetchItemDetails(itemState) {
  if (!itemState) {
    return true
  } else if (itemState.loading) {
    return false
  } else {
    return itemState.didInvalidate
  }
}

export function* fetchItemDetailsIfNeeded(
  actionTypes,
  apiFunc,
  selectorFunc,
  id
) {
  const itemState = yield select(selectorFunc, id)
  if (shouldFetchItemDetails(itemState)) {
    yield call(fetchItemDetails, actionTypes, apiFunc, id)
  }
}

export function itemDetailsFetchingFlowFactory(
  actionTypes,
  apiFunc,
  selectorFunc
) {
  return function* () {
    let action = yield take(actionTypes["fetch"])
    while (true) {
      yield fork(
        fetchItemDetailsIfNeeded,
        actionTypes,
        apiFunc,
        selectorFunc,
        action.id
      )
      action = yield take([actionTypes["fetch"], actionTypes["request"]])
      if (action.type === actionTypes["fetch"]) {
        continue
      } else {
        yield take([actionTypes["success"], actionTypes["failure"]])
        action = yield take(actionTypes["fetch"])
      }
    }
  }
}
