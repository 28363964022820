import { ApiError } from "core/api"

export class LessonsError extends ApiError {
  constructor(error, lessons) {
    if (!(error instanceof ApiError))
      throw new Error(
        `Error expected to be ApiError, but ${typeof error} recieved`
      )

    super(error.message, null, error.statusCode)

    let matches = []
    let message = ""

    if (error.byFields.lessons_are_required) {
      message = error.byFields.lessons_are_required
    } else if (error.byFields.lessons_are_fully_occupied) {
      ;[message, matches] = matchLessons(
        error.byFields.lessons_are_fully_occupied,
        lessons
      )
    } else if (error.byFields.already_participated) {
      ;[message, matches] = matchLessons(
        error.byFields.already_participated,
        lessons
      )
    }

    this.message = error.message
    this.byFields = { lessons: message }
    this.lessons = matches
  }
}

function matchLessons(message, lessons) {
  let idsString = message.match(/{{.*}}/g)
  let matches = idsString[0].match(/\d+/g)

  matches = matches.map((match) => parseInt(match))
  message = message.substr(0, message.length - idsString[0].length)

  matches.forEach((match) => {
    message = message.replace(
      `_${match}_`,
      `"${lessons.find((lesson) => lesson.id === match).title}"`
    )
  })

  return [message, matches]
}
