import { createMuiTheme } from "@material-ui/core/styles"

const colors = {
  light: "#a9a389",
  dark: "#000000",
  lightText: "#a9a389",
  greenText: "#5da84c",
  darkText: "#000",
  redText: "#f44336",
  lightBackground: "rgba(201, 193, 159, 0.5)",
  lightBackgroundHover: "#edece7",
  lightButtonBackground: "#a8a38c",
  lightButtonBackgroundHover: "#79745f",
  darkBackground: "#3a3726",
  darkBackgroundHover: "#4b4939",
  lightBorder: "rgba(201, 193, 159, 0.5)",
  darkBorder: "#3a3726",
}

const theme = createMuiTheme({
  colors,
  constants: {
    contentMaxWidth: 1396,
  },
  palette: {
    primary: {
      main: colors.dark,
      light: colors.light,
    },
    text: {
      primary: colors.dark,
      secondary: colors.light,
    },
    background: {
      default: "#fff",
    },
  },
  spacing: 10,
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: "1.1rem",
      },
      contained: {
        borderRadius: 30,
        color: "white",
        backgroundColor: colors.darkBackground,
        padding: "6px 22px",
        textDecoration: "none",
        textTransform: "none",
        fontSize: "1.1rem",
        boxShadow: "none",
        MozBoxShadow: "none",
        WebkitBoxShadow: "none",

        "&:hover": {
          backgroundColor: colors.darkBackgroundHover,
        },
      },
      containedSecondary: {
        borderRadius: 30,
        color: colors.lightText,
        backgroundColor: colors.lightBackground,
        padding: "6px 22px",
        textDecoration: "none",
        textTransform: "none",
        fontSize: "1.1rem",
        boxShadow: "none",
        MozBoxShadow: "none",
        WebkitBoxShadow: "none",

        "&:hover": {
          backgroundColor: colors.lightBackgroundHover,
        },
      },
      outlined: {
        borderRadius: 30,
        borderColor: colors.lightBorder,
        padding: "6px 22px",

        "&:hover": {
          backgroundColor: colors.lightBackgroundHover,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: "1.1rem",
      },
    },
    MuiFormHelperText: {
      root: {
        textAlign: "right",
      },
    },
    MuiInput: {
      root: {
        "&$error": {
          "&:before, &:after": {
            borderBottomColor: colors.redText,
          },
          "&:hover:before, &:hover:after": {
            borderBottomColor: colors.redText,
          },
        },
      },
      underline: {
        "&:before, &:after": {
          borderBottomColor: colors.lightBorder,
        },
        "&:hover:before, &:hover:after": {
          borderBottomColor: colors.lightBorder,
        },
        "&:not($disabled)": {
          "&:hover:before": {
            borderBottomColor: colors.light,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "1.1rem",

        "&$focused": {
          color: colors.lightText,
        },

        "&$error": {
          color: colors.lightText,
        },
      },
    },
    MuiLink: {
      root: {
        display: "inline-block",
        lineHeight: "0.85",
        verticalAlign: "middle",
        color: colors.darkText,

        "&:hover": {
          display: "inline-block",
          lineHeight: "0.85",
          color: colors.darkText,
        },
      },
      underlineHover: {
        textDecoration: "none",
        borderBottom: `1px solid ${colors.lightBorder}`,
        color: colors.darkText,

        "&:hover": {
          textDecoration: "none",
          borderBottom: "1px solid rgba(0,0,0,0)",
          color: colors.darkText,
        },
      },
    },
    MuiTableCell: {
      body: {
        fontSize: "1.1rem",
      },
      head: {
        fontSize: "0.9rem",
      },
    },
    MuiTableRow: {
      root: {
        height: "auto",
        padding: "10px 0",
        lineHeight: 2,
        borderBottom: `1px solid ${colors.lightBorder}`,
      },
      head: {
        height: "auto",
        padding: "10px 0 5px",
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Spectral", "serif"].join(","),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
})

theme.presets = {
  italicTitle: {
    margin: theme.spacing(2, 0, 4, 0),
    fontStyle: "italic",
  },
  title: {
    margin: theme.spacing(2, 0, 4, 0),
  },
}

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: 36,
  fontWeight: 500,

  [theme.breakpoints.up("md")]: {
    fontSize: 48,
  },
}
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: 36,
}
theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: 18,

  [theme.breakpoints.up("md")]: {
    fontSize: 21,
  },
}
theme.typography.body2 = {
  ...theme.typography.body2,
  fontSize: 18,
}
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: 24,

  [theme.breakpoints.up("md")]: {
    fontSize: 27,
  },
}

export default theme
