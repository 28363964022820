import { combineReducers } from "redux"
import { normalize } from "normalizr"

import listReducer from "./list/reducer"

import LIST_ACTION_TYPES from "./list/actiontypes"
import { feedbackSchema } from "./schema"

const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES

export const feedbacksEntitiesReducer = (state = {}, action) => {
  let normalizedData = null
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      normalizedData = normalize(action.items, [feedbackSchema])
      return {
        ...state,
        ...normalizedData.entities.feedbacks,
      }

    default:
      return state
  }
}

export const feedbacksStateReducer = combineReducers({
  list: listReducer,
})
