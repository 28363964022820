import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { CalendarToday } from "@material-ui/icons"
import { Link } from "react-router-dom"

import { BigHeaderLayout, Footer } from "core/components"
import urls from "core/urls"
import { CoursesGrid, useCoursesList } from "courses"
import { QuotationBanner, useQuotationsList } from "quotations"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.constants.contentMaxWidth,
    margin: `${theme.spacing(2)}px auto 0 auto`,
  },

  gridWrapper: {
    padding: 0,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8.4),
    },
  },

  bannerSeparator: {
    height: 1,
    backgroundColor: theme.colors.lightBorder,
    maxWidth: 1167,
    marginTop: theme.spacing(3),
    border: 0,

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
    },
  },

  coursesTitle: {
    ...theme.presets.italicTitle,
    marginBottom: 20,
  },

  timetableBlock: {
    textAlign: "center",
    marginBottom: 40,
  },

  timetable: {
    fontSize: 20,
    color: theme.colors.lightText,
    verticalAlign: "middle",
    textDecoration: "underline",
    textDecorationColor: theme.colors.lightBorder,

    "&:hover": {
      color: theme.colors.lightText,
      textDecoration: "underline",
      textDecorationColor: theme.colors.lightBorder,
    },
  },

  timetableIcon: {
    color: theme.colors.lightText,
    verticalAlign: "middle",
  },
}))

function ScreensHome() {
  const classes = useStyles()

  const {
    coursesList,
    coursesListState,
    isCoursesListLoaded,
  } = useCoursesList(["authors"])

  const {
    quotationsList,
    quotationsListState,
    isQuotationsListLoaded,
  } = useQuotationsList()

  const isDataLoaded = isCoursesListLoaded && isQuotationsListLoaded
  const isErrorHappened = coursesListState.error || quotationsListState.error

  let mainContent
  if (isDataLoaded && !isErrorHappened) {
    const randomQuotation =
      quotationsList[Math.floor(Math.random() * quotationsList.length)]
    const quotationCourse = coursesList.find(
      (c) => c.id === randomQuotation.course
    )
    mainContent = (
      <React.Fragment>
        <div className={classes.root}>
          <QuotationBanner
            source={randomQuotation.source}
            text={randomQuotation.text}
            courseTitle={quotationCourse && quotationCourse.title}
            courseUrl={
              quotationCourse && urls.courseDetails(quotationCourse.id)
            }
          />
          <hr className={classes.bannerSeparator} />
          <Typography
            variant="h1"
            component="h1"
            align="center"
            className={classes.coursesTitle}
          >
            Курсы
          </Typography>
          <div className={classes.timetableBlock}>
            <CalendarToday className={classes.timetableIcon} />
            <Link to={urls.timetable()} className={classes.timetable}>
              Расписание текущих занятий
            </Link>
          </div>
          <div className={classes.gridWrapper}>
            <CoursesGrid courses={coursesList} withFilters />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
  return (
    <BigHeaderLayout
      isDataLoaded={isDataLoaded}
      isErrorHappened={!!isErrorHappened}
    >
      {mainContent}
    </BigHeaderLayout>
  )
}

export default ScreensHome
