import {
  startParticipantsDeletion,
  requestParticipantsDeletion,
  finishParticipantsDeletion,
  failParticipantsDeletion,
  resetParticipantsDeletion,
} from "./slice"
import ParticipantsAPI from "../api"
import { itemDeletionFlowFactory } from "core/factories/actions"

export default function* deletionFlow() {
  yield* itemDeletionFlowFactory(
    {
      fetch: startParticipantsDeletion().type,
      request: requestParticipantsDeletion().type,
      success: finishParticipantsDeletion().type,
      failure: failParticipantsDeletion().type,
      reset: resetParticipantsDeletion().type,
    },
    () => ParticipantsAPI.deleteFromCourse
  )()
}
