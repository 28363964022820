import { schema } from "normalizr"

const authorSchema = new schema.Entity("authors")
const participantSchema = new schema.Entity("participants")
const lessonSchema = new schema.Entity("lessons", {
  participant: participantSchema,
})

const courseSchema = new schema.Entity("courses", {
  authors: [authorSchema],
  lessons: [lessonSchema],
})

export default courseSchema
