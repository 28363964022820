import DELETION_ACTION_TYPES from "./actiontypes"
import { deletionReducerFactory } from "core/factories/reducers"

const { DELETE_FAILURE, DELETE_REQUEST, DELETE_SUCCESS } = DELETION_ACTION_TYPES

const deletionReducer = deletionReducerFactory({
  deleteRequest: DELETE_REQUEST,
  deleteSuccess: DELETE_SUCCESS,
  deleteFailure: DELETE_FAILURE,
})

export default deletionReducer
