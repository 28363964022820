import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { makeStyles } from "@material-ui/styles"

import CourseVolume from "./Volume"
import { CourseType } from "../../types"
import { DISCIPLE_AGE_OPTIONS } from "disciples"
import { LessonType } from "lessons"

import rectanglesIcon from "assets/images/icons/rectangles.png"
import calendarIcon from "assets/images/icons/calendar.png"
import hatIcon from "assets/images/icons/hat.png"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    lineHeight: 1,

    "& img ": {
      margin: "0 10px",
    },

    "@media (min-width: 960px)": {
      fontSize: 18,
      lineHeight: 1,
    },

    "& > div:not(:first-child)": {
      marginTop: 20,

      "@media (min-width: 960px)": {
        marginTop: 30,
      },
    },
  },
  lightText: {
    color: theme.colors.lightText,
  },
}))

function CourseDetailsMeta({ age, start, fullYear, lessons }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <span>Объём курса:</span>
        <img src={rectanglesIcon} alt="" />
        <span className={classes.lightText}>
          <CourseVolume lessons={lessons} fullYear={fullYear} />
        </span>
      </div>
      <div>
        <span>Начало курса:</span>
        <img src={calendarIcon} alt="" />
        <span className={classes.lightText}>
          {moment(start).format("DD.MM.YYYY")}
        </span>
      </div>
      <div>
        <span>Возраст учеников:</span>
        <img src={hatIcon} alt="" />
        <span className={classes.lightText}>
          Курс {DISCIPLE_AGE_OPTIONS[age]}
        </span>
      </div>
    </div>
  )
}

CourseDetailsMeta.propTypes = {
  age: CourseType.disciplesAge,
  fullYear: CourseType.fullYear,
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      duration: LessonType.duration,
    })
  ),
  start: CourseType.start,
}

export default CourseDetailsMeta
