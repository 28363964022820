import PropTypes from "prop-types"

export const PriceType = {
  coursePrice: PropTypes.number,
  lessonPrice: PropTypes.number,
  lessonWholesalePrice: PropTypes.number,
  currency: PropTypes.oneOf([
    "CURRENCY_CHOICE_RUB",
    "CURRENCY_CHOICE_EURO",
    "CURRENCY_CHOICE_USD",
  ]),
}
