import fetch from "cross-fetch"

import { convertToQueryString, getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class FeedbacksAPI {
  fetchList(params) {
    const queryString = params ? `?${convertToQueryString(params)}` : ""
    return handleErrors(
      fetch(`${endpoints.feedbacksList()}${queryString}`, {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new FeedbacksAPI()
