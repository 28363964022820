import React from "react"
import { convertFromRaw } from "draft-js"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Footer, Loader, SmallHeader } from "core/components"
import { convertEditorStateToHtml } from "core/utils/text"
import { useTimetable } from "timetable"

const useStyles = makeStyles((theme) => ({
  text: {
    margin: "20px auto 0 auto",
    fontSize: 18,
    lineHeight: 1.43,
    maxWidth: 1024,

    "@media (min-width: 960px)": {
      marginTop: 30,
      fontSize: 21,
    },
  },

  coursesTitle: theme.presets.italicTitle,
}))

function createMarkup(html) {
  return { __html: html }
}

export default function ScreensCourseTimetable() {
  const classes = useStyles()
  const { timetable, isTimetableLoaded } = useTimetable()

  const getTextAsHtml = (rawText) => {
    const convertedText = convertFromRaw(JSON.parse(rawText))
    return createMarkup(convertEditorStateToHtml(convertedText))
  }

  const mainContent = isTimetableLoaded ? (
    <>
      <Typography
        variant="h1"
        component="h1"
        align="center"
        className={classes.coursesTitle}
      >
        {timetable.name}
      </Typography>
      <div
        className={classes.text}
        dangerouslySetInnerHTML={getTextAsHtml(timetable.text)}
      />
    </>
  ) : (
    <Loader />
  )

  return (
    <>
      <SmallHeader />
      {mainContent}
      {isTimetableLoaded ? <Footer /> : null}
    </>
  )
}
