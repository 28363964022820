import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

import CourseDetailsDescription from "./Description"
import CourseDetailsMeta from "./Meta"
import { CourseType } from "../../types"
import { AuthorType } from "authors"
import { LessonType } from "lessons"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
}))

function CourseDetails({ course }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CourseDetailsMeta
        age={course.disciplesAge}
        fullYear={course.fullYear}
        lessons={course.lessons}
        start={course.start}
      />
      <CourseDetailsDescription description={course.description} />
    </div>
  )
}

CourseDetails.propTypes = {
  course: PropTypes.shape({
    ...CourseType,
    authors: PropTypes.arrayOf(PropTypes.shape(AuthorType)),
    lessons: PropTypes.arrayOf(
      PropTypes.shape({
        duration: LessonType.duration,
      })
    ),
  }).isRequired,
}

export default CourseDetails
