import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"

import { BigHeaderLayout, Footer } from "core/components"

const useStyles = makeStyles({
  content: {
    maxWidth: 570,
    margin: "0 auto",
  },
  text: {
    marginTop: 40,
  },
})

function ScreensUserResetPasswordInstructions() {
  const classes = useStyles()
  return (
    <BigHeaderLayout isDataLoaded isErrorHappened={false}>
      <div className={classes.content}>
        <Typography variant="h1" align="center" className={classes.header}>
          ВОССТАНОВЛЕНИЕ ПАРОЛЯ
        </Typography>
        <div className={classes.text}>
          На указанный Вами адрес электронной почты отправлено письмо с
          интрукциями по восстановлению пароля. Если письмо не пришло, проверьте
          папку спам, возможно оно находится там.
        </div>
      </div>
      <Footer />
    </BigHeaderLayout>
  )
}

export default ScreensUserResetPasswordInstructions
