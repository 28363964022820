import React from "react"
import PropTypes from "prop-types"
import { Button, Grid, Link, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { RouterHashLink, RouterLink } from "core/components"
import urls from "core/urls"

import logo from "assets/images/logo.svg"

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
      textAlign: "left",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 80,

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(5),
    },

    "& > a": {
      border: "none",
    },
  },
  siteName: {
    fontSize: 30,
  },
  siteDescription: {
    marginTop: theme.spacing(3),
    textAlign: "left",
    fontSize: 18,

    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      fontSize: 24,
    },
  },
  accountBlock: {
    margin: theme.spacing(2, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 auto",
    },
  },
  anchorLink: {
    display: "inline-block",
    borderBottom: `1px solid ${theme.colors.lightText}`,
    color: theme.colors.lightText,
    textDecoration: "none",
    lineHeight: 0.85,

    "&:hover": {
      borderBottom: "1px solid rgba(0,0,0,0)",
    },

    "&:visited": {
      color: theme.colors.lightText,
    },
  },
  registerButton: {
    color: "white",
    backgroundColor: theme.colors.lightButtonBackground,

    "&:hover": {
      backgroundColor: theme.colors.lightButtonBackgroundHover,
      color: "white",
    },
  },
}))

function BigHeader({ isLoggedIn, firstName, lastName, author }) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <Grid
      container
      component="header"
      className={classes.header}
      alignItems={mediumOrHigherScreen ? "center" : "flex-start"}
      direction={mediumOrHigherScreen ? "row" : "column"}
    >
      <Grid item>
        <Grid container alignItems="center">
          <Grid item className={classes.logo}>
            <Link component={RouterLink} to="/">
              <img src={logo} width="80px" height="80px" alt="logo" />
            </Link>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.siteName}>
                VAGANTES - ВАГАНТЫ
              </Grid>
              {mediumOrHigherScreen ? (
                <Grid item className={classes.siteDescription}>
                  Образовательный проект&nbsp;
                  <Link
                    component={RouterHashLink}
                    to={`${urls.about()}#ashtein`}
                  >
                    Аси Штейн
                  </Link>{" "}
                  и&nbsp;
                  <Link
                    component={RouterHashLink}
                    to={`${urls.about()}#adesnitsky`}
                  >
                    Андрея Десницкого
                  </Link>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {mediumOrHigherScreen ? null : (
        <Grid item className={classes.siteDescription}>
          Образовательный проект&nbsp;
          <Link component={RouterHashLink} to={`${urls.about()}#ashtein`}>
            Аси Штейн
          </Link>{" "}
          и&nbsp;
          <Link component={RouterHashLink} to={`${urls.about()}#adesnitsky`}>
            Андрея Десницкого
          </Link>
        </Grid>
      )}
      <Grid item className={classes.accountBlock}>
        {isLoggedIn ? (
          <Grid>
            {author ? (
              <a href={urls.admin()} className={classes.anchorLink}>
                {`${firstName || ""} ${lastName || ""}`}
              </a>
            ) : (
              <Link
                color="textSecondary"
                component={RouterLink}
                to={urls.profile()}
              >
                {`${firstName || ""} ${lastName || ""}`}
              </Link>
            )}
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            spacing={2}
            justify="center"
            wrap="nowrap"
          >
            <Grid item>
              <Button
                variant="contained"
                component={RouterLink}
                to={urls.signUp()}
                className={classes.registerButton}
              >
                Регистрация
              </Button>
            </Grid>
            <Grid item>
              <Link
                color="textSecondary"
                underline="none"
                component={RouterLink}
                to={urls.login()}
              >
                Войти
              </Link>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

BigHeader.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
}

export default BigHeader
