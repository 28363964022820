const CURRENT_ACTION_TYPES = {
  FETCH_CURRENT: "FETCH_PAYMENT_CURRENT",
  FETCH_CURRENT_REQUEST: "FETCH_PAYMENT_CURRENT_REQUEST",
  FETCH_CURRENT_INPROGRESS: "FETCH_PAYMENT_CURRENT_INPROGRESS",
  FETCH_CURRENT_SUCCESS: "FETCH_PAYMENT_CURRENT_SUCCESS",
  FETCH_CURRENT_FAILURE: "FETCH_PAYMENT_CURRENT_FAILURE",
  INVALIDATE_CURRENT: "INVALIDATE_PAYMENT_CURRENT",
}

export default CURRENT_ACTION_TYPES
