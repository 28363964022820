const ADMIN_SITE = process.env.REACT_APP_ADMIN_SITE

const urls = {
  // home
  home: () => "/",

  // account
  profile: () => "/account/profile",
  profileEditor: () => "/account/profile/editor",
  discipleCourses: () => "/account/courses",

  // admin
  admin: () => `${ADMIN_SITE}`,

  // courses
  courseDetails: (id) => `/courses/${id}`,

  // feedbacks
  feedbacksList: () => "/feedbacks",

  // library
  library: () => "library",

  //timetable
  timetable: () => "/timetable",

  // users
  login: () => "/user/login",
  resetPassword: () => "/user/reset-password",
  resetPasswordInstructions: () => "/user/reset-password/instructions/",
  resetPasswordSuccess: () => "/user/reset-password/success",
  signUp: () => "/user/signup",
  signupEmailSent: () => "/user/signup-email-sent",

  // static pages
  about: () => "/about",
  policy: () => "/policy",
}

export default urls
