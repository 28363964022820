import "url-search-params-polyfill"

export function parseSearchString(searchString) {
  const searchParams = new URLSearchParams(searchString)
  const result = {}
  for (const [key, value] of searchParams.entries()) {
    result[key] = value.split(",").map((i) => parseInt(i, 10))
  }
  return result
}
