import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class TimetableAPI {
  fetchDetails() {
    return handleErrors(
      fetch(endpoints.timetable(), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new TimetableAPI()
