import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import { Button, TextField } from "@material-ui/core"

import {
  useResetPasswordCompletionFormHandlers,
  resetPasswordStateSelector,
  resetUserPasswordResettingCompletion,
} from "../../password-reset"
import { FormError } from "core/components"
import { ApiError } from "core/api"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > div:not(:first-child)": {
      marginTop: theme.spacing(1),

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
      },
    },
  },

  button: {
    marginTop: theme.spacing(3),
  },
}))

function UserPasswordResetCompletionForm({ uidb64, token }) {
  const classes = useStyles()
  const [
    { password1, password2 },
    { setValueFromEvent, onSubmit },
  ] = useResetPasswordCompletionFormHandlers(uidb64, token)
  const completionState = useSelector(resetPasswordStateSelector).completion
  const redirect = useRef(false)
  const dispatch = useDispatch()
  const error = completionState.error || new ApiError()

  if (completionState.completed) {
    redirect.current = true
    dispatch(resetUserPasswordResettingCompletion())
  }

  if (redirect.current) {
    return <Redirect to={urls.resetPasswordSuccess()} />
  }

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <TextField
        name="password1"
        label="Новый пароль"
        type="password"
        value={password1}
        onChange={setValueFromEvent("password1")}
        fullWidth
        error={!!error.byFields.password1}
        helperText={error.byFields.password1}
        autoComplete="new-password"
      />
      <TextField
        name="password2"
        label="Новый пароль еще раз"
        type="password"
        value={password2}
        onChange={setValueFromEvent("password2")}
        fullWidth
        error={!!error.byFields.password2}
        helperText={error.byFields.password2}
        autoComplete="new-password"
      />
      {error.byFields.nonFieldErrors ? (
        <FormError>{error.byFields.nonFieldErrors}</FormError>
      ) : null}
      <Button
        variant="contained"
        type="submit"
        fullWidth
        className={classes.button}
        disabled={completionState.loading}
      >
        Сохранить
      </Button>
    </form>
  )
}

UserPasswordResetCompletionForm.propTypes = {
  token: PropTypes.string.isRequired,
  uidb64: PropTypes.string.isRequired,
}

export default UserPasswordResetCompletionForm
