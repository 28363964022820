import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Error, Loader } from "core/components"
import { useCoursesList } from "courses"
import { ParticipantsTable } from "participants"

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 1000,
    padding: theme.spacing(0, 2),
    margin: `${theme.spacing(6)}px auto 0 auto`,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8),
    },
  },
  table: {
    overflowX: "auto",
  },
  text: {
    color: theme.colors.lightText,
    marginTop: 20,
    fontSize: 20,
    fontStyle: "italic",
  },
  title: theme.presets.italicTitle,
}))

function ScreensAccountCourses() {
  const classes = useStyles()
  const {
    coursesList,
    coursesListState,
    isCoursesListLoaded,
  } = useCoursesList(["authors", "lessons"])

  let mainContent
  if (isCoursesListLoaded) {
    if (coursesListState.error) {
      mainContent = <Error errorText="Упс... Что-то пошло не так" />
    } else {
      const participants = coursesList
        .filter(
          (course) =>
            course.subscribedForMailouts ||
            course.lessons.some((l) => l.participationState)
        )
        .map((course) => ({
          id: course.id,
          authors: `Курс ${course.authors.map((a) => a.genitive).join(", ")}`,
          course: course.title,
          subscribed: course.subscribedForMailouts,
          lessons: course.lessons.filter((l) => l.participationState),
        }))
      if (participants.length) {
        mainContent = (
          <div className={classes.table}>
            <ParticipantsTable participants={participants} />
          </div>
        )
      } else {
        mainContent = (
          <div className={classes.text}>
            Вы пока не записались ни на один курс.
          </div>
        )
      }
    }
  } else {
    mainContent = <Loader />
  }

  return (
    <div className={classes.content}>
      <Typography
        variant="h1"
        component="h1"
        align="center"
        className={classes.title}
      >
        Курсы
      </Typography>
      {mainContent}
    </div>
  )
}

export default ScreensAccountCourses
