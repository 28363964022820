function localeMessages() {
  return {
    Russian: "Русский",
    English: "Английский",
    Other: "Другой",
  }
}

export default function translate(message) {
  const translated = localeMessages()[message]
  if (translated === undefined) {
    return message
  } else {
    return translated
  }
}
