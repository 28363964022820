import { combineReducers } from "redux"

import { authorEntitiesReducer, authorsStateReducer } from "authors"
import { courseEntitiesReducer, coursesStateReducer } from "courses"
import { dictionariesReducer } from "../dictionaries"
import {
  disciplesEntitiesReducer,
  disciplesStateReducer,
  profileEntitiesReducer,
} from "disciples"
import { feedbacksEntitiesReducer, feedbacksStateReducer } from "feedbacks"
import { lessonEntitiesReducer, lessonsStateReducer } from "lessons"
import { libraryReducer } from "library"
import { libraryCategoriesReducer } from "library-categories"
/* import {
  participantsEntitiesReducer,
  participantsStateReducer
} from 'participants' */
import { quotationEntitiesReducer, quotationsStateReducer } from "quotations"
import { currentUserStateReducer, userEntitiesReducer } from "users"
import { paymentsStateReducer } from "payments"
import { participantsStateReducer } from "features/participants/stateReducer"
import participantsEntitiesReducer from "features/participants/entities/slice"
import { timetableReducer } from "timetable"

const entities = combineReducers({
  authors: authorEntitiesReducer,
  courses: courseEntitiesReducer,
  disciples: disciplesEntitiesReducer,
  feedbacks: feedbacksEntitiesReducer,
  lessons: lessonEntitiesReducer,
  participants: participantsEntitiesReducer,
  profiles: profileEntitiesReducer,
  quotations: quotationEntitiesReducer,
  users: userEntitiesReducer,
})

const local = combineReducers({
  authors: authorsStateReducer,
  courses: coursesStateReducer,
  currentUser: currentUserStateReducer,
  dictionaries: dictionariesReducer,
  disciples: disciplesStateReducer,
  feedbacks: feedbacksStateReducer,
  lessons: lessonsStateReducer,
  library: libraryReducer,
  libraryCategories: libraryCategoriesReducer,
  participants: participantsStateReducer,
  quotations: quotationsStateReducer,
  payments: paymentsStateReducer,
  timetable: timetableReducer,
})

const rootReducer = combineReducers({
  entities,
  local,
})

export default rootReducer
