import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Error, Footer, Loader, Separator, SmallHeader } from "core/components"
import urls from "core/urls"
import {
  CourseDetails,
  CourseDetailsBanner,
  CoursesGrid,
  useCourseDetails,
  useCoursesList,
  COURSE_STATES,
} from "courses"
import { SelectableLessonsList, SelectableLessonsBlocks } from "lessons"
import { ParticipantForm, PARTICIPANT_STATES } from "participants"
import { usePaymentCreationHandlers, usePaymentCreationState } from "payments"
import {
  MailoutsSubscribeButton,
  MailoutsUnsubscribeButton,
} from "courses/mailouts"
import { LessonsError } from "payments/errorParser"
import { useLoginInfo } from "users"
import { BlocksBuilder } from "lessons/blockBuilder"

const useStyles = makeStyles((theme) => ({
  content: {
    margin: `${theme.spacing(3)}px auto 0 auto`,
    maxWidth: 1167,
    padding: 0,

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(5)}px auto 0 auto`,
    },
  },

  courseContent: {
    maxWidth: 634,
    padding: 0,
  },

  participantFormContent: {
    position: "sticky",
    top: theme.spacing(3),
    maxWidth: 366,

    [theme.breakpoints.up("md")]: {
      top: theme.spacing(5),
    },

    "& a": {
      color: theme.colors.lightText,
      textDecoration: "underline",
      textDecorationColor: theme.colors.lightBorder,
    },
  },

  message: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  subscribedMessage: {
    fontSize: 21,
    color: theme.colors.lightText,
    fontStyle: "italic",
    marginBottom: 20,
  },

  participantFormHeader: {
    fontSize: 27,
    lineHeight: 1.33,
    marginBottom: 20,
  },

  coursesTitle: theme.presets.italicTitle,

  separator: {
    maxWidth: 1366,
    marginTop: theme.spacing(10),
  },

  errorMessage: {
    marginTop: 22,
    textAlign: "center",
    color: "red",
  },

  subSpace: {
    paddingTop: 20,
  },
}))

export default function ScreensCourseDetails(props) {
  const { id } = props.match.params
  const {
    courseDetails,
    courseDetailsState,
    isCourseDetailsLoaded,
  } = useCourseDetails(id, true)
  const {
    coursesList,
    coursesListState,
    isCoursesListLoaded,
  } = useCoursesList(["authors"])
  const isDataLoaded = isCoursesListLoaded && isCourseDetailsLoaded

  let mainContent
  if (isDataLoaded) {
    const errorOccurred = courseDetailsState.error || coursesListState.error
    if (errorOccurred) {
      mainContent = <Error errorText="Упс... что-то пошло не так" />
    } else {
      mainContent = (
        <DataWrapper course={courseDetails} coursesList={coursesList} />
      )
    }
  } else {
    mainContent = <Loader />
  }
  return (
    <>
      <SmallHeader />
      {mainContent}
      {isDataLoaded ? <Footer /> : null}
    </>
  )
}

function DataWrapper(props) {
  const classes = useStyles()
  const { isLoggedIn } = useLoginInfo()

  const { course, coursesList } = props
  const lessonsList = course.lessons.filter(
    (lesson) => lesson.state !== "draft"
  )
  const purchasedLessons = lessonsList.filter(
    (lesson) => lesson.participationState === PARTICIPANT_STATES.approved
  )

  const isBlocksFeature = course.lessonsBlockSize > 1
  let blocksBuilder = null
  let availableLessons = lessonsList
  let participantForm
  let lessonsError = null

  if (isBlocksFeature) {
    blocksBuilder = new BlocksBuilder(course.lessonsBlockSize)
    lessonsList.forEach((lesson) => blocksBuilder.addLesson(lesson))
    availableLessons = blocksBuilder.getLessons()
  }

  const paymentHandlers = usePaymentCreationHandlers(course, availableLessons)
  const { completed, loading, redirectTo, error } = usePaymentCreationState()

  if (error && !error.byFields.nonFieldErrors) {
    lessonsError = new LessonsError(error, lessonsList)
  }

  if (completed) {
    window.location = redirectTo
    return <Loader />
  }

  if (course.state === COURSE_STATES.canBeSubscribed) {
    participantForm = (
      <div className={classes.message}>
        <ParticipantForm
          courseDetails={course}
          lessonsList={lessonsList}
          availableLessons={availableLessons}
          allLessons={[...course.lessons]}
          paymentHandlers={paymentHandlers}
          purchasedLessons={purchasedLessons}
          loading={loading}
        />
        {!isLoggedIn && (
          <div>
            <div className={classes.subSpace} />
            <Link to={urls.login()}>Авторизуйтесь</Link> или&nbsp;
            <Link to={urls.signUp()}>зарегистрируйтесь</Link>,&nbsp; для записи
            на курс.
          </div>
        )}
      </div>
    )
  } else {
    participantForm = (
      <div>
        <div className={classes.participantFormHeader}>
          Запись на курс закрыта
        </div>
        {isLoggedIn ? (
          purchasedLessons.length === 0 &&
          (course.subscribedForMailouts ? (
            <MailoutsUnsubscribeButton course={course.id} />
          ) : (
            <MailoutsSubscribeButton course={course.id} />
          ))
        ) : (
          <div>
            <div className={classes.subSpace} />
            <Link to={urls.login()}>Авторизуйтесь</Link> или&nbsp;
            <Link to={urls.signUp()}>зарегистрируйтесь</Link>,&nbsp; для записи
            на курс.
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <CourseDetailsBanner course={course} />
      <Grid
        className={classes.content}
        container
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid className={classes.courseContent} item>
          <CourseDetails course={course} />
          {isBlocksFeature ? (
            <SelectableLessonsBlocks
              blocksBuilder={blocksBuilder}
              selectedLessons={paymentHandlers.selectedLessons}
              availableForSelection={paymentHandlers.canSelectLessons}
              selectLessonFromEvent={paymentHandlers.selectLessonFromEvent}
              error={lessonsError || null}
            />
          ) : (
            <SelectableLessonsList
              lessons={lessonsList}
              selectedLessons={paymentHandlers.selectedLessons}
              availableForSelection={paymentHandlers.canSelectLessons}
              selectLessonFromEvent={paymentHandlers.selectLessonFromEvent}
              error={lessonsError || null}
            />
          )}
        </Grid>
        <Grid className={classes.participantFormContent} component="aside" item>
          {participantForm}
          {error && (
            <div className={classes.errorMessage}>
              {lessonsError
                ? lessonsError.byFields.lessons
                : error.byFields.nonFieldErrors}
            </div>
          )}
          {lessonsError && (
            <ErrorDialog errorText={lessonsError.byFields.lessons} />
          )}
        </Grid>
      </Grid>
      <Separator className={classes.separator} />
      <Typography
        variant="h1"
        component="h1"
        align="center"
        className={classes.coursesTitle}
      >
        Другие курсы
      </Typography>
      <CoursesGrid courses={coursesList} />
    </>
  )
}

function ErrorDialog({ errorText }) {
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  if (!errorText) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ошибка</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  errorText: PropTypes.string.isRequired,
}
