/* global localStorage */
import PropTypes from "prop-types"
import "url-search-params-polyfill"

import { capitalize } from "./utils/text"

export function getDefaultHeaders() {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ru",
  }
  if (localStorage.getItem("accessToken")) {
    headers["Authorization"] = "Bearer " + localStorage.getItem("accessToken")
  }
  return headers
}

export class ApiError extends Error {
  getFieldErrors(validationErrors) {
    Object.keys(validationErrors).forEach((fieldName) => {
      // handle nested errors
      if (
        typeof validationErrors[fieldName] === "object" &&
        validationErrors[fieldName].constructor === Object
      ) {
        this.getFieldErrors(validationErrors[fieldName])
      } else {
        if (Array.isArray(validationErrors[fieldName])) {
          this.byFields[fieldName] = validationErrors[fieldName]
            .map((error) => capitalize(error))
            .join(" ")
        } else {
          this.byFields[fieldName] = validationErrors[fieldName]
        }
      }
    })
  }

  constructor(message, validationErrors, statusCode) {
    super(message)
    this.name = "RequestError"
    this.statusCode = statusCode
    this.byFields = {}
    if (validationErrors) {
      this.getFieldErrors(validationErrors)
    }
  }
}

export const ApiErrorType = {
  name: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  byFields: PropTypes.object.isRequired,
}

export function handleApiErrors(response, skipReloadOn401) {
  if (!skipReloadOn401 && response.status === 401) {
    localStorage.removeItem("accessToken")
    return Promise.reject(
      new ApiError(response.statusText, {}, response.status)
    )
  }

  if (response.status === 204) {
    // handle response with empty body
    return Promise.resolve({})
  }

  const json = response
    .json()
    .catch((error) => {
      return Promise.reject(
        new ApiError(error.message, {
          nonFieldErrors: ["Ошибка получения данных с сервера."],
        })
      )
    })
    .then((json) => {
      if (response.ok) {
        return Promise.resolve(json)
      } else {
        return Promise.reject(
          new ApiError(response.statusText, json, response.status)
        )
      }
    })
  return Promise.resolve(json)
}

export function handleFetchErrors(error) {
  return Promise.reject(
    new ApiError(error.message, {
      nonFieldErrors: ["Ошибка запроса к серверу."],
    })
  )
}

export function handleErrors(promise, skipReloadOn401) {
  return promise
    .catch(handleFetchErrors)
    .then((response) => handleApiErrors(response, skipReloadOn401))
}

export function convertToQueryString(obj) {
  const searchParams = new URLSearchParams()
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((i) => searchParams.append(key, i))
    } else {
      searchParams.append(key, obj[key])
    }
  })
  return searchParams.toString()
}
