import LIST_ACTION_TYPES from "../list/actiontypes"
import authorSchema from "../schema"
import { entitiesReducerFactory } from "core/factories/reducers"
import courseActionTypes from "courses/actiontypes"
import courseSchema from "courses/schema"

const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES
const {
  FETCH_DETAILS_SUCCESS: FETCH_COURSE_DETAILS_SUCCESS,
  FETCH_LIST_SUCCESS: FETCH_COURSE_LIST_SUCCESS,
} = courseActionTypes

const baseReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_LIST_SUCCESS,
  },
  authorSchema,
  (normalizedData) => normalizedData.entities.authors
)

const authorsFromCourseDetailsReducer = entitiesReducerFactory(
  {
    listFromDetailsSuccess: FETCH_COURSE_DETAILS_SUCCESS,
  },
  courseSchema,
  (normalizedData) => normalizedData.entities.authors
)

const authorsFromCourseListReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_COURSE_LIST_SUCCESS,
  },
  courseSchema,
  (normalizedData) => normalizedData.entities.authors
)

export default function entitiesReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_LIST_SUCCESS:
      return baseReducer(state, action)

    case FETCH_COURSE_DETAILS_SUCCESS:
      return authorsFromCourseDetailsReducer(state, action)

    case FETCH_COURSE_LIST_SUCCESS:
      return authorsFromCourseListReducer(state, action)

    default:
      return state
  }
}
