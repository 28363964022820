import { difference } from "lodash"
import { normalize } from "normalizr"

const initState = {
  loading: false,
  didInvalidate: false,
  items: [],
  error: null,
}

function listReducerFactory(actionTypes, schema) {
  const supportedActions = [
    "createSuccess",
    "deleteSuccess",
    "updateSuccess",
    "listRequest",
    "listSuccess",
    "listFailure",
    "invalidateList",
    "signoutSuccess",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = initState, action) => {
    switch (action.type) {
      case actionTypes["listRequest"]:
        return {
          ...state,
          loading: true,
        }

      case actionTypes["listSuccess"]:
        const normalizedData = normalize(action.items, [schema])
        return {
          ...state,
          loading: false,
          didInvalidate: false,
          items: normalizedData.result,
          lastUpdated: action.receivedAt,
          error: null,
        }

      case actionTypes["listFailure"]:
        return {
          ...state,
          loading: false,
          error: action.error,
          lastUpdated: action.receivedAt,
        }

      case actionTypes["createSuccess"]:
      case actionTypes["updateSuccess"]:
        return {
          ...state,
          didInvalidate: true,
          error: null,
        }

      case actionTypes["deleteSuccess"]:
        return {
          ...state,
          loading: false,
          items: difference(state.items, [action.id]),
          error: null,
          lastUpdated: action.deletedAt || state.lastUpdated,
        }

      case actionTypes["invalidateList"]:
        return {
          ...state,
          didInvalidate: true,
          error: null,
        }

      case actionTypes["signoutSuccess"]:
        return {
          ...initState,
        }

      default:
        return state
    }
  }
}

export default listReducerFactory
