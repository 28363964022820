import { PARTICIPANT_STATES } from "participants"

class Block {
  constructor() {
    this.lessons = []
    this.available = true
    this.selected = false
    this.participated = false
    this.selectEvent = null
  }
}

export class BlocksBuilder {
  constructor(blockSize) {
    this.treshold = blockSize
    this.blocks = []
    this.currentBlock = new Block()
  }

  addLesson(lesson) {
    const block = this.currentBlock
    block.lessons.push(lesson)
    if (lesson.participationState === PARTICIPANT_STATES.approved) {
      block.participated = true
      block.available = false
    } else if (!lesson.hasFreePlaces) {
      block.available = false
    }
    if (block.lessons.length >= this.treshold) {
      this.blocks.push(block)
      this.currentBlock = new Block()
    }
  }

  getLessons() {
    let lessons = []
    this.blocks.forEach((block) => {
      if (block.available) {
        lessons = lessons.concat(block.lessons)
      }
    })
    return lessons
  }

  setupSelectionData(handler, selectedLessons) {
    this.blocks.forEach((block) => {
      if (selectedLessons[block.lessons[0].id] === true) {
        block.selected = true
      }
      block.selectEvent = (id) => {
        return handler(block.lessons.map((lesson) => lesson.id))
      }
    })
  }
}
