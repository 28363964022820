import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Button, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import {
  resetUserPasswordResetState,
  resetPasswordStateSelector,
  useResetPasswordEmailFormHandlers,
} from "../../password-reset"
import { ApiError } from "core/api"
import { FormError } from "core/components"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
}))

function userPasswordResetEmailForm() {
  const classes = useStyles()
  const [
    { email },
    { setValueFromEvent, onSubmit },
  ] = useResetPasswordEmailFormHandlers()
  const resetState = useSelector(resetPasswordStateSelector).start
  const error = resetState.error || new ApiError()
  const redirect = useRef(false)
  const dispatch = useDispatch()

  if (resetState.completed) {
    redirect.current = true
    dispatch(resetUserPasswordResetState())
  }

  if (redirect.current) {
    return <Redirect to={urls.resetPasswordInstructions()} />
  }

  return (
    <form onSubmit={onSubmit}>
      <TextField
        name="email"
        label="E-mail"
        value={email}
        onChange={setValueFromEvent("email")}
        fullWidth
        error={!!error.byFields.username}
        helperText={error.byFields.username}
        autoComplete="username"
      />
      {error.byFields.nonFieldErrors ? (
        <FormError>{error.byFields.nonFieldErrors}</FormError>
      ) : null}
      <Button
        variant="contained"
        type="submit"
        fullWidth
        className={classes.button}
        disabled={resetState.loading}
      >
        Восстановить
      </Button>
    </form>
  )
}

export default userPasswordResetEmailForm
