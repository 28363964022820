import { createSlice } from "@reduxjs/toolkit"
import { normalize } from "normalizr"

import courseSchema from "courses/schema"

import { failureSlice, invalidateSlice, saveSliceItems } from "common/reducers"

const initialState = {
  loading: false,
  didInvalidate: false,
  completed: false,
}

const participantsCreationSlice = createSlice({
  name: "participants-creation",
  initialState: { ...initialState },
  reducers: {
    startParticipantsCreation: {
      reducer: (state) => ({ ...state }),
      prepare: (data) => ({ payload: data }),
    },
    requestParticipantsCreation: (state) => {
      state.loading = true
      return state
    },
    finishParticipantsCreation: (state, action) =>
      saveSliceItems({
        state,
        items: normalize(action.payload.details, courseSchema).entities
          .participants,
      }),
    failParticipantsCreation: (state, action) =>
      failureSlice({ state, error: action.payload.error }),
    resetParticipantsCreation: () => ({ ...initialState }),
    invalidateParticipantsCreation: (state) => invalidateSlice({ state }),
  },
})

export const {
  startParticipantsCreation,
  requestParticipantsCreation,
  finishParticipantsCreation,
  failParticipantsCreation,
  resetParticipantsCreation,
  invalidateParticipantsCreation,
} = participantsCreationSlice.actions

export default participantsCreationSlice.reducer
