import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { updateUserPassword } from "./actions"
import { updatePasswordStateSelector } from "./selectors"
import { ApiError } from "core/api"
import { useFormHandlers } from "core/hooks"

export function usePasswordUpdateFormHandlers() {
  const dispatch = useDispatch()
  const [values, baseHandlers] = useFormHandlers(
    {
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    },
    true
  )

  const onSubmit = useCallback((event) => {
    event.preventDefault()
    dispatch(
      updateUserPassword(
        values.oldPassword,
        values.newPassword1,
        values.newPassword2
      )
    )
  })

  return [values, { ...baseHandlers, onSubmit }]
}

export function usePasswordUpdatingState() {
  const updateState = useSelector(updatePasswordStateSelector)

  return {
    loading: updateState.loading,
    didInvalidate: updateState.didInvalidate,
    error: updateState.error || new ApiError(),
  }
}
