/* global localStorage */
import { call, fork, put, take } from "redux-saga/effects"

import LOGIN_ACTION_TYPES from "./actiontypes"
import UsersAPI from "../api"

const {
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  UNAUTHORIZED_ERROR,
} = LOGIN_ACTION_TYPES

export function loginUser(username, password) {
  return {
    type: LOGIN,
    username,
    password,
  }
}

export function logoutUser() {
  return { type: LOGOUT }
}

export function removeJwt() {
  return { type: UNAUTHORIZED_ERROR }
}

function* login(username, password) {
  yield put({ type: LOGIN_REQUEST })
  try {
    const { access, refresh } = yield call(UsersAPI.login, username, password)
    localStorage.setItem("accessToken", access)
    localStorage.setItem("refreshToken", refresh)
    yield put({ type: LOGIN_SUCCESS, access, refresh })
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, error })
  }
}

export function* loginFlow() {
  while (true) {
    const { username, password } = yield take(LOGIN)
    yield fork(login, username, password)
    yield take(LOGIN_REQUEST)
    yield take([LOGIN_SUCCESS, LOGIN_FAILURE])
  }
}

function* logout() {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
  yield put({ type: LOGOUT_SUCCESS })
}

export function* logoutFlow() {
  while (true) {
    yield take(LOGOUT)
    yield fork(logout)
    yield take(LOGOUT_SUCCESS)
  }
}
