import { call, put, take, fork, select } from "redux-saga/effects"

import DICTIONARIES_ACTION_TYPES from "./actiontypes"
import DictionariesAPI from "./api"
import { dictionariesStateSelector } from "./selectors"

const {
  FETCH,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
} = DICTIONARIES_ACTION_TYPES

export function fetchDisctionaries() {
  return {
    type: FETCH,
  }
}

function* fetch() {
  yield put({ type: FETCH_REQUEST })
  try {
    const dictionaries = yield call(DictionariesAPI.fetchList)
    yield put({ type: FETCH_SUCCESS, dictionaries, receivedAt: Date.now() })
  } catch (error) {
    yield put({
      type: FETCH_FAILURE,
      status: error.message,
      payload: { error: error.message, status: error.statusCode },
    })
  }
}

function shouldFetchDictionaries(dictionariesState) {
  if (dictionariesState.isFetching) {
    return false
  } else if (!dictionariesState.lastUpdated) {
    return true
  } else {
    return dictionariesState.didInvalidate
  }
}

function* fetchDictionariesIfNeeded() {
  const dictionariesState = yield select(dictionariesStateSelector)
  if (shouldFetchDictionaries(dictionariesState)) {
    yield call(fetch)
  }
}

export function* fetchDictionariesFlow() {
  yield take(FETCH)
  while (true) {
    yield fork(fetchDictionariesIfNeeded)
    const { type } = yield take([FETCH, FETCH_REQUEST])
    if (type === FETCH) continue
    yield take([FETCH_SUCCESS, FETCH_FAILURE])
  }
}

export function* dictionariesSaga() {
  yield call(fetchDictionariesFlow)
}
