import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { createPayment } from "./actions"
import { createPaymentStateSelector } from "./selectors"

import {
  CURRENCY_CHOICE_RUB,
  CURRENCY_CHOICE_EURO,
  SYSTEM_YANDEX,
  SYSTEM_PAYPAL,
} from "payments/constants"
import { COURSE_STATES } from "courses"

import { PARTICIPANT_STATES } from "participants"

export function usePaymentCreationHandlers(course, lessons) {
  const dispatch = useDispatch()
  const [values, setValues] = React.useState(setupData(course, lessons))

  useEffect(() => {
    if (course.id !== values.currentCourse)
      setValues(setupData(course, lessons))

    return function cleanup() {
      setValues((state) => ({ ...state, selectedLessons: {} }))
    }
  }, [course])

  const selectLessonFromEvent = (lessonId) => (event) => {
    const target = event.target
    let changedLessons = {}

    if (Array.isArray(lessonId)) {
      lessonId.forEach((lesson) => (changedLessons[lesson] = target.checked))
    } else {
      changedLessons[lessonId] = target.checked
    }

    setValues((values) => ({
      ...values,
      selectedLessons: {
        ...values.selectedLessons,
        ...changedLessons,
      },
    }))
  }

  const setSelectedCurrency = (price) =>
    setValues((values) => ({
      ...values,
      selectedCurrency: price,
      canSelectLessons: !!price.lessonPrice,
    }))

  const create = (lessons) => (event) => {
    /* if lessons is set, that means user is willing to buy all lessons instead of selected ones */
    event.preventDefault()
    const system =
      values.selectedCurrency.currency === CURRENCY_CHOICE_EURO
        ? SYSTEM_PAYPAL
        : SYSTEM_YANDEX
    const data = {
      system,
      currency: values.selectedCurrency.currency,
      lessons: [],
    }
    if (lessons == null) {
      for (const [lessonId, isSelected] of Object.entries(
        values.selectedLessons
      )) {
        if (isSelected) {
          data.lessons.push(parseInt(lessonId))
        }
      }
    } else {
      lessons.forEach(
        (lesson) =>
          lesson.participationState !== PARTICIPANT_STATES.approved &&
          lesson.hasFreePlaces &&
          data.lessons.push(lesson.id)
      )
    }

    console.log(data)
    dispatch(createPayment(data))
  }

  return {
    currentCourse: values.currentCourse,
    selectedLessons: values.selectedLessons,
    selectedCurrency: values.selectedCurrency,
    canMakePayment: values.canMakePayment,
    canSelectLessons: values.canSelectLessons,
    selectLessonFromEvent: selectLessonFromEvent,
    setSelectedCurrency: setSelectedCurrency,
    createPayment: create,
  }
}

function setupData(course, lessons) {
  const rubleCurrency = course.prices.find(
    (price) => price.currency === CURRENCY_CHOICE_RUB
  )
  const initialCurrency = rubleCurrency || course.prices[0]
  const canMakePayment =
    course.state === COURSE_STATES.canBeSubscribed &&
    course.prices.length > 0 &&
    lessons.length > 0 &&
    !!lessons.find(
      (lesson) =>
        lesson.participationState !== PARTICIPANT_STATES.approved &&
        lesson.hasFreePlaces
    )
  const canSelectLessons = canMakePayment && !!initialCurrency.lessonPrice

  return {
    currentCourse: course.id,
    selectedLessons: {},
    selectedCurrency: initialCurrency,
    canMakePayment: canMakePayment,
    canSelectLessons: canSelectLessons,
  }
}

export function usePaymentCreationState() {
  return useSelector(createPaymentStateSelector)
}
