import DETAILS_ACTION_TYPES from "./actiontypes"
import { discipleDetailsStateSelector } from "./selectors"
import DisciplesAPI from "../api"
import { itemDetailsFetchingFlowFactory } from "core/factories/actions"

const {
  FETCH_DETAILS,
  FETCH_DETAILS_REQUEST,
  FETCH_DETAILS_SUCCESS,
  FETCH_DETAILS_FAILURE,
} = DETAILS_ACTION_TYPES

export function fetchDiscipleDetails(id) {
  return { type: FETCH_DETAILS, id }
}

export function* detailsFetchingFlow() {
  yield* itemDetailsFetchingFlowFactory(
    {
      fetch: FETCH_DETAILS,
      request: FETCH_DETAILS_REQUEST,
      success: FETCH_DETAILS_SUCCESS,
      failure: FETCH_DETAILS_FAILURE,
    },
    DisciplesAPI.fetchDetails,
    discipleDetailsStateSelector
  )()
}
