import { call, put, select, take, fork } from "redux-saga/effects"

export function* fetchCurrent(actionTypes, apiFunc) {
  yield put({ type: actionTypes["request"] })
  try {
    const details = yield call(apiFunc)
    yield put({
      type: actionTypes["success"],
      details,
      receivedAt: Date.now(),
    })
  } catch (error) {
    yield put({
      type: actionTypes["failure"],
      error: error.message,
      payload: { error: error.message, status: error.statusCode },
    })
  }
}

export function shouldFetchCurrent(itemState) {
  if (!itemState.error && !itemState.details) {
    return true
  } else if (itemState.loading) {
    return false
  } else {
    return itemState.didInvalidate
  }
}

export function* fetchCurrentIfNeeded(actionTypes, apiFunc, selectorFunc) {
  const itemState = yield select(selectorFunc)
  if (shouldFetchCurrent(itemState)) {
    yield call(fetchCurrent, actionTypes, apiFunc)
  }
}

export function currentFetchingFlowFactory(actionTypes, apiFunc, selectorFunc) {
  return function* () {
    let action = yield take(actionTypes["fetch"])
    while (true) {
      yield fork(fetchCurrentIfNeeded, actionTypes, apiFunc, selectorFunc)
      action = yield take([actionTypes["fetch"], actionTypes["request"]])
      if (action.type === actionTypes["fetch"]) {
        continue
      } else {
        yield take([actionTypes["success"], actionTypes["failure"]])
        action = yield take(actionTypes["fetch"])
      }
    }
  }
}
