import React from "react"
import PropTypes from "prop-types"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.colors.lightBackground,
    color: "black",
    borderRadius: 4,
    border: "none",
    fontSize: 18,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    height: 50,
    padding: "0 32px",
    margin: "45px auto 15px auto",

    "&:hover": {
      color: "black",
      backgroundColor: theme.colors.lightBackground,
    },

    "@media (min-width: 960px)": {
      margin: "60px auto 0 auto",
    },
  },
}))

export default function FeedbacksListLoadMoreButton({ loading, loadNextPage }) {
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      onClick={loadNextPage}
      disabled={loading}
    >
      Ещё отзывы
    </Button>
  )
}

FeedbacksListLoadMoreButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
}
