import listActionTypes from "./actiontypes"
import { librarySchema } from "../schema"
import { paginatedListReducerFactory } from "core/factories/reducers"

const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  INVALIDATE_LIST,
  RESET_PAGES,
} = listActionTypes

export default paginatedListReducerFactory(
  {
    listRequest: FETCH_LIST_REQUEST,
    listSuccess: FETCH_LIST_SUCCESS,
    listFailure: FETCH_LIST_FAILURE,
    invalidateList: INVALIDATE_LIST,
    resetPages: RESET_PAGES,
  },
  librarySchema
)
