const MAILOUTS_ACTION_TYPES = {
  SUBSCRIBE_FOR_MAILOUTS: "SUBSCRIBE_FOR_COURSE_MAILOUTS",
  SUBSCRIBE_FOR_MAILOUTS_REQUEST: "SUBSCRIBE_FOR_COURSE_MAILOUTS_REQUEST",
  SUBSCRIBE_FOR_MAILOUTS_SUCCESS: "SUBSCRIBE_FOR_COURSE_MAILOUTS_SUCCESS",
  SUBSCRIBE_FOR_MAILOUTS_FAILURE: "SUBSCRIBE_FOR_COURSE_MAILOUTS_FAILURE",
  UNSUBSCRIBE_FROM_MAILOUTS: "UNSUBSCRIBE_FROM_COURSE_MAILOUTS",
  UNSUBSCRIBE_FROM_MAILOUTS_REQUEST: "UNSUBSCRIBE_FROM_COURSE_MAILOUTS_REQUEST",
  UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS: "UNSUBSCRIBE_FROM_COURSE_MAILOUTS_SUCCESS",
  UNSUBSCRIBE_FROM_MAILOUTS_FAILURE: "UNSUBSCRIBE_FROM_COURSE_MAILOUTS_FAILURE",
  RESET: "RESET_MAILOUT_UPDATE",
}

export default MAILOUTS_ACTION_TYPES
