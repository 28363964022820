import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { ApiError, ApiErrorType } from "core/api"
import { RouterLink } from "core/components"
import urls from "core/urls"

const useStyles = makeStyles({
  profile: {
    marginTop: 60,
  },
  buttons: {
    marginTop: 40,
  },
})

function AdultProfileForm(props) {
  const classes = useStyles()
  const {
    firstName,
    lastName,
    email,
    yearOfBirth,
    country,
    mainLanguage,
    timezone,
    otherInfo,
    error,
    countries,
    timezones,
    yearsOfBirth,
    setValueFromEvent,
    onSubmit,
  } = props

  return (
    <form onSubmit={onSubmit} className={classes.profile}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Имя участника"
            name="firstName"
            value={firstName}
            fullWidth
            onChange={setValueFromEvent("firstName")}
            error={!!error.byFields.firstName}
            helperText={error.byFields.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Фамилия участника"
            name="lastName"
            value={lastName}
            fullWidth
            onChange={setValueFromEvent("lastName")}
            error={!!error.byFields.lastName}
            helperText={error.byFields.lastName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="E-mail"
            name="email"
            value={email}
            fullWidth
            onChange={setValueFromEvent("email")}
            error={!!error.byFields.email}
            helperText={error.byFields.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!error.byFields.yearOfBirth}>
            <InputLabel htmlFor="year-of-birth">Год рождения</InputLabel>
            <Select
              onChange={setValueFromEvent("yearOfBirth")}
              inputProps={{
                name: "yearOfBirth",
                id: "year-of-birth",
              }}
              value={yearOfBirth}
            >
              {yearsOfBirth.map((year) => (
                <MenuItem key={year} value={year}>
                  {year === 0
                    ? `раньше ${Math.min(
                        ...yearsOfBirth.filter((el) => el !== 0)
                      )}го`
                    : year}
                </MenuItem>
              ))}
            </Select>
            {error.byFields.yearOfBirth ? (
              <FormHelperText>{error.byFields.yearOfBirth}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!error.byFields.country}>
            <InputLabel htmlFor="country">Страна проживания</InputLabel>
            <Select
              onChange={setValueFromEvent("country")}
              inputProps={{
                name: "country",
                id: "country",
              }}
              value={country}
            >
              {countries.map((countryPair) => (
                <MenuItem key={countryPair[0]} value={countryPair[0]}>
                  {countryPair[1]}
                </MenuItem>
              ))}
            </Select>
            {error.byFields.country ? (
              <FormHelperText>{error.byFields.country}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!error.byFields.mainLanguage}>
            <InputLabel htmlFor="main-language">
              Основной язык общения
            </InputLabel>
            <Select
              onChange={setValueFromEvent("mainLanguage")}
              inputProps={{
                name: "mainLanguage",
                id: "main-language",
              }}
              value={mainLanguage}
            >
              <MenuItem value="Russian" key="russian">
                Русский
              </MenuItem>
              ,
              <MenuItem value="English" key="english">
                Английский
              </MenuItem>
              ,
              <MenuItem value="Other" key="other">
                Другой
              </MenuItem>
            </Select>
            {error.byFields.mainLanguage ? (
              <FormHelperText>{error.byFields.mainLanguage}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!error.byFields.timezone}>
            <InputLabel htmlFor="timezone">Часовой пояс</InputLabel>
            <Select
              onChange={setValueFromEvent("timezone")}
              inputProps={{
                name: "timezone",
                id: "timezone",
              }}
              value={timezone}
            >
              {timezones.map((timezonePair) => (
                <MenuItem key={timezonePair[0]} value={timezonePair[0]}>
                  {timezonePair[1]}
                </MenuItem>
              ))}
            </Select>
            {error.byFields.timezone ? (
              <FormHelperText>{error.byFields.timezone}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TextField
            label="Другая важная информация"
            name="otherInfo"
            value={otherInfo}
            fullWidth
            onChange={setValueFromEvent("otherInfo")}
            error={!!error.byFields.otherInfo}
            helperText={error.byFields.otherInfo}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={classes.buttons}
      >
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Сохранить
          </Button>
        </Grid>
        <Grid item>
          <Link
            component={RouterLink}
            to={urls.profile()}
            color="secondary"
            underline="none"
          >
            Отменить
          </Link>
        </Grid>
      </Grid>
    </form>
  )
}

AdultProfileForm.propTypes = {
  country: PropTypes.string,
  email: PropTypes.string.isRequired,
  error: PropTypes.shape(ApiErrorType),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  mainLanguage: PropTypes.string,
  setValueFromEvent: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  otherInfo: PropTypes.string,
  timezone: PropTypes.string,
  yearOfBirth: PropTypes.number,
}

AdultProfileForm.defaultProps = {
  country: "",
  error: new ApiError(),
  mainLanguage: "",
  otherInfo: "",
  timezone: "",
  yearOfBirth: 0,
}

export default AdultProfileForm
