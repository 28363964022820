import React from "react"
import PropTypes from "prop-types"
import { convertFromRaw } from "draft-js"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import FeedbacksListItemAuthor from "./ItemAuthor"
import FeedbacksListItemCourseAuthor from "./ItemCourseAuthor"
import FeedbacksListItemFooter from "./ItemFooter"
import { AuthorType } from "authors"
import { convertEditorStateToHtml } from "core/utils/text"
import { CourseType } from "courses"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    margin: `${theme.spacing(4)}px auto 0 auto`,
    fontSize: 18,
    lineHeight: 1.43,
    textAlign: "left",

    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(5)}px auto 0 auto`,
      fontSize: 21,
    },
  },
  text: {
    marginTop: theme.spacing(5),
  },
  meta: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
  },
}))

function createMarkup(html) {
  return { __html: html }
}

function getFeedbackContent(rawContent) {
  const classes = useStyles()
  try {
    const textAsHtml = createMarkup(
      convertEditorStateToHtml(convertFromRaw(JSON.parse(rawContent)))
    )
    return <div className={classes.text} dangerouslySetInnerHTML={textAsHtml} />
  } catch (error) {
    return <p>{rawContent}</p>
  }
}

export default function FeedbacksListItem(props) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))

  const { author, authorAge, course, courseAuthors, footer, text } = props
  return (
    <div className={classes.root}>
      {getFeedbackContent(text)}
      <Grid
        container
        justify="space-between"
        className={classes.meta}
        direction={mediumOrHigherScreen ? "row" : "column"}
      >
        <Grid item>
          <FeedbacksListItemAuthor name={author} age={authorAge} />
        </Grid>
        <Grid item>
          <FeedbacksListItemCourseAuthor
            course={course}
            authors={courseAuthors}
          />
        </Grid>
      </Grid>
      <FeedbacksListItemFooter text={footer} />
      {props.children}
    </div>
  )
}

FeedbacksListItem.propTypes = {
  author: PropTypes.string.isRequired,
  authorAge: PropTypes.number,
  course: PropTypes.shape(CourseType),
  courseAuthors: PropTypes.arrayOf(PropTypes.shape(AuthorType)),
  footer: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
