const initState = {
  loading: false,
  didInvalidate: false,
}

function itemState(actionTypes, state = initState, action) {
  switch (action.type) {
    case actionTypes["currentRequest"]:
      return {
        ...state,
        loading: true,
      }

    case actionTypes["currentSuccess"]:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        details: action.details,
        lastUpdated: action.receivedAt,
      }

    case actionTypes["currentFailure"]:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: action.error,
      }

    case actionTypes["invalidateCurrent"]:
      return {
        ...state,
        didInvalidate: true,
      }

    default:
      return state
  }
}

function currentReducerFactory(actionTypes) {
  const supportedActions = [
    "currentRequest",
    "currentSuccess",
    "currentFailure",
    "invalidateCurrent",
  ]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes["currentRequest"]:
      case actionTypes["currentSuccess"]:
      case actionTypes["currentFailure"]:
      case actionTypes["invalidateCurrent"]:
        return {
          ...itemState(actionTypes, state, action),
        }

      default:
        return state
    }
  }
}

export default currentReducerFactory
