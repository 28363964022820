import DETAILS_ACTION_TYPES from "./actiontypes"
import AUTH_ACTION_TYPES from "../login/actiontypes"

const { LOGOUT_SUCCESS } = AUTH_ACTION_TYPES

const {
  FETCH_DETAILS_REQUEST,
  FETCH_DETAILS_SUCCESS,
  FETCH_DETAILS_FAILURE,
} = DETAILS_ACTION_TYPES

const initialState = {
  loading: false,
  didInvalidate: false,
  error: null,
}

function detailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        id: action.details.id,
        lastUpdated: Date.now(),
        didInvalidate: false,
      }

    case FETCH_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: action.error,
        lastUpdated: action.receivedAt,
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default detailsReducer
