import React from "react"
import { Redirect } from "react-router-dom"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Error, Loader } from "core/components"
import urls from "core/urls"
import { useDictionaries } from "dictionaries"
import { DiscipleProfile, useDiscipleDetails } from "disciples"
import { useLoginInfo } from "users"

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 1000,
    padding: theme.spacing(0, 2),
    margin: `${theme.spacing(6)}px auto 0 auto`,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8),
    },
  },
  title: theme.presets.italicTitle,
}))

function ScreensAccountProfile() {
  const classes = useStyles()
  const { isLoggedIn } = useLoginInfo()
  const {
    discipleDetails: details,
    discipleDetailsState,
    discipleProfile: profile,
    isDiscipleDetailsLoaded,
  } = useDiscipleDetails(true)
  const {
    countries,
    dictionariesState,
    isDictionariesLoaded,
  } = useDictionaries()

  if (!isLoggedIn) {
    return <Redirect to={urls.login()} />
  }

  let mainContent
  if (isDiscipleDetailsLoaded && isDictionariesLoaded) {
    if (discipleDetailsState.error || dictionariesState.error) {
      mainContent = <Error errorText="Упс... что-то пошло не так" />
    } else {
      let localizedCountryName
      if (profile && profile.country) {
        localizedCountryName = profile.country
        const countryPair = countries.find((c) => c[0] === profile.country)
        if (countryPair) {
          localizedCountryName = countryPair[1]
        }
      }
      mainContent = (
        <div className={classes.content}>
          <Typography variant="h1" align="center" className={classes.title}>
            Личный кабинет участника
          </Typography>
          <DiscipleProfile
            country={localizedCountryName}
            email={details.email}
            firstName={details.firstName}
            language={profile && profile.mainLanguage}
            lastName={details.lastName}
            otherInfo={profile && profile.otherInfo}
            readWriteProblems={profile && profile.readWriteProblems}
            timezone={profile && profile.timezone}
            visitSchool={profile && profile.visitSchool}
            yearOfBirth={details.yearOfBirth}
          />
        </div>
      )
    }
  } else {
    mainContent = <Loader />
  }

  return mainContent
}

export default ScreensAccountProfile
