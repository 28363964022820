import { normalize } from "normalizr"

import LIST_ACTION_TYPES from "./actiontypes"
import { lessonSchema } from "../schema"
import courseActionTypes from "courses/actiontypes"
import courseSchema from "courses/schema"

const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  INVALIDATE_LIST,
} = LIST_ACTION_TYPES

const {
  FETCH_DETAILS_SUCCESS: FETCH_COURSE_DETAILS_SUCCESS,
} = courseActionTypes

function lessons(
  state = {
    loading: false,
    didInvalidate: false,
    items: [],
  },
  action
) {
  let normalizedData
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case INVALIDATE_LIST:
      return {
        ...state,
        didInvalidate: true,
      }

    case FETCH_LIST_SUCCESS:
      normalizedData = normalize(action.items, [lessonSchema])
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        items: normalizedData.result,
        lastUpdated: action.receivedAt,
      }

    case FETCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        lastUpdated: action.receivedAt,
        didInvalidate: false,
        error: action.error,
      }

    case FETCH_COURSE_DETAILS_SUCCESS:
      normalizedData = normalize(action.details, courseSchema)
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        items: normalizedData.entities.courses[action.details.id].lessons,
        lastUpdated: action.receivedAt,
      }

    default:
      return state
  }
}

function listByCourseIdReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
    case FETCH_LIST_SUCCESS:
    case FETCH_LIST_FAILURE:
    case INVALIDATE_LIST:
      return {
        ...state,
        [action.courseId]: lessons(state[action.courseId], action),
      }

    case FETCH_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        [action.details.id]: lessons(state[action.details.id], action),
      }

    default:
      return state
  }
}

export default listByCourseIdReducer
