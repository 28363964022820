import React from "react"
import PropTypes from "prop-types"
import { pickBy, without, sortBy } from "lodash"
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Separator } from "core/components"
import { CourseType } from "courses"
import { LibraryCategoryType } from "library-categories"

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(2),
  },

  sectionTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },

  sectionItems: {
    "& div": {
      margin: theme.spacing(0.2, 1),
    },
  },

  controlLabel: {
    fontSize: 16,
  },
}))

function LibraryListFilters(props) {
  const classes = useStyles()
  const { categories, courses, filters, setFilters } = props
  const onFilterChange = (field, value) => (event) => {
    const currentFilter = (filters[field] || []).concat()
    const newFilter = event.target.checked
      ? currentFilter.concat([value])
      : without(currentFilter, value)
    // if filter array is empty we delete it from filters
    setFilters(
      pickBy(
        {
          ...filters,
          [field]: newFilter,
        },
        (v) => v.length > 0
      )
    )
  }
  return (
    <div>
      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>ТИП МАТЕРИАЛА</Typography>
        <FormGroup>
          <Grid container justify="center" className={classes.sectionItems}>
            {categories.map((category) => (
              <Grid item key={category.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (filters.categories &&
                          filters.categories.includes(category.id)) ||
                        false
                      }
                      onChange={onFilterChange("categories", category.id)}
                      color="default"
                    />
                  }
                  label={
                    <Typography className={classes.controlLabel}>
                      {category.name}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </div>
      <Separator />
      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>КУРСЫ</Typography>
        <FormGroup>
          <Grid container justify="center" className={classes.sectionItems}>
            {sortBy(courses, (c) => c.title).map((course) => (
              <Grid item key={course.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        (filters.courses &&
                          filters.courses.includes(course.id)) ||
                        false
                      }
                      onChange={onFilterChange("courses", course.id)}
                      color="default"
                    />
                  }
                  label={
                    <Typography className={classes.controlLabel}>
                      {course.title}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </div>
    </div>
  )
}

LibraryListFilters.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(LibraryCategoryType)),
  courses: PropTypes.arrayOf(PropTypes.shape(CourseType)),
  filters: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.number),
    courses: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
}

export default LibraryListFilters
