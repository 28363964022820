import React from "react"
import { Route, Switch } from "react-router-dom"
import { CssBaseline } from "@material-ui/core"

import {
  ScreensAbout,
  ScreensAccount,
  ScreensCourseDetails,
  ScreensCourseTimetable,
  ScreensFeedbacks,
  ScreensHome,
  ScreensLibrary,
  ScreensPolicy,
  ScreensUserLogin,
  ScreensUserResetPassword,
  ScreensUserPasswordResetCompletion,
  ScreensUserResetPasswordInstructions,
  ScreensUserPasswordResetSuccess,
  ScreensUserSignUp,
  ScreensSignUpConfirmation,
  ScreensUserSignUpConfirmEmailSent,
  ScreensWaitingForPayment,
} from "screens"

import "assets/css/vagantes.css"

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Switch>
        <Route exact path="/" component={ScreensHome} />
        <Route path="/about" component={ScreensAbout} />
        <Route path="/account" component={ScreensAccount} />
        <Route path="/courses/:id" component={ScreensCourseDetails} />
        <Route path="/timetable" component={ScreensCourseTimetable} />
        <Route path="/feedbacks" component={ScreensFeedbacks} />
        <Route path="/library" component={ScreensLibrary} />
        <Route path="/policy" component={ScreensPolicy} />
        <Route path="/user/login" component={ScreensUserLogin} />
        <Route exact path="/user/signup" component={ScreensUserSignUp} />
        <Route
          exact
          path="/user/reset-password"
          component={ScreensUserResetPassword}
        />
        <Route
          path="/user/reset-password/completion/:uidb64/:token/"
          component={ScreensUserPasswordResetCompletion}
        />
        <Route
          path="/user/reset-password/instructions"
          component={ScreensUserResetPasswordInstructions}
        />
        <Route
          path="/user/reset-password/success"
          component={ScreensUserPasswordResetSuccess}
        />
        <Route
          path="/user/signup-email-sent"
          component={ScreensUserSignUpConfirmEmailSent}
        />
        <Route
          path="/user/confirm-signup/:uidb64/:token/"
          component={ScreensSignUpConfirmation}
        />
        <Route
          path="/waiting-for-payment"
          component={ScreensWaitingForPayment}
        />
      </Switch>
    </React.Fragment>
  )
}

export default App
