import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { ThemeProvider } from "@material-ui/styles"

import App from "./app/App"
import { ScrollToTop } from "./core/components"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./app/store"
import theme from "./theme"

function Root({ store, persistor }) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollToTop>
              <Route path="/" component={App} />
            </ScrollToTop>
          </Router>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  )
}

const { store, persistor } = configureStore()

render(
  <Root store={store} persistor={persistor} />,
  document.getElementById("root")
)

serviceWorker.unregister()
