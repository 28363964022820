import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Error, Loader } from "core/components"
import { useDictionaries } from "dictionaries"
import { DiscipleProfileForm, useDiscipleDetails } from "disciples"
import { UserPasswordUpdateForm } from "users"

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 1000,
    padding: theme.spacing(0, 2),
    margin: `${theme.spacing(6)}px auto 0 auto`,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8),
    },
  },
}))

function ScreensAccountProfileEditor() {
  const classes = useStyles()
  const {
    discipleDetails: details,
    discipleProfile: profile,
    discipleDetailsState,
    isDiscipleDetailsLoaded,
  } = useDiscipleDetails()
  const {
    dictionariesState,
    isDictionariesLoaded,
    countries,
    timezones,
  } = useDictionaries()
  let content
  if (isDictionariesLoaded && isDiscipleDetailsLoaded) {
    if (discipleDetailsState.error || dictionariesState.error) {
      content = <Error errorText="Упс... что-то пошло не так" />
    } else {
      content = (
        <div className={classes.content}>
          <Typography variant="h1" align="center" css="margin: 22px 0 50px 0">
            Личный кабинет участника
          </Typography>
          <DiscipleProfileForm
            id={details.id}
            email={details.email}
            firstName={details.firstName}
            lastName={details.lastName}
            country={profile && profile.country}
            mainLanguage={profile && profile.mainLanguage}
            otherInfo={profile && profile.otherInfo}
            readWriteProblems={profile && profile.readWriteProblems}
            timezone={profile && profile.timezone}
            visitSchool={profile && profile.visitSchool}
            yearOfBirth={details.yearOfBirth}
            countries={countries}
            timezones={timezones}
          />
          <UserPasswordUpdateForm />
        </div>
      )
    }
  } else {
    content = <Loader />
  }
  return content
}

export default ScreensAccountProfileEditor
