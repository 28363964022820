import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class LessonsAPI {
  fetchList(courseId) {
    return handleErrors(
      fetch(`${endpoints.lessonsList()}?course=${courseId}`, {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new LessonsAPI()
