import { useDispatch, useSelector } from "react-redux"

import { loginUser } from "./actions"
import { loginStateSelector } from "./selectors"
import { ApiError } from "core/api"
import {
  isLoggedIn,
  isAccessTokenExpired,
  isRefreshTokenExpired,
} from "./utils"
import { useFormHandlers } from "core/hooks"

export function useLoginInfo() {
  const loginState = useSelector(loginStateSelector)
  return {
    accessToken: loginState.access,
    refreshToken: loginState.refresh,
    isAccessTokenExpired: isAccessTokenExpired(loginState),
    isLoggedIn: isLoggedIn(loginState),
    isRefreshTokenExpired: isRefreshTokenExpired(loginState),
  }
}

export function useLoginFormHandlers() {
  const dispatch = useDispatch()
  const [values, baseHandlers] = useFormHandlers({
    email: "",
    password: "",
  })

  const login = (event) => {
    event.preventDefault()
    dispatch(loginUser(values.email, values.password))
  }

  return [values, { ...baseHandlers, login }]
}

export function useLoginState() {
  const reduxState = useSelector(loginStateSelector)
  return {
    loading: reduxState.loading,
    completed: reduxState.completed,
    error: reduxState.error || new ApiError(),
  }
}
