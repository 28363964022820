import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchCourseDetails } from "./actions"
import { courseDetailsSelector, courseDetailsStateSelector } from "./selectors"
import { authorEntitiesSelector } from "authors/entities/selectors"
import { lessonEntitiesSelector } from "lessons/entities/selectors"
import { participantEntitiesSelector } from "features/participants/entities/selector"

export function useCourseDetails(courseId, nested) {
  const dispatch = useDispatch()
  const getDetails = useCallback(() => {
    dispatch(fetchCourseDetails(courseId))
  }, [courseId])
  useEffect(getDetails, [getDetails])

  const detailsState = useSelector((state) =>
    courseDetailsStateSelector(state, courseId)
  )
  let details = useSelector((state) => courseDetailsSelector(state, courseId))
  const authors = useSelector(authorEntitiesSelector)
  const lessons = useSelector(lessonEntitiesSelector)
  const participants = useSelector(participantEntitiesSelector)

  if (nested) {
    details = {
      ...details,
      authors: details && details.authors.map((authorId) => authors[authorId]),
      lessons:
        details &&
        details.lessons.map((lessonId) => {
          let lesson = lessons[lessonId]
          if (lesson.participant !== null) {
            lesson = {
              ...lesson,
              participant: participants[lesson.participant],
            }
          }
          return lesson
        }),
    }
  }
  return {
    courseDetails: details,
    courseDetailsState: detailsState,
    isCourseDetailsLoaded: !!detailsState && !detailsState.loading,
  }
}
