import { listActionTypes } from "../list"
import { librarySchema } from "../schema"
import { entitiesReducerFactory } from "core/factories/reducers"

const { FETCH_LIST_SUCCESS } = listActionTypes

const libraryEntitiesReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_LIST_SUCCESS,
  },
  librarySchema,
  (normalizedData) => normalizedData.entities.libraryItems
)

export default libraryEntitiesReducer
