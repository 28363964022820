import React from "react"
import PropTypes from "prop-types"
import { PriceType } from "payments"
import { CourseType } from "courses"
import { LessonType } from "lessons"
import { useLoginInfo } from "users"
import { formatTextByNumber } from "core/utils/text"
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined"

import {
  CURRENCY_CHOICE_OPTIONS,
  CURRENCY_CHOICE_NAMES,
  WHOLESALE_SCORE,
} from "../constants"

import {
  Button,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Fade,
  Tooltip,
  Checkbox,
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/styles"
import { PARTICIPANT_STATES } from "participants"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },

  focusedPrice: {
    fontSize: 54,
  },

  decoratedHeader: {
    color: "black",
    textAlign: "center",
  },

  decoratedHeaderSides: {
    paddingTop: 17,
    borderBottom: "1px solid black",
  },

  priceGridValue: {
    fontSize: 15,
  },

  priceGridKey: {
    fontSize: 21,
    color: "#a9a389",
  },

  priceGridItem: {
    borderBottom: "1px solid #aca68d",
    textAlign: "center",
    transition: "border-color 1s, box-shadow 1s",
  },

  priceGridActive: {
    borderColor: "#292405",
    boxShadow: "0px 2px 0px 0px rgb(226 226 226)",
  },

  priceGridHalfBorderL: {
    float: "left",
    width: 1,
    height: 5,
    marginTop: 51,
    borderLeft: "1px solid #aca68d",
  },

  priceGridHalfBorderR: {
    float: "right",
    width: 1,
    height: 5,
    borderLeft: "1px solid #aca68d",
  },

  upperPayButton: {
    float: "right",
  },

  bottomPayButton: {
    marginTop: 10,
    background: "#7d7336",
    animationDuration: "0.5s",
    animationName: "expandOnEnter",
  },

  mainText: {
    fontSize: 19,
  },

  input: {
    width: 155,
  },

  tooltipHelper: {
    padding: 10,
    fontSize: "1.5em",
  },

  tooltipTarget: {
    cursor: "help",
  },

  helpIcon: {
    fontSize: "1em",
    color: "#887b2c", //#ceb964
    marginBottom: -4,
  },
}))

const GlobalCss = withStyles({
  "@global": {
    ".MuiInput-underline:before": {
      borderBottom: "none",
    },
    "@keyframes expandOnEnter": {
      from: {
        opacity: 0,
        width: 0,
        height: 12,
      },
      "20%": {
        width: "100%",
        opacity: 1,
      },
      "50%": {
        height: 38,
      },
      "95%": {
        height: 42,
      },
      to: {
        height: "auto",
      },
    },
    "@keyframes collapseOnExit": {
      from: {
        height: 42,
      },
      to: {
        height: 12,
      },
    },
  },
})(() => null)

function PaymentCreationForm(props) {
  const { isLoggedIn } = useLoginInfo()
  const classes = useStyles()
  const {
    courseDetails,
    lessonsList,
    isCoursePriceAvailable,
    selectedLessons,
    canMakePayment,
    canSelectLessons,
    selectedCurrency,
    setSelectedCurrency,
    createPayment,
    loading,
  } = props

  const handleCurrencySwitch = (event, newValue) => {
    setSelectedCurrency(
      courseDetails.prices.find((price) => price.currency === newValue.key)
    )
  }

  const filteredLessons = Object.values(selectedLessons).filter(
    // parse only checked lessons (in selectable list)
    (value) => value === true
  )

  const optionalPrices = canSelectLessons && (
    <Grid container spacing={3}>
      {selectedCurrency.coursePrice ? (
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs>
              <div className={classes.decoratedHeaderSides} />
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.decoratedHeader}
              >
                или
              </Typography>
            </Grid>
            <Grid item xs>
              <div className={classes.decoratedHeaderSides} />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {getPaymentText(courseDetails.lessonsBlockSize, classes)}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0} comment="Grid with prices">
          <Grid
            item
            xs={6}
            className={
              (filteredLessons.length > 0 &&
              filteredLessons.length < WHOLESALE_SCORE
                ? classes.priceGridActive + " "
                : "") + classes.priceGridItem
            }
          >
            <div className={classes.priceGridHalfBorderL} />
            <div className={classes.priceGridValue}>
              <span className={classes.priceGridKey}>
                {selectedCurrency.lessonPrice
                  ? selectedCurrency.lessonPrice +
                    CURRENCY_CHOICE_OPTIONS[selectedCurrency.currency]
                  : "---"}
              </span>
              <br />
              одно занятие
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className={
              (filteredLessons.length >= WHOLESALE_SCORE
                ? classes.priceGridActive + " "
                : "") + classes.priceGridItem
            }
          >
            <div className={classes.priceGridHalfBorderL} />
            <div className={classes.priceGridValue}>
              <span className={classes.priceGridKey}>
                {selectedCurrency.lessonWholesalePrice
                  ? selectedCurrency.lessonWholesalePrice +
                    CURRENCY_CHOICE_OPTIONS[selectedCurrency.currency]
                  : "---"}
              </span>
              <br />
              пять и более
            </div>
            <div className={classes.priceGridHalfBorderR} />
          </Grid>
        </Grid>
      </Grid>
      {!selectedCurrency.coursePrice ? (
        <Grid item xs={6}>
          <TextField
            className={classes.input}
            id="select-currency"
            select
            label="выберите валюту"
            value={selectedCurrency.currency}
            onChange={handleCurrencySwitch}
          >
            {Object.values(courseDetails.prices).map((price) => (
              <MenuItem key={price.currency} value={price.currency}>
                {"цена " + CURRENCY_CHOICE_NAMES[price.currency]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        Если у Вас есть причины просить о скидке, пишите по адресу:{" "}
        <a href={`mailto: ${courseDetails.authors[0].email}`}>
          {courseDetails.authors[0].email}
        </a>
      </Grid>
      <Grid item xs={12}>
        <Fade
          in={filteredLessons.length > 0}
          mountOnEnter
          unmountOnExit
          onExit={(node) => {
            node.style.animationName = "collapseOnExit"
          }}
        >
          <Button
            variant="contained"
            className={classes.bottomPayButton}
            onClick={createPayment()}
            disabled={!canMakePayment || !isLoggedIn || loading}
            fullWidth
          >
            Оплатить {declinationHelper(filteredLessons.length)} на{" "}
            {priceHelper(filteredLessons, selectedCurrency)}
            {CURRENCY_CHOICE_OPTIONS[selectedCurrency.currency]}
          </Button>
        </Fade>
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>
      <GlobalCss />
      <Grid container spacing={3}>
        {selectedCurrency.coursePrice ? (
          <Grid item xs={12}>
            <em className={classes.focusedPrice}>
              {(maxPriceCalculator(
                lessonsList,
                selectedCurrency,
                isCoursePriceAvailable
              ) || "Нет цены курса ") +
                CURRENCY_CHOICE_OPTIONS[selectedCurrency.currency]}
            </em>
            <br />
            <TextField
              className={classes.input}
              id="select-currency"
              select
              label="выберите валюту"
              value={selectedCurrency.currency}
              onChange={handleCurrencySwitch}
            >
              {Object.values(courseDetails.prices).map((price) => (
                <MenuItem key={price.currency} value={price.currency}>
                  {(maxPriceCalculator(
                    lessonsList,
                    price,
                    isCoursePriceAvailable
                  ) || "Нет цены ") + CURRENCY_CHOICE_OPTIONS[price.currency]}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              disabled={
                !canMakePayment ||
                !isLoggedIn ||
                loading ||
                !maxPriceCalculator(
                  lessonsList,
                  selectedCurrency,
                  isCoursePriceAvailable
                )
              }
              className={classes.upperPayButton}
              onClick={createPayment(lessonsList)}
            >
              Оплатить
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {optionalPrices}
        </Grid>
      </Grid>
    </div>
  )
}

function maxPriceCalculator(lessonsList, price, isCoursePriceAvailable) {
  const availableLessons = lessonsList.filter(
    (lesson) =>
      lesson.participationState !== PARTICIPANT_STATES.approved &&
      lesson.hasFreePlaces
  )
  return availableLessons.length === lessonsList.length
    ? (isCoursePriceAvailable && price.coursePrice) ||
        (price.lessonPrice ? priceHelper(availableLessons, price) : 0)
    : priceHelper(availableLessons, price)
}

function priceHelper(lessons, price) {
  return (
    lessons.length *
    (lessons.length < WHOLESALE_SCORE
      ? price.lessonPrice || 0
      : price.lessonWholesalePrice || price.lessonPrice || 0)
  )
}

function declinationHelper(number) {
  return formatTextByNumber(number, "занятие", "занятий", "занятия")
}

function getPaymentText(blockSize, classes) {
  switch (blockSize) {
    case 1:
      return (
        <Typography variant="body1" component="h2" className={classes.mainText}>
          Вы можете оплатить любое количество занятий. Стоимость каждого:
        </Typography>
      )
    default:
      return (
        <Typography variant="body1" component="h2" className={classes.mainText}>
          Вы можете оплатить часть&nbsp;
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <div className={classes.tooltipHelper}>
                Возле названия занятий есть галочка:{" "}
                <Checkbox checked={false} name={"CheckboxExample"} /> - на
                которую можно кликнуть.
              </div>
            }
          >
            <span className={classes.tooltipTarget}>
              курса <HelpOutlineOutlinedIcon className={classes.helpIcon} />
            </span>
          </Tooltip>
          ({declinationHelper(blockSize)}). О возможности посетить отдельные
          занятия спрашивайте преподавателя. Стоимость каждого занятия:
        </Typography>
      )
  }
}

PaymentCreationForm.propTypes = {
  courseDetails: PropTypes.shape(CourseType).isRequired,
  lessonsList: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
  isCoursePriceAvailable: PropTypes.bool.isRequired,
  selectedLessons: PropTypes.object.isRequired,
  canMakePayment: PropTypes.bool.isRequired,
  canSelectLessons: PropTypes.bool.isRequired,
  createPayment: PropTypes.func.isRequired,
  selectedCurrency: PropTypes.shape(PriceType).isRequired,
  setSelectedCurrency: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

PaymentCreationForm.defaultProps = {
  loading: false,
}

export default PaymentCreationForm
