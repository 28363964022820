const SIGNUP_ACTION_TYPES = {
  SIGNUP: "SIGNUP_USER",
  SIGNUP_REQUEST: "SIGNUP_USER_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_USER_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_USER_FAILURE",
  RESET_SIGNUP: "RESET_SIGNUP",

  CONFIRM_SIGNUP: "CONFIRM_SIGNUP",
  CONFIRM_SIGNUP_REQUEST: "CONFIRM_SIGNUP_REQUEST",
  CONFIRM_SIGNUP_SUCCESS: "CONFIRM_SIGNUP_SUCCESS",
  CONFIRM_SIGNUP_FAILURE: "CONFIRM_SIGNUP_FAILURE",
}

export default SIGNUP_ACTION_TYPES
