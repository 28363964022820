import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchCoursesList } from "./actions"
import { coursesListSelector, coursesListStateSelector } from "./selectors"
import { authorEntitiesSelector } from "authors/entities/selectors"
import { lessonEntitiesSelector } from "lessons/entities/selectors"
import { participantEntitiesSelector } from "features/participants/entities/selector"

export function useCoursesList(nestedFields) {
  const dispatch = useDispatch()
  const fetchList = useCallback(() => {
    dispatch(fetchCoursesList())
  })
  useEffect(fetchList, [])

  const coursesListState = useSelector(coursesListStateSelector)
  let coursesList = useSelector(coursesListSelector)
  const authors = useSelector(authorEntitiesSelector)
  const lessons = useSelector(lessonEntitiesSelector)
  const participants = useSelector(participantEntitiesSelector)
  if (nestedFields) {
    coursesList = coursesList.map((course) => ({
      ...course,
      authors: nestedFields.includes("authors")
        ? course.authors.map((authorId) => authors[authorId])
        : course.authors,
      lessons: nestedFields.includes("lessons")
        ? course.lessons.map((lessonId) => {
            let lesson = lessons[lessonId]
            if (lesson.participant !== null) {
              lesson = {
                ...lesson,
                participant: participants[lesson.participant],
              }
            }
            return lesson
          })
        : course.lessons,
    }))
  }
  return {
    coursesList,
    coursesListState,
    isCoursesListLoaded:
      !!coursesListState.lastUpdated && !coursesListState.loading,
  }
}
