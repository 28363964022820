import { itemCreationFlowFactory } from "core/factories/actions"
import CREATION_ACTION_TYPES from "./actiontypes"
import PaymentAPI from "../api"

const {
  CREATE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET,
} = CREATION_ACTION_TYPES

export function createPayment(data) {
  return {
    type: CREATE,
    data,
  }
}

export function* creationFlow() {
  yield* itemCreationFlowFactory(
    {
      fetch: CREATE,
      request: CREATE_REQUEST,
      success: CREATE_SUCCESS,
      failure: CREATE_FAILURE,
      reset: RESET,
    },
    () => PaymentAPI.create
  )()
}
