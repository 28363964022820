import { call, fork, put, select, take } from "redux-saga/effects"

import PASSWORD_UPDATE_ACTION_TYPES from "./actiontypes"
import { updatePasswordStateSelector } from "./selectors"
import UsersAPI from "../api"

const {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
} = PASSWORD_UPDATE_ACTION_TYPES

function* updatePassword(oldPassword, newPassword1, newPassword2) {
  yield put({ type: UPDATE_PASSWORD_REQUEST })
  try {
    yield call(UsersAPI.updatePassword, {
      oldPassword,
      newPassword1,
      newPassword2,
    })
    yield put({ type: UPDATE_PASSWORD_SUCCESS })
  } catch (error) {
    yield put({ type: UPDATE_PASSWORD_FAILURE, error })
  }
}

function shouldUpdatePassword(updatePasswordState) {
  return !updatePasswordState.loading
}

function* updatePasswordIfNeeded(oldPassword, newPassword1, newPassword2) {
  const updatePasswordState = yield select(updatePasswordStateSelector)
  if (shouldUpdatePassword(updatePasswordState)) {
    yield call(updatePassword, oldPassword, newPassword1, newPassword2)
  }
}

export default function* updatingPasswordFlow() {
  while (true) {
    let { oldPassword, newPassword1, newPassword2 } = yield take(
      UPDATE_PASSWORD
    )
    yield fork(updatePasswordIfNeeded, oldPassword, newPassword1, newPassword2)
    yield take(UPDATE_PASSWORD_REQUEST)
    yield take([UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE])
  }
}
