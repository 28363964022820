import React from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.colors.redText,
    textAlign: "center",
    margin: "20px auto",
  },
}))

function FormError({ children }) {
  const classes = useStyles()
  return <div className={classes.error}>{children}</div>
}

export default FormError
