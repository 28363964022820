import React from "react"
import PropTypes from "prop-types"
import { CourseTypeNested } from "courses"
import { LessonType } from "lessons"
import { makeStyles } from "@material-ui/styles"

import {
  MailoutsSubscribeButton,
  MailoutsUnsubscribeButton,
} from "courses/mailouts"
import PaymentCreationForm from "payments/components/CreationForm"
import ParticipantCreationForm from "./CreationForm"
import ParticipantUpdatingForm from "./UpdatingForm"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },

  header: {
    fontSize: 27,
    lineHeight: 1.33,
    marginBottom: 20,
  },

  subSpace: {
    paddingTop: 20,
  },

  status: {
    paddingBottom: 14,
  },
}))

/* Ensure that user is logged in */
function ParticipantForm(props) {
  const classes = useStyles()
  const {
    courseDetails,
    lessonsList,
    paymentHandlers,
    loading,
    purchasedLessons,
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {purchasedLessons.length > 0
          ? "Участник курса"
          : "Запись на курс открыта"}
      </div>
      {purchasedLessons.length > 0 && (
        <div className={classes.status}>
          Вам доступн
          {purchasedLessons.length === lessonsList.length
            ? "ы все лекции"
            : `о ${purchasedLessons.length} из ${lessonsList.length} лекций${
                paymentHandlers.canMakePayment
                  ? ", и при желании вы можете приобрести остальные за"
                  : ""
              }`}
        </div>
      )}
      {paymentHandlers.canMakePayment && (
        <PaymentCreationForm
          courseDetails={courseDetails}
          lessonsList={lessonsList}
          selectedLessons={paymentHandlers.selectedLessons}
          canSelectLessons={paymentHandlers.canSelectLessons}
          selectedCurrency={paymentHandlers.selectedCurrency}
          setSelectedCurrency={paymentHandlers.setSelectedCurrency}
          createPayment={paymentHandlers.createPayment}
          loading={loading}
        />
      )}
      {courseDetails.prices.length === 0 &&
        lessonsList.length > 0 &&
        (lessonsList[0].participant ? (
          <ParticipantUpdatingForm
            participant={lessonsList[0].participant}
            courseId={courseDetails.id}
          />
        ) : (
          <ParticipantCreationForm courseId={courseDetails.id} />
        ))}
      <div className={classes.subSpace} />
      {purchasedLessons.length === 0 &&
        (courseDetails.subscribedForMailouts ? (
          <MailoutsUnsubscribeButton course={courseDetails.id} />
        ) : (
          <MailoutsSubscribeButton course={courseDetails.id} />
        ))}
    </div>
  )
}

ParticipantForm.propTypes = {
  courseDetails: PropTypes.shape(CourseTypeNested).isRequired,
  lessonsList: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
  paymentHandlers: PropTypes.object.isRequired,
  purchasedLessons: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export default ParticipantForm
