import PASSWORD_UPDATE_ACTION_TYPES from "./actiontypes"

const { UPDATE_PASSWORD, UPDATE_PASSWORD_RESET } = PASSWORD_UPDATE_ACTION_TYPES

export function updateUserPassword(oldPassword, newPassword1, newPassword2) {
  return {
    type: UPDATE_PASSWORD,
    oldPassword,
    newPassword1,
    newPassword2,
  }
}

export function resetUserPasswordUpdating() {
  return {
    type: UPDATE_PASSWORD_RESET,
  }
}
