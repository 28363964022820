const CREATION_ACTION_TYPES = {
  CREATE: "CREATE_PARTICIPANT",
  CREATE_REQUEST: "CREATE_PARTICIPANT_REQUEST",
  CREATE_SUCCESS: "CREATE_PARTICIPANT_SUCCESS",
  CREATE_FAILURE: "CREATE_PARTICIPANT_FAILURE",
  RESET: "RESET_PARTICIPANT_CREATION",
  INVALIDATE: "INVALIDATE_PARTICIPANT_CREATION",
}

export default CREATION_ACTION_TYPES
