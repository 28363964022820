export function getAccessToken(loginState) {
  if (loginState.access) {
    return loginState.access.token
  }
}

export function getRefreshToken(loginState) {
  if (loginState.refresh) {
    return loginState.refresh.token
  }
}

export function isAccessTokenExpired(loginState) {
  if (loginState.access && loginState.access.exp) {
    return 1000 * loginState.access.exp - new Date().getTime() < 5000
  }
  return true
}

export function isRefreshTokenExpired(loginState) {
  if (loginState.refresh && loginState.refresh.exp) {
    return 1000 * loginState.refresh.exp - new Date().getTime() < 5000
  }
  return true
}

export function isLoggedIn(loginState) {
  return !isAccessTokenExpired(loginState)
}
