import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchAuthorsList } from "./actions"
import { authorsListSelector, authorsListStateSelector } from "./selectors"

export function useAuthorsList() {
  const dispatch = useDispatch()
  const fetchList = useCallback(() => {
    dispatch(fetchAuthorsList())
  })
  useEffect(fetchList, [])

  const authorsListState = useSelector(authorsListStateSelector)
  const authorsList = useSelector(authorsListSelector)

  return {
    authorsList,
    authorsListState,
    isAuthorsListLoaded:
      !!authorsListState.lastUpdated && !authorsListState.loading,
  }
}
