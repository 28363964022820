import React from "react"
import PropTypes from "prop-types"
import { convertFromRaw } from "draft-js"
import { Link } from "react-router-dom"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { convertEditorStateToHtml } from "core/utils/text"
import urls from "core/urls"

function createMarkup(html) {
  return { __html: html }
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    margin: "0 auto",
    padding: theme.spacing(0, 1.5),
  },
  mainText: {
    fontSize: 24,
  },
  footer: {
    marginTop: theme.spacing(3),
  },
  source: {
    fontSize: 21,
  },
  libraryLink: {
    fontSize: 21,
    margin: theme.spacing(1.5, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 auto",
    },

    "& a": {
      color: theme.colors.lightText,
      textDecoration: "underline",
      textDecorationColor: theme.colors.lightBorder,
    },
  },
  course: {
    fontSize: 18,
    margin: theme.spacing(1.5, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 auto",
    },

    "& a": {
      color: theme.colors.lightText,
      textDecoration: "underline",
      textDecorationColor: theme.colors.lightBorder,
    },
  },
}))

function Quotation({ source, text, courseTitle, courseUrl }) {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  // this is for backward compatibility with formatted quotations. It can be removed
  // when all questions are in plain text.
  let formattedText
  try {
    formattedText = createMarkup(
      convertEditorStateToHtml(convertFromRaw(JSON.parse(text)))
    )
  } catch (error) {
    formattedText = createMarkup(`<div>${text}</div>`)
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.mainText}
        dangerouslySetInnerHTML={formattedText}
      />
      <Grid
        container
        className={classes.footer}
        direction={mediumOrHigherScreen ? "row" : "column"}
      >
        <Grid item className={classes.source}>
          {source.toUpperCase()}
        </Grid>
        <Grid item className={classes.libraryLink}>
          <Link to={urls.library()}>Библиотека</Link>
        </Grid>
      </Grid>
      {courseTitle ? (
        <Grid container className={classes.course}>
          <Link to={courseUrl}>
            Из материалов к курсу &laquo;{courseTitle}&raquo;
          </Link>
        </Grid>
      ) : null}
    </div>
  )
}

Quotation.propTypes = {
  source: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  courseTitle: PropTypes.string,
  courseUrl: PropTypes.string,
}

export default Quotation
