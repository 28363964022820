import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { Error, Loader, SmallHeader } from "core/components"
import { Link } from "react-router-dom"
import urls from "core/urls"

import { STATE_COMPLETED, STATE_FAILURE } from "payments"
import { useCurrentPayment } from "payments"
import { useLoginInfo } from "users"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    position: "relative",
  },

  centered: {
    position: "absolute",
    top: "50%",
    margin: 0,
    width: "100%",
    textAlign: "center",
    "-ms-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
  },

  status: {
    display: "inline-block",
    marginBottom: 70,
  },
}))

export default function ScreensWaitingForPayment() {
  const classes = useStyles()
  const { isLoggedIn } = useLoginInfo()
  if (!isLoggedIn)
    return defaultWindow(
      <span>
        Требуется <Link to={urls.login()}>авторизация</Link>
      </span>
    )

  const { details, error, isCurrentPaymentLoaded } = useCurrentPayment()

  if (!isCurrentPaymentLoaded) return defaultWindow(<Loader />)

  if (error) return defaultWindow(<Error errorText={error.message} />)

  const finished =
    details &&
    (details.state === STATE_COMPLETED || details.state === STATE_FAILURE)

  return defaultWindow(
    <span className={classes.status}>
      <Typography variant="h2" component="h2">
        {!finished
          ? "Ожидается подтверждение оплаты"
          : details.state === STATE_COMPLETED
          ? "Успешно оплачено"
          : "Платеж отменен"}
      </Typography>
      {!finished ? (
        <Loader />
      ) : details.state === STATE_COMPLETED ? (
        <Link to={urls.profile()}>Перейти в аккаунт</Link>
      ) : null}
    </span>
  )
}

function defaultWindow(appendComponent) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.root}>
        <SmallHeader />
        <div className={classes.centered}>{appendComponent}</div>
      </div>
    </React.Fragment>
  )
}
