import React from "react"
import PropTypes from "prop-types"
// import { useDispatch, useSelector } from 'react-redux'
// import { Button } from '@material-ui/core'

// import { unsubscribeFromMailouts } from '../actions'
// import { courseMailoutsUnsubscribeStateSelector } from '../selectors'

function MailoutsUnsubscribeButton({ course }) {
  // TODO: enable unsubscribe button when api will be ready
  // const dispatch = useDispatch()

  // let loading = false
  // const state = useSelector(state => courseMailoutsUnsubscribeStateSelector(state, course))
  // if (state) {
  //   loading = state.loading
  // }

  // const subscribe = useCallback(event => {
  //   event.preventDefault()
  //   dispatch(unsubscribeFromMailouts(course))
  // })

  return (
    // <Button
    //   variant='contained'
    //   color='primary'
    //   onClick={subscribe}
    //   disabled={loading}
    // >
    //   Отписаться от новостей
    // </Button>
    <div>Вы получаете новости по этому курсу</div>
  )
}

MailoutsUnsubscribeButton.propTypes = {
  course: PropTypes.number.isRequired,
}

export default MailoutsUnsubscribeButton
