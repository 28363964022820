import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class DisciplesAPI {
  fetchDetails(id) {
    return handleErrors(
      fetch(endpoints.discipleDetails(id), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }

  patch(id, data) {
    return handleErrors(
      fetch(endpoints.discipleDetails(id), {
        method: "PATCH",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }
}

export default new DisciplesAPI()
