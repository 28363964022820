import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class ParticipantsAPI {
  fetchList() {
    return handleErrors(
      fetch(endpoints.participantsList(), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }

  create(data) {
    return handleErrors(
      fetch(endpoints.participantsList(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }

  patch(id, data) {
    return handleErrors(
      fetch(endpoints.participantDetails(id), {
        method: "PATCH",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }

  delete(id) {
    return handleErrors(
      fetch(endpoints.participantDetails(id), {
        method: "delete",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new ParticipantsAPI()
