import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchDisctionaries } from "./actions"
import {
  countriesSelector,
  dictionariesStateSelector,
  timezonesSelector,
} from "./selectors"

export function useDictionaries() {
  const dispatch = useDispatch()
  const fetch = useCallback(() => {
    dispatch(fetchDisctionaries())
  })

  const { didInvalidate } = useSelector(dictionariesStateSelector)
  useEffect(fetch, [didInvalidate])

  const dictionariesState = useSelector(dictionariesStateSelector)
  return {
    dictionariesState,
    isDictionariesLoaded:
      !!dictionariesState.lastUpdated && !dictionariesState.loading,
    countries: useSelector(countriesSelector),
    timezones: useSelector(timezonesSelector),
  }
}
