import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchQuotationsList } from "./actions"
import {
  quotationsListSelector,
  quotationsListStateSelector,
} from "./selectors"

export function useQuotationsList() {
  const dispatch = useDispatch()
  const fetchList = useCallback(() => {
    dispatch(fetchQuotationsList())
  })
  useEffect(fetchList, [])

  const quotationsListState = useSelector(quotationsListStateSelector)
  const quotationsList = useSelector(quotationsListSelector)
  return {
    quotationsList,
    quotationsListState,
    isQuotationsListLoaded:
      !!quotationsListState.lastUpdated && !quotationsListState.loading,
  }
}
