import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    margin: "-18px -67px 0 auto",
    visibility: "hidden",
    height: 0,
    overflow: "visible",
    zIndex: 20,
    width: 200,
  },
  triangle: {
    width: 26,
    margin: "0 auto",
    content: "",
    borderLeft: "13px solid transparent",
    borderRight: "13px solid transparent",
    borderBottom: "15px solid #e9e8e1",
  },
  content: {
    padding: "5px 20px 5px 15px",
    width: "100%",
    background: "#e9e8e1",
    fontSize: 16,
    borderRadius: "13px 18px 18px 13px",
    boxShadow: "2px 2px 2px 0 rgba(0, 0, 0, 0.2)",
    color: theme.colors.darkText,
    textAlign: "center",
  },
}))

export default function CanBeSubscribedPopup({ start }) {
  const classes = useStyles()
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.triangle} />
      <div className={classes.content}>
        Запись открыта. Начало курса &mdash; {moment(start).format("DD.MM")}.
      </div>
    </div>
  )
}
CanBeSubscribedPopup.propTypes = {
  start: PropTypes.string.isRequired,
}
