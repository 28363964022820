const CREATION_ACTION_TYPES = {
  CREATE: "CREATE_PAYMENT",
  CREATE_REQUEST: "CREATE_PAYMENT_REQUEST",
  CREATE_SUCCESS: "CREATE_PAYMENT_SUCCESS",
  CREATE_FAILURE: "CREATE_PAYMENT_FAILURE",
  RESET: "RESET_PAYMENT_CREATION",
  INVALIDATE: "INVALIDATE_PAYMENT_CREATION",
}

export default CREATION_ACTION_TYPES
