import { call, put, select, take, fork } from "redux-saga/effects"

import LessonsAPI from "../api"
import LIST_ACTION_TYPES from "./actiontypes"
import { courseLessonsListStateSelector } from "./selectors"

const {
  FETCH_LIST,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
} = LIST_ACTION_TYPES

export function fetchLessonsList(courseId) {
  return { type: FETCH_LIST, courseId }
}

export function* fetchLessons(courseId) {
  yield put({ type: FETCH_LIST_REQUEST, courseId })
  try {
    const items = yield call(LessonsAPI.fetchList, courseId)
    yield put({
      type: FETCH_LIST_SUCCESS,
      items,
      courseId,
      receivedAt: Date.now(),
    })
  } catch (error) {
    yield put({
      type: FETCH_LIST_FAILURE,
      courseId,
      error,
      receivedAt: Date.now(),
    })
  }
}

function shouldFetchLessons(lessonsList) {
  if (!lessonsList) {
    return true
  } else if (lessonsList.loading) {
    return false
  } else {
    return lessonsList.didInvalidate
  }
}

export function* fetchLessonsIfNeeded(courseId) {
  const lessonsList = yield select(courseLessonsListStateSelector, courseId)
  if (shouldFetchLessons(lessonsList)) {
    yield call(fetchLessons, courseId)
  }
}

export function* listFetchingFlow() {
  let action = yield take(FETCH_LIST)
  while (true) {
    yield fork(fetchLessonsIfNeeded, action.courseId)
    action = yield take([FETCH_LIST, FETCH_LIST_REQUEST])
    if (action.type === FETCH_LIST) {
      continue
    }
    yield take([FETCH_LIST_SUCCESS, FETCH_LIST_FAILURE])
  }
}

export default listFetchingFlow
