import DETAILS_ACTION_TYPES from "./actiontypes"
import { detailsReducerFactory } from "core/factories/reducers"

const {
  FETCH_DETAILS_REQUEST,
  FETCH_DETAILS_SUCCESS,
  FETCH_DETAILS_FAILURE,
  INVALIDATE_DETAILS,
} = DETAILS_ACTION_TYPES

const detailsReducer = detailsReducerFactory({
  detailsRequest: FETCH_DETAILS_REQUEST,
  detailsSuccess: FETCH_DETAILS_SUCCESS,
  detailsFailure: FETCH_DETAILS_FAILURE,
  invalidateDetails: INVALIDATE_DETAILS,
})

export default detailsReducer
