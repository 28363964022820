import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  outerCircle: {
    margin: "0 25px 0 auto",
    width: 16,
    height: 16,
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: "50%",
  },

  innerCircle: {
    margin: "3px 0 0 3px",
    width: 8,
    height: 8,
    backgroundColor: "#7ed321",
    borderRadius: 50,
  },
})

export default function CanBeSubscribedIcon({ onMouseOver, onMouseOut }) {
  const classes = useStyles()
  return (
    <div
      className={classes.outerCircle}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div className={classes.innerCircle} />
    </div>
  )
}

CanBeSubscribedIcon.propTypes = {
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
}
