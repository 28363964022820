import CURRENT_ACTION_TYPES from "./actiontypes"
import { currentReducerFactory } from "core/factories/reducers"

const {
  FETCH_CURRENT_REQUEST,
  FETCH_CURRENT_SUCCESS,
  FETCH_CURRENT_FAILURE,
  INVALIDATE_CURRENT,
} = CURRENT_ACTION_TYPES

const currentReducer = currentReducerFactory({
  currentRequest: FETCH_CURRENT_REQUEST,
  currentSuccess: FETCH_CURRENT_SUCCESS,
  currentFailure: FETCH_CURRENT_FAILURE,
  invalidateCurrent: INVALIDATE_CURRENT,
})

export default currentReducer
