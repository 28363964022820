import { flatten } from "lodash"

export function allFeedbackSelector(state) {
  const allPages = Object.keys(state.local.feedbacks.list.pages)
  return feedbacksForPagesSelector(state, allPages)
}

export function feedbacksForPagesSelector(state, pages) {
  const { feedbacks } = state.entities
  return flatten(
    pages.map((page) => {
      const listState = feedbacksPageStateSelector(state, page)
      if (listState) {
        const feedbackIdsOnPage = listState.items
        return feedbackIdsOnPage.map((id) => feedbacks[id])
      }
      return null
    })
  ).filter((f) => f)
}

export function feedbacksPageStateSelector(state, page) {
  return state.local.feedbacks.list.pages[page]
}

export function feedbacksListStateSelector(state) {
  return state.local.feedbacks.list
}

export function currentFeedbacksPageSelector(state) {
  return state.local.feedbacks.list.currentPage
}
