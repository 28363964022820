import UPDATING_ACTION_TYPES from "./actiontypes"
import DisciplesAPI from "../api"
import { itemUpdatingFlowFactory } from "core/factories/actions"

const {
  UPDATE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  RESET,
} = UPDATING_ACTION_TYPES

export function updateDisciple(id, data) {
  return {
    type: UPDATE,
    id,
    data,
  }
}

export function resetDiscipleUpdating(id) {
  return {
    type: RESET,
    id,
  }
}

export function* updatingFlow() {
  yield* itemUpdatingFlowFactory(
    {
      fetch: UPDATE,
      request: UPDATE_REQUEST,
      success: UPDATE_SUCCESS,
      failure: UPDATE_FAILURE,
      reset: RESET,
    },
    () => DisciplesAPI.patch
  )()
}
