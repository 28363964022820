import React from "react"
import { withRouter } from "react-router-dom"

import urls from "core/urls"

class ScrollToTop extends React.Component {
  ignoredUrlsChanges() {
    return [
      [urls.profile(), urls.discipleCourses()],
      [urls.discipleCourses(), urls.profile()],
      [urls.profile(), urls.profileEditor()],
      [urls.profileEditor(), urls.profile()],
    ]
  }

  shouldIgnoreUrlsChange(prevProps) {
    const prevPathName = prevProps.location.pathname
    const pathName = this.props.location.pathname
    for (let ignoredChange of this.ignoredUrlsChanges()) {
      if (
        ignoredChange.includes(prevPathName) &&
        ignoredChange.includes(pathName)
      ) {
        return true
      }
    }
    return false
  }

  componentDidUpdate(prevProps) {
    if (
      !this.shouldIgnoreUrlsChange(prevProps) &&
      this.props.location !== prevProps.location
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
