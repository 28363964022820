import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@material-ui/core"

import { subscribeForMailouts } from "../actions"
import { courseMailoutsSubscribeStateSelector } from "../selectors"

function MailoutsSubscribeButton({ course }) {
  const dispatch = useDispatch()

  let loading = false
  const state = useSelector((state) =>
    courseMailoutsSubscribeStateSelector(state, course)
  )
  if (state) {
    loading = state.loading
  }

  const subscribe = useCallback((event) => {
    event.preventDefault()
    dispatch(subscribeForMailouts(course))
  })

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={subscribe}
      disabled={loading}
    >
      Подписаться на новости
    </Button>
  )
}

MailoutsSubscribeButton.propTypes = {
  course: PropTypes.number.isRequired,
}

export default MailoutsSubscribeButton
