import React from "react"
import PropTypes from "prop-types"
import { convertFromRaw } from "draft-js"
import { makeStyles } from "@material-ui/styles"

import { LessonType } from "../../types"
import { convertEditorStateToHtml } from "core/utils/text"
import { PARTICIPANT_STATES } from "participants"

import { Checkbox } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  lesson: {
    marginTop: 20,
    border: "none",
    padding: 0,
  },
  title: {
    fontSize: 21,
    lineHeight: 1,
    color: theme.colors.lightText,

    "@media (min-width: 960px)": {
      fontSize: 27,
    },
  },
  selectable: {
    position: "relative",
    bottom: 2,
  },
  content: {
    marginTop: 20,
    fontSize: 18,
    lineHeight: 1.43,

    "@media (min-width: 960px)": {
      fontSize: 21,
    },
  },
  error: {
    color: "red",
    borderBottom: "1px solid red",
  },
  lessonFull: {
    "&:after": {
      color: "#bdbdbd",
      content: '"[мест нет]"',
      fontSize: "0.9em",
    },
  },
}))

function createMarkup(html) {
  return { __html: html }
}

function makeTitleStyle(lessonError, lessonIsFull, canSelect) {
  const classes = useStyles()
  const style = [classes.title]

  if (lessonError) style.push(classes.error)
  if (lessonIsFull && canSelect) style.push(classes.lessonFull)

  return style.join(" ")
}

export default function LessonsListItem(props) {
  const classes = useStyles()
  const {
    lesson,
    disabled,
    selected,
    selectable,
    selectLessonFromEvent,
    error,
  } = props

  const getLessonContent = (rawContent, className) => {
    try {
      const convertedContent = convertFromRaw(JSON.parse(lesson.content))
      const htmlMarkup = createMarkup(
        convertEditorStateToHtml(convertedContent)
      )
      return (
        <div
          className={`lesson-content ${classes.content}`}
          dangerouslySetInnerHTML={htmlMarkup}
        />
      )
    } catch (error) {
      return (
        <div className={`lesson-content ${classes.content}`}>{rawContent}</div>
      )
    }
  }

  return (
    <div className={classes.lesson}>
      <div className={makeTitleStyle(error, !lesson.hasFreePlaces, selectable)}>
        {lesson.number}. {lesson.title}
        {selectable && (
          <Checkbox
            className={classes.selectable}
            checked={
              Boolean(
                lesson.participationState === PARTICIPANT_STATES.approved
              ) || selected
            }
            name={String(lesson.id)}
            onChange={selectLessonFromEvent(lesson.id)}
            disabled={
              Boolean(
                lesson.participationState === PARTICIPANT_STATES.approved
              ) ||
              !lesson.hasFreePlaces ||
              disabled
            }
          />
        )}
      </div>
      {getLessonContent(lesson.content)}
    </div>
  )
}

LessonsListItem.propTypes = {
  lesson: PropTypes.shape(LessonType).isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  selectLessonFromEvent: PropTypes.func,
  error: PropTypes.bool,
}

LessonsListItem.defaultProps = {
  disabled: false,
  selected: false,
  selectable: false,
  selectLessonFromEvent: () => null,
  error: false,
}
