import { combineReducers } from "redux"

import { normalize } from "normalizr"

import detailsReducer from "./details/reducer"
import DETAILS_ACTION_TYPES from "./details/actiontypes"
import listReducer from "./list/reducer"
import LIST_ACTION_TYPES from "./list/actiontypes"
import courseSchema from "./schema"
import { entitiesReducerFactory } from "core/factories/reducers"
import mailoutsReducer from "./mailouts/reducer"
import MAILOUTS_ACTION_TYPES from "./mailouts/actiontypes"

import { finishParticipantsCreation } from "features/participants/creation/slice"

const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES
const { FETCH_DETAILS_SUCCESS } = DETAILS_ACTION_TYPES
const {
  SUBSCRIBE_FOR_MAILOUTS_SUCCESS,
  UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS,
} = MAILOUTS_ACTION_TYPES

const baseReducer = entitiesReducerFactory(
  {
    detailsSuccess: FETCH_DETAILS_SUCCESS,
    listSuccess: FETCH_LIST_SUCCESS,
  },
  courseSchema,
  (normalizedData) => normalizedData.entities.courses
)

export const courseEntitiesReducer = (state = {}, action) => {
  let id = null
  let normalizedData = null

  switch (action.type) {
    case FETCH_LIST_SUCCESS:
    case FETCH_DETAILS_SUCCESS:
      return baseReducer(state, action)

    case finishParticipantsCreation.type:
      normalizedData = normalize(action.details, courseSchema)
      id = action.details.id
      return {
        ...state,
        [id]: normalizedData.entities.courses[id],
      }

    case SUBSCRIBE_FOR_MAILOUTS_SUCCESS:
      id = action.id
      return {
        ...state,
        [id]: { ...state[id], subscribedForMailouts: true },
      }

    case UNSUBSCRIBE_FROM_MAILOUTS_SUCCESS:
      id = action.id
      return {
        ...state,
        [id]: { ...state[id], subscribedForMailouts: false },
      }

    default:
      return state
  }
}

export const coursesStateReducer = combineReducers({
  byId: detailsReducer,
  list: listReducer,
  mailouts: mailoutsReducer,
})
