const initState = {
  loading: false,
  didInvalidate: false,
  error: null,
}

function itemState(actionTypes, state = initState, action) {
  switch (action.type) {
    case actionTypes["deleteRequest"]:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case actionTypes["deleteSuccess"]:
      return {
        ...state,
        loading: false,
        didInvalidate: true,
        error: null,
      }

    case actionTypes["deleteFailure"]:
      console.log(action.error)
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: action.error,
      }

    default:
      return state
  }
}

function deletionReducerFactory(actionTypes) {
  const usedActionTypes = Object.values(actionTypes)
  const supportedActions = ["deleteRequest", "deleteSuccess", "deleteFailure"]
  for (let key of supportedActions) {
    actionTypes[key] = actionTypes[key] || ""
  }

  return (state = {}, action) => {
    if (usedActionTypes.includes(action.type)) {
      return {
        ...state,
        [action.id]: itemState(actionTypes, state[action.id], action),
      }
    } else {
      return state
    }
  }
}

export default deletionReducerFactory
