import React from "react"
import {
  Avatar,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { BigHeaderLayout, Footer, Separator } from "core/components"

import ADesnitskyPhoto from "assets/images/adesnitsky.jpg"
import AShteinPhoto from "assets/images/ashtein.jpg"

const useStyles = makeStyles((theme) => ({
  headerSeparator: {
    maxWidth: "90%",
    marginTop: 30,
  },

  content: {
    margin: "22px 20px 0 20px",
    maxWidth: 900,

    [theme.breakpoints.up("md")]: {
      margin: "22px auto 0 auto",
    },
  },

  authorAvatar: {
    width: 80,
    height: 80,

    [theme.breakpoints.up("md")]: {
      width: 100,
      height: 100,
    },
  },

  aboutAuthor: {
    marginLeft: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3.3),
    },
  },

  quotation: {
    margin: theme.spacing(3, 0, 0, 0),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0, 0, 13.3),
    },
  },

  title: theme.presets.title,

  work: {
    fontStyle: "italic",
    color: theme.colors.lightText,
    marginTop: 10,
  },

  space: {
    marginTop: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
    },
  },
}))

function ScreensAbout() {
  const classes = useStyles()
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <BigHeaderLayout isDataLoaded isErrorHappened={false}>
      <Separator className={classes.headerSeparator} />
      <div className={classes.content}>
        <Typography variant="h1" align="center" className={classes.title}>
          О проекте
        </Typography>
        <Typography variant="body1" paragraph>
          Ваганты – это на латинском языке „бродяги“. Так в средневековой Европе
          называли странствующих школяров: они переходили от одного учителя к
          другому, из одного университета в другой, самостоятельно выбирая, чему
          учитсья. Сегодня никому не надо бродить по дорогам — есть интернет. И
          есть потребность в получении новых знаний, в общении с близкими по
          духу людьми не только в стенах учебных заведений, но и по собственному
          выбору. И потому мы создали проект „Ваганты“.
        </Typography>
        <Typography variant="body1" paragraph>
          Мы закончили филологический факультет МГУ (классическое отделение), а
          теперь занимаемся с детьми и взрослыми тем, что не входит в обычные
          школьные и институтские программы. Учим читать тексты, в том числе
          хорошо знакомые, открывая в них то, чего не видно сразу. Учим
          общаться, слышать друг друга и отстаивать свою позицию в споре. В мире
          фастфуда и телерекламы мы учим взрослых и подростков (от 11 до 17 лет)
          читать медленно, думать глубоко и жить интересно. А еще мы супруги, у
          нас трое выросших детей и трое внуков. Но сейчас мы не об этом.
        </Typography>
        <Typography variant="body1">
          Официально мы здесь выступаем как самозанятые репетиторы, но мы не
          готовим ваших детей к ЕГЭ. Мы готовим их к жизни в мире, где всё
          больше значат человеческое слово и стоящая за ним мысль.
        </Typography>

        <Grid
          id="ashtein"
          container
          className={classes.space}
          alignItems={mediumOrHigherScreen ? "center" : "flex-start"}
          wrap="nowrap"
        >
          <Grid item>
            <Avatar
              className={classes.authorAvatar}
              src={AShteinPhoto}
              alt="A.Shtein"
            />
          </Grid>
          <Grid item className={classes.aboutAuthor}>
            <Typography variant="h2">Ася Штейн</Typography>
            <Typography className={classes.work} variant="body2">
              Преподаватель литературы и мифологии в Лицее им. Вернадского
              (Донская гимназия)
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" className={classes.quotation}>
          «Я учу детей читать. В первую очередь, это значит не бояться текстов –
          длинных, сложных, не всегда понятных. Во-вторых – не бояться о них
          говорить. Не то, что написано в учебнике, а то, что думаешь по поводу
          текста на самом деле, даже если окружающие думают иначе. В-третьих,
          разбираться, как тексты устроены и какие в них скрыты тайны и загадки.
          Это могут быть древние сказания или современные произведения,
          классическая поэзия или рассказы для детей – всё зависит от того,
          сколько лет вашему ребенку и что ему интересно в данный момент».
        </Typography>

        <Grid
          id="adesnitsky"
          container
          className={classes.space}
          alignItems={mediumOrHigherScreen ? "center" : "flex-start"}
          wrap="nowrap"
        >
          <Grid item>
            <Avatar
              className={classes.authorAvatar}
              src={ADesnitskyPhoto}
              alt="A.Desnitsky"
            />
          </Grid>
          <Grid item className={classes.aboutAuthor}>
            <Typography variant="h2">Андрей Десницкий</Typography>
            <Typography className={classes.work} variant="body2">
              Доктор филологических наук, профессор РАН
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" className={classes.quotation}>
          «Моя основная специальность – Библия и ее переводы на современные
          языки, но здесь большинство моих занятий посвящены другим темам. Как
          устроены разные языки, как их особенности отражают свойства
          человеческого мышления? Как люди общаются друг с другом, почему
          нередко они понимают друг друга превратно и как это можно исправить?
          Как пишутся художественные тексты, что нужно, чтобы их понимать и
          создавать? Проще говоря – филология для чайников… а впрочем, для всех,
          ведь все мы пользуемся родным языком практически всё то время, пока не
          спим».
        </Typography>
        <Typography variant="body1" className={classes.space} paragraph>
          Занятия делятся на вебинары (личное участие в реальном времени) и
          лекции (их можно просматривать в любое удобное время).
          Продолжительность каждого курса и его целевая аудитория указаны в его
          описании. Вебинары сейчас проходят на площадке&nbsp;
          <Link href="https://zoom.us/" underline="none">
            Zoom
          </Link>
          , для участия вам потребуются устойчивый интернет, микрофон, наушники
          и вебкамера. Перед началом нового курса обычно проводится тестовое
          занятие для настройки оборудования.
        </Typography>
        <Typography variant="body1">
          За занятия мы предлагаем вносить плату, но если у вас есть основания
          для скидки (многодетная семья, инвалидность, трудная жизненная
          ситуация) –{" "}
          <Link href="mailto:ailoyros@gmail.com" underline="none">
            пишите
          </Link>
          , договоримся о скидках, а в особых случаях и о бесплатном участии.
        </Typography>
      </div>
      <Footer />
    </BigHeaderLayout>
  )
}

export default ScreensAbout
