import React from "react"
import {
  Avatar,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { RouterHashLink, RouterLink } from "core/components"
import urls from "core/urls"

import ADesnitskyPhoto from "assets/images/adesnitsky.jpg"
import AShteinPhoto from "assets/images/ashtein.jpg"

const useMobileStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 2),
  },
  footerLinks: {
    marginTop: theme.spacing(3),

    "& a:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  tooltip: {
    position: "relative",
    color: theme.colors.lightText,
    backgroundColor: theme.colors.lightBackground,
    padding: 25,
    fontSize: 18,
    fontStyle: "italic",
    lineHeight: 1.33,
  },
  triangle1: {
    position: "absolute",
    bottom: -20,
    left: 10,
    width: 0,
    height: 0,
    borderTop: `20px solid ${theme.colors.lightBorder}`,
    borderLeft: "20px solid transparent",
  },
  triangle2: {
    position: "absolute",
    bottom: -20,
    left: 62,
    width: 0,
    height: 0,
    borderTop: `20px solid ${theme.colors.lightBorder}`,
    borderLeft: "20px solid transparent",
  },
  authorsBlock: {
    marginTop: theme.spacing(4),
  },
  work: {
    fontSize: 18,
  },
}))

const useStyles = makeStyles({
  root: {
    marginTop: 150,
    padding: 50,
    fontSize: 21,
  },
  footerLinks: {
    "& a:not(:last-child)": {
      marginRight: 25,
    },
  },
  authors: {
    position: "relative",
    overflow: "hidden",

    "&:hover": {
      overflow: "visible",
    },

    "&:hover .tooltip-description": {
      transition: "all 0.3s ease-in-out",
      "-webkit-transition": "all 0.3s ease-in-out",
      "-moz-transition": "all 0.3s ease-in-out",
      "-o-transition": "all 0.3s ease-in-out",
      opacity: 1,
    },
  },
  authorAvatar: {
    margin: "0 10px",
    width: 45,
    height: 45,
  },
  authorBlock: {
    position: "relative",
  },
  authorTextTriangle: {
    position: "absolute",
    top: -30,
    left: 18,
    width: 0,
    height: 0,
    borderTop: "15px solid rgba(201, 193, 159, 0.5)",
    borderLeft: "15px solid transparent",
    opacity: 0,
  },
  authorsText: {
    position: "absolute",
    width: 550,
    height: 127,
    visibility: "visible",
    padding: 25,
    fontSize: 18,
    fontStyle: "italic",
    color: "#a9a389",
    lineHeight: 1.33,
    backgroundColor: "rgba(201, 193, 159, 0.5)",
    top: -157,
    left: -130,
    opacity: 0,
    cursor: "default",
  },
})

function Footer() {
  const theme = useTheme()
  const mediumOrHigherScreen = useMediaQuery(theme.breakpoints.up("md"))
  const mobileClasses = useMobileStyles()
  const classes = useStyles()

  if (mediumOrHigherScreen) {
    return (
      <Grid
        container
        justify="space-between"
        className={classes.root}
        alignItems="center"
      >
        <Grid item className={classes.footerLinks}>
          <Link component={RouterLink} to={urls.about()}>
            О проекте
          </Link>
          <Link component={RouterLink} to={urls.feedbacksList()}>
            Отзывы
          </Link>
          <Link component={RouterLink} to={urls.policy()}>
            Политика конфиденциальности
          </Link>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" className={classes.authors}>
            <Grid item className={classes.authorBlock}>
              <Avatar
                src={AShteinPhoto}
                alt="А.Штейн"
                className={classes.authorAvatar}
              />
              <div
                className={`tooltip-description ${classes.authorTextTriangle}`}
              />
              <div className={`tooltip-description ${classes.authorsText}`}>
                Мы &mdash; Ася и Андрей &mdash; любим и умеем учить взрослых и
                детей. Здесь вы можете познакомиться с курсами, которые мы ведём
                он-лайн, и предварительно записаться на них.
              </div>
            </Grid>
            <Grid item>
              <Link component={RouterHashLink} to={`${urls.about()}#ashtein`}>
                Asia
              </Link>{" "}
              Lapidaria ac
            </Grid>
            <Grid item className={classes.authorBlock}>
              <Avatar
                src={ADesnitskyPhoto}
                alt="А.Десницкий"
                className={classes.authorAvatar}
              />
              <div
                className={`tooltip-description ${classes.authorTextTriangle}`}
              />
            </Grid>
            <Grid item>
              <Link
                component={RouterHashLink}
                to={`${urls.about()}#adesnitsky`}
              >
                Andreas
              </Link>{" "}
              Dextimus scire volentibus
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <div className={mobileClasses.root}>
        <div className={mobileClasses.tooltip}>
          <div>
            Мы &mdash; Ася и Андрей &mdash; любим и умеем учить взрослых и
            детей. Здесь вы можете познакомиться с курсами, которые мы ведём
            он-лайн, и предварительно записаться на них.
          </div>
          <div className={mobileClasses.triangle1} />
          <div className={mobileClasses.triangle2} />
        </div>
        <Grid
          container
          className={mobileClasses.authorsBlock}
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <Avatar
              src={AShteinPhoto}
              alt="А.Штейн"
              className={mobileClasses.authorAvatar}
            />
          </Grid>
          <Grid item>
            <Avatar
              src={ADesnitskyPhoto}
              alt="А.Десницкий"
              className={mobileClasses.authorAvatar}
            />
          </Grid>
          <Grid item>
            <Typography className={mobileClasses.work}>
              <Link component={RouterHashLink} to={`${urls.about()}#ashtein`}>
                Asia
              </Link>{" "}
              Lapidaria ac
            </Typography>
            <Typography className={mobileClasses.work}>
              <Link
                component={RouterHashLink}
                to={`${urls.about()}#adesnitsky`}
              >
                Andreas
              </Link>{" "}
              Dextimus scire volentibus
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={mobileClasses.footerLinks}>
          <Grid item>
            <Link component={RouterLink} to={urls.about()}>
              О проекте
            </Link>
            <Link component={RouterLink} to={urls.feedbacksList()}>
              Отзывы
            </Link>
            <Link component={RouterLink} to={urls.policy()}>
              Политика конфиденциальности
            </Link>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Footer
