import fetch from "cross-fetch"

import { convertToQueryString, getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class LibraryAPI {
  list(params) {
    const queryString = params ? `?${convertToQueryString(params)}` : ""
    return handleErrors(
      fetch(endpoints.libraryItemsList() + queryString, {
        method: "get",
        headers: getDefaultHeaders(),
        mode: "cors",
      })
    )
  }

  details(id) {
    return handleErrors(
      fetch(endpoints.libraryItemDetails(id), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }
}

export default new LibraryAPI()
