import { removeJwt } from "users/login/actions"

export const authInterceptor = ({ dispatch }) => (next) => (action) => {
  if (action.payload && action.payload.status === 401) {
    dispatch(removeJwt())
    window.location.href = "/user/login"
  } else {
    next(action)
  }
}
