import {
  startParticipantsCreation,
  requestParticipantsCreation,
  finishParticipantsCreation,
  failParticipantsCreation,
  resetParticipantsCreation,
} from "./slice"
import ParticipantsAPI from "../api"
import { itemCreationFlowFactory } from "core/factories/actions"

export default function* creationFlow() {
  yield* itemCreationFlowFactory(
    {
      fetch: startParticipantsCreation().type,
      request: requestParticipantsCreation().type,
      success: finishParticipantsCreation().type,
      failure: failParticipantsCreation().type,
      reset: resetParticipantsCreation().type,
    },
    () => ParticipantsAPI.createFromCourse
  )()
}
