import { call, put, select, take, fork } from "redux-saga/effects"

export function* fetchItems(actionsTypes, apiFunc, { page }) {
  yield put({ type: actionsTypes["request"], page })
  try {
    const response = yield call(apiFunc)
    yield put({
      type: actionsTypes["success"],
      nextPage: response.next,
      count: response.count,
      items: response.results,
      receivedAt: Date.now(),
      page,
    })
  } catch (error) {
    yield put({
      type: actionsTypes["failure"],
      error: error.message,
      receivedAt: Date.now(),
      page,
      payload: {
        error: error.message,
        status: error.statusCode,
        receivedAt: Date.now(),
        page,
      },
    })
  }
}

function shouldFetchItems(pageState) {
  if (!pageState) {
    return true
  } else if (pageState.loading) {
    return false
  } else if (!pageState.lastUpdated) {
    return true
  } else {
    return pageState.didInvalidate
  }
}

export function* fetchItemsIfNeeded(
  actionTypes,
  apiFunc,
  selectorFunc,
  action
) {
  const listState = yield select(selectorFunc)
  if (shouldFetchItems(listState)) {
    yield call(fetchItems, actionTypes, apiFunc, action)
  }
}

/**
 *
 * @param {object} actionTypes - object containing fetch, request, failure and success action types
 * @param {function} apiFuncFromAction - function that takes an action as parameter
 *                             and returns API function for making request
 * @param {function} selectorFuncFromAction - function that takes an action as parameter
 *                                  and returns selector function for getting list state
 */
export function fetchingPaginatedItemsFlowFactory(
  actionTypes,
  apiFuncFromAction,
  selectorFuncFromAction
) {
  return function* () {
    let action = yield take(actionTypes["fetch"])
    while (true) {
      yield fork(
        fetchItemsIfNeeded,
        actionTypes,
        apiFuncFromAction(action),
        selectorFuncFromAction(action),
        action
      )
      action = yield take([actionTypes["fetch"], actionTypes["request"]])
      if (action.type === actionTypes["fetch"]) {
        continue
      }
      yield take([actionTypes["success"], actionTypes["failure"]])
      action = yield take(actionTypes["fetch"])
    }
  }
}
