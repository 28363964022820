import LIST_ACTION_TYPES from "./actiontypes"
import quotationSchema from "../schema"
import { listReducerFactory } from "core/factories/reducers"

const {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  INVALIDATE_LIST,
} = LIST_ACTION_TYPES

const listReducer = listReducerFactory(
  {
    listRequest: FETCH_LIST_REQUEST,
    listSuccess: FETCH_LIST_SUCCESS,
    listFailure: FETCH_LIST_FAILURE,
    invalidateList: INVALIDATE_LIST,
  },
  quotationSchema
)

export default listReducer
