import { combineReducers } from "redux"

import CREATION_ACTION_TYPES from "./creation/actiontypes"
import DELETION_ACTION_TYPES from "./deletion/actiontypes"
import creationReducer from "./creation/reducer"
import deletionReducer from "./deletion/reducer"
import LIST_ACTION_TYPES from "./list/actiontypes"
import listReducer from "./list/reducer"
import UPDATING_ACTION_TYPES from "./updating/actiontypes"
import updatingReducer from "./updating/reducer"
import { entitiesReducerFactory } from "core/factories/reducers"
import { participantSchema } from "./schema"

const { CREATE_SUCCESS } = CREATION_ACTION_TYPES
const { DELETE_SUCCESS } = DELETION_ACTION_TYPES
const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES
const { UPDATE_SUCCESS } = UPDATING_ACTION_TYPES

export const participantsEntitiesReducer = entitiesReducerFactory(
  {
    createSuccess: CREATE_SUCCESS,
    deleteSuccess: DELETE_SUCCESS,
    listSuccess: FETCH_LIST_SUCCESS,
    updateSuccess: UPDATE_SUCCESS,
  },
  participantSchema,
  (normalizedData) => normalizedData.entities.participants
)

export const participantsStateReducer = combineReducers({
  creation: creationReducer,
  deletion: deletionReducer,
  list: listReducer,
  updating: updatingReducer,
})
