import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import LessonsListItem from "./Item"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up("md")]: {
      marginTop: 50,
      padding: 0,
    },
  },
  title: {
    fontSize: 27,
    lineHeight: 1,
    paddingBottom: 7,

    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  block: {
    paddingLeft: 20,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 15,
    marginTop: 15,
    transition: "all 0.1s linear",
    transitionProperty: "padding-left border-width border-color",
    borderRadius: 21,
  },
  enabledBlock: {
    border: "1px solid #e6e6e6",
    borderTop: "3px solid #d2ccb6",
  },
  disabledBlock: {
    border: "1px solid #e6e6e6",
    borderTop: "3px solid #e2a2a2",
  },
  selectedBlock: {
    border: "3px solid #6cb96e",
    paddingLeft: 18,
  },
  participatedBlock: {
    border: "1px solid #aee0b6",
    borderTop: "3px solid #5fa06a",
  },
}))

function SelectableLessonsBlocks(props) {
  const classes = useStyles()
  const {
    blocksBuilder,
    selectedLessons,
    availableForSelection,
    selectLessonFromEvent,
    error,
  } = props

  blocksBuilder.setupSelectionData(selectLessonFromEvent, selectedLessons)

  const blocks = blocksBuilder.blocks.map((block) => {
    return (
      <div
        key={block.lessons[0].id}
        className={`${classes.block} ${
          block.participated
            ? classes.participatedBlock
            : !block.available
            ? classes.disabledBlock
            : block.selected
            ? classes.selectedBlock
            : classes.enabledBlock
        }`}
      >
        {block.lessons.map((lesson) => {
          return (
            <LessonsListItem
              key={lesson.id}
              disabled={!block.available}
              lesson={lesson}
              selected={selectedLessons[lesson.id] || false}
              selectable={availableForSelection}
              selectLessonFromEvent={block.selectEvent}
              error={error ? error.lessons.includes(lesson.id) : false}
            />
          )
        })}
      </div>
    )
  })

  if (!blocks.length) {
    return null
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>Содержание</div>
      <div>{blocks}</div>
    </div>
  )
}

SelectableLessonsBlocks.propTypes = {
  blocksBuilder: PropTypes.object.isRequired,
  selectedLessons: PropTypes.object.isRequired,
  availableForSelection: PropTypes.bool.isRequired,
  selectLessonFromEvent: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

SelectableLessonsBlocks.defaultProps = {
  error: null,
}

export default SelectableLessonsBlocks
