import CREATION_ACTION_TYPES from "./actiontypes"
import { creationReducerFactory } from "core/factories/reducers"

const {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET,
  INVALIDATE,
} = CREATION_ACTION_TYPES

const creationReducer = creationReducerFactory({
  createRequest: CREATE_REQUEST,
  createSuccess: CREATE_SUCCESS,
  createFailure: CREATE_FAILURE,
  reset: RESET,
  invalidate: INVALIDATE,
})

export default creationReducer
