import fetch from "cross-fetch"

import { getDefaultHeaders, handleErrors } from "core/api"
import endpoints from "core/endpoints"

class UsersAPI {
  fetchDetails(id) {
    return handleErrors(
      fetch(endpoints.userDetails(id || "me"), {
        method: "get",
        headers: getDefaultHeaders(),
      })
    )
  }

  signUp(data) {
    return handleErrors(
      fetch(endpoints.usersList(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }

  confirmSignUp(uidb64, token) {
    return handleErrors(
      fetch(endpoints.userSignUpConfirmation(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify({ uidb64, token }),
      })
    )
  }

  updatePassword(data) {
    return handleErrors(
      fetch(endpoints.userPasswordUpdate(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify(data),
      })
    )
  }

  resetPassword(email) {
    return handleErrors(
      fetch(endpoints.userPasswordReset(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify({ email }),
      })
    )
  }

  resetPasswordComplete(uidb64, token, password1, password2) {
    return handleErrors(
      fetch(endpoints.userPasswordResetComplete(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify({ uidb64, token, password1, password2 }),
      })
    )
  }

  login(username, password) {
    return handleErrors(
      fetch(endpoints.userSignIn(), {
        method: "post",
        headers: getDefaultHeaders(),
        body: JSON.stringify({
          username: username.toLowerCase().trim(),
          password,
        }),
      }),
      true
    )
  }
}

export default new UsersAPI()
