import React from "react"
import { Redirect } from "react-router-dom"
import { Button, Link, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { useLoginInfo, useLoginFormHandlers, useLoginState } from "../login"
import { FormError, RouterLink } from "core/components"
import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  form: {
    "& > div:not(:first-child)": {
      marginTop: theme.spacing(1),

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
      },
    },

    "& > button": {
      marginTop: theme.spacing(3),
    },
  },

  forgotPasswordLink: {
    marginTop: 30,
    color: theme.colors.lightText,
  },
}))

function UserLoginForm() {
  const classes = useStyles()

  const [
    { email, password },
    { setValueFromEvent, login },
  ] = useLoginFormHandlers()
  const { loading, error } = useLoginState()
  const { isLoggedIn } = useLoginInfo()

  if (isLoggedIn) {
    return <Redirect to={urls.home()} />
  }

  return (
    <form onSubmit={login} className={classes.form}>
      <TextField
        name="email"
        label="E-mail"
        value={email}
        onChange={setValueFromEvent("email")}
        fullWidth
        error={!!error.byFields.username}
        helperText={error.byFields.username}
        autoComplete="username"
      />
      <TextField
        name="password"
        label="Пароль"
        value={password}
        onChange={setValueFromEvent("password")}
        error={!!error.byFields.password}
        helperText={error.byFields.password}
        fullWidth
        type="password"
        autoComplete="current-password"
      />
      {
        // login api returns non field errors in 'detail' field
        error.byFields.detail ? (
          <FormError>{error.byFields.detail}</FormError>
        ) : null
      }
      <Button variant="contained" type="submit" fullWidth disabled={loading}>
        Войти
      </Button>
      <Link
        component={RouterLink}
        to={urls.resetPassword()}
        className={classes.forgotPasswordLink}
      >
        Забыли пароль?
      </Link>
    </form>
  )
}

export default UserLoginForm
