import { combineReducers } from "redux"

import listReducer from "./list/reducer"
import LIST_ACTION_TYPES from "./list/actiontypes"
import quotationSchema from "./schema"
import { entitiesReducerFactory } from "core/factories/reducers"

const { FETCH_LIST_SUCCESS } = LIST_ACTION_TYPES

export const quotationEntitiesReducer = entitiesReducerFactory(
  {
    listSuccess: FETCH_LIST_SUCCESS,
  },
  quotationSchema,
  (normalizedData) => normalizedData.entities.quotations
)

export const quotationsStateReducer = combineReducers({
  list: listReducer,
})
