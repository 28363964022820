import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"

import { AuthorType } from "authors"
import urls from "core/urls"
import { CourseType } from "courses"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 9,
    fontSize: 18,
    color: theme.colors.lightText,

    "@media (min-width: 960px)": {
      marginTop: 0,
    },
  },

  courseLink: {
    color: theme.colors.lightText,
    textDecoration: "underline",
    textDecorationColor: theme.colors.lightBackground,

    "&:hover": {
      color: theme.colors.lightText,
    },
  },
}))

export default function FeedbacksListItemCourseAuthor({ course, authors }) {
  const classes = useStyles()
  if (course) {
    const authorsNames = authors.map((author) => author.genitive).join(", ")
    return (
      <div className={classes.root}>
        {`О курсе ${authorsNames} `}
        <Link className={classes.courseLink} to={urls.courseDetails(course.id)}>
          &laquo;{course.title}&raquo;
        </Link>
      </div>
    )
  } else if (authors) {
    const authorsNames = authors.map((author) => author.genitive).join(", ")
    return <div className={classes.root}>{`О курсах ${authorsNames} `}</div>
  }
  return null
}

FeedbacksListItemCourseAuthor.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.shape(AuthorType)),
  course: PropTypes.shape(CourseType),
}
