import { useDispatch, useSelector } from "react-redux"

import { signUpUser as signUpAction, resetUserSignUp } from "./actions"
import { useFormHandlers } from "core/hooks"
import { userCreationStateSelector } from "users/signup/selectors"
import { ApiError } from "core/api"

export function useSignUpFormHandlers() {
  const yearOfBirthOptions = [...Array(11).keys()].map(
    (n) => new Date().getFullYear() - 8 - n
  )
  yearOfBirthOptions.push(0)

  const [values, formHandlers] = useFormHandlers({
    email: "",
    firstName: "",
    lastName: "",
    password1: "",
    password2: "",
    yearOfBirth: "",
    yearOfBirthOptions,
    agreement: false,
  })

  const {
    email,
    password1,
    password2,
    firstName,
    lastName,
    yearOfBirth,
    agreement,
  } = values

  const dispatch = useDispatch()

  const signUp = (event) => {
    event.preventDefault()
    const data = {
      email: email.toLowerCase(),
      password1,
      password2,
      firstName,
      lastName,
      agreement,
      yearOfBirth: yearOfBirth === 0 ? null : yearOfBirth,
    }
    dispatch(signUpAction(data))
  }

  const reset = () => {
    dispatch(resetUserSignUp())
  }

  return [values, { ...formHandlers, reset, signUp }]
}

export function useSignUpState() {
  const reduxState = useSelector(userCreationStateSelector)
  return {
    loading: reduxState.loading,
    completed: reduxState.completed,
    error: reduxState.error || new ApiError(),
  }
}
