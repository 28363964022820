import { all, call } from "redux-saga/effects"

import { detailsFetchingFlow } from "./details/actions"
import { listFetchingFlow } from "./list/actions"
import { subscribeFlow, unsubscribeFlow } from "./mailouts/actions"

export function* coursesSaga() {
  yield all([
    call(detailsFetchingFlow),
    call(listFetchingFlow),
    call(subscribeFlow),
    call(unsubscribeFlow),
  ])
}

export { fetchCourseDetails } from "./details/actions"
export { fetchCoursesList } from "./list/actions"
