import jwtDecode from "jwt-decode"
import { omit } from "lodash"
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"

import LOGIN_ACTION_TYPES from "./actiontypes"

const {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UNAUTHORIZED_ERROR,
} = LOGIN_ACTION_TYPES

const initState = {
  loading: false,
  access: null,
  refresh: null,
}

function loginReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...omit(state, ["error"]),
        loading: true,
      }

    case LOGIN_SUCCESS:
      return {
        ...omit(state, ["error"]),
        loading: false,
        access: {
          token: action.access,
          ...jwtDecode(action.access),
        },
        refresh: {
          token: action.refresh,
          ...jwtDecode(action.refresh),
        },
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case LOGOUT_SUCCESS:
      return {
        ...initState,
      }

    case UNAUTHORIZED_ERROR:
      return {
        ...initState,
      }

    default:
      return state
  }
}

const persistConfig = {
  key: "login",
  storage,
  whitelist: ["access", "refresh"],
}

export default persistReducer(persistConfig, loginReducer)
