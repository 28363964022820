/* global localStorage */
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import createSagaMiddleware from "redux-saga"

import { authInterceptor } from "./middlewares"
import rootReducer from "./rootReducer"
import rootSaga from "./rootSaga"
import {
  isAccessTokenExpired,
  isRefreshTokenExpired,
  loginStateSelector,
} from "../users"

function configureTokens(store) {
  const loginState = loginStateSelector(store.getState())
  if (isAccessTokenExpired(loginState)) {
    localStorage.removeItem("accessToken")
  } else {
    localStorage.setItem("accessToken", loginState.access.token)
  }
  if (isRefreshTokenExpired(loginState)) {
    localStorage.removeItem("refreshToken")
  } else {
    localStorage.setItem("refreshToken", loginState.refresh.token)
  }
}

export default function initStore() {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: false,
      }),
      sagaMiddleware,
      authInterceptor,
    ],
  })
  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store, null, () => configureTokens(store))
  return { store, persistor }
}
