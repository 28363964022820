import CREATION_ACTION_TYPES from "./actiontypes"
import { creationReducerFactory } from "core/factories/reducers"

const {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  RESET,
  INVALIDATE,
} = CREATION_ACTION_TYPES

const initState = {
  loading: false,
  didInvalidate: false,
  completed: false,
}

const baseReducer = creationReducerFactory({
  createRequest: CREATE_REQUEST,
  createFailure: CREATE_FAILURE,
  reset: RESET,
  invalidate: INVALIDATE,
})

const creationReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_REQUEST:
    case CREATE_FAILURE:
    case RESET:
    case INVALIDATE:
      return baseReducer(state, action)

    case CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        didInvalidate: false,
        error: null,
        completed: true,
        redirectTo: action.details.redirectTo,
      }

    default:
      return state
  }
}

export default creationReducer
