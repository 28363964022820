import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { LessonType } from "lessons"
import { useDispatch, useSelector } from "react-redux"
import {
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Link,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { unsubscribeFromMailouts } from "courses/mailouts/actions"
import { courseMailoutsUnsubscribeStateSelector } from "courses/mailouts/selectors"
import { AlertDialog, RouterLink } from "core/components"
import { PARTICIPANT_STATES } from "participants/constants"

import urls from "core/urls"

const useStyles = makeStyles((theme) => ({
  topContent: {
    verticalAlign: "top",
  },
  greenColor: {
    color: theme.colors.greenText,
  },
  orangeColor: {
    color: "#e29029",
  },
  defaultColor: {
    color: theme.colors.lightText,
  },
  lessonList: {
    maxWidth: 400,
    listStyle: "none",
    margin: 0,
    marginTop: 5,
    padding: 0,
  },
  lessonsContainer: {
    marginTop: 21,
  },
  courseLink: {
    color: theme.colors.lightText,
    verticalAlign: "sub",
    marginRight: 4,
    fontSize: "1.2rem",

    "&:hover": {
      color: "#796a27",
    },
  },
  subscribedState: {
    whiteSpace: "nowrap",
  },
  mailoutsStatus: {
    fontSize: "16px",
  },
}))

function ParticipantsTableRow(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const { id, course, authors, subscribed, lessons } = props
  const participantState =
    lessons && lessons.length > 0 && lessons[0].participationState

  let loading = false
  const state = useSelector((state) =>
    courseMailoutsUnsubscribeStateSelector(state, course)
  )
  if (state) {
    loading = state.loading
  }

  const unSubscribe = useCallback((event) => {
    event.preventDefault()
    dispatch(unsubscribeFromMailouts(id))
  })

  const onAgree = (event) => {
    if (!loading) {
      unSubscribe(event)
      setModalOpen(false)
    }
  }

  const onClose = (event) => {
    event.preventDefault()
    setModalOpen(false)
  }

  return (
    <TableRow>
      <TableCell className={classes.topContent}>
        <div className={classes.defaultColor}>
          <Link
            component={RouterLink}
            to={urls.courseDetails(id)}
            className={classes.courseLink}
            noWrap
          >
            <Typography variant="body2" component="h2">
              {course}
            </Typography>
          </Link>
        </div>
        {participantState === PARTICIPANT_STATES.approved && (
          <div className={classes.lessonsContainer}>
            <span>Участие в занятиях:</span>
            <ul className={classes.lessonList}>
              {lessons.map((lesson) => (
                <li key={lesson.id}>
                  {lesson.number}. {lesson.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </TableCell>
      <TableCell className={classes.topContent}>{authors}</TableCell>
      <TableCell className={classes.topContent} align="right">
        {handleState(classes, participantState)}
        {subscribed &&
          handleMailouts(classes, participantState, loading, setModalOpen)}
      </TableCell>
      <AlertDialog
        open={modalOpen}
        description={`Вы действительно хотите отписаться от новостей курса «${course}»?`}
        onAgree={onAgree}
        onClose={onClose}
      />
    </TableRow>
  )
}

function handleState(classes, state) {
  switch (state) {
    case PARTICIPANT_STATES.waitingList:
      return <span className={classes.orangeColor}>Очередь на курс</span>
    case PARTICIPANT_STATES.reserved:
      return (
        <span className={classes.orangeColor}>
          Зарезервировано - ожидание оплаты
        </span>
      )
    case PARTICIPANT_STATES.approved:
      return <span className={classes.greenColor}>Участие</span>

    default:
      return null
  }
}

function handleMailouts(classes, state, loading, setModalOpen) {
  if (state === PARTICIPANT_STATES.approved) {
    return null
  }

  return (
    <div className={classes.subscribedState}>
      <span className={classes.mailoutsStatus}>подписка на новости</span>
      <IconButton
        size="small"
        disabled={loading}
        onClick={() => {
          setModalOpen(true)
        }}
      >
        {/* TODO: show when api will be added */}
        {/* <Cancel className={classes.defaultColor} /> */}
      </IconButton>
    </div>
  )
}

ParticipantsTableRow.propTypes = {
  authors: PropTypes.string.isRequired,
  course: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  subscribed: PropTypes.bool.isRequired,
  lessons: PropTypes.arrayOf(PropTypes.shape(LessonType)).isRequired,
}

export default ParticipantsTableRow
