import React from "react"
import { convertFromRaw } from "draft-js"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { CourseType } from "../../types"
import { convertEditorStateToHtml } from "core/utils/text"

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    fontSize: 18,
    lineHeight: 1.43,

    "@media (min-width: 960px)": {
      marginTop: 30,
      fontSize: 21,
    },
  },

  courseTitle: {
    marginTop: 30,

    "@media (min-width: 960px)": {
      marginTop: 50,
    },
  },
})

function createMarkup(html) {
  return { __html: html }
}

function CourseDetailsDescription({ description }) {
  const classes = useStyles()
  const convertedDescription = convertFromRaw(JSON.parse(description))
  const htmlMarkup = createMarkup(
    convertEditorStateToHtml(convertedDescription)
  )
  return (
    <div>
      <Typography variant="h2" align="left" className={classes.courseTitle}>
        О курсе
      </Typography>
      <div className={classes.root} dangerouslySetInnerHTML={htmlMarkup} />
    </div>
  )
}

CourseDetailsDescription.propTypes = {
  description: CourseType.description,
}

export default CourseDetailsDescription
