const UPDATING_ACTION_TYPES = {
  UPDATE: "UPDATE_DISCIPLE",
  UPDATE_REQUEST: "UPDATE_DISCIPLE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_DISCIPLE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_DISCIPLE_FAILURE",
  RESET: "RESET_DISCIPLE_UPDATE",
  INVALIDATE: "INVALIDATE_DISCIPLE_UPDATE",
}

export default UPDATING_ACTION_TYPES
