import React from "react"
import PropTypes from "prop-types"
import { Avatar, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import { CourseType } from "../../types"
import { AuthorType } from "authors"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    color: "white",
    textAlign: "center",
    overflow: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundAttachment: "fixed",
    backgroundImage: ({ bgImage }) =>
      `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
    padding: 15,

    "@media screen and (orientation: landscape)": {
      backgroundSize: "100% auto",
    },

    "@media screen and (orientation: portrait)": {
      backgroundSize: "auto 100%",
    },
  },

  authorsList: {
    marginTop: 20,

    [theme.breakpoints.up("md")]: {
      marginTop: 30,
    },
  },

  authorAvatar: {
    width: 45,
    height: 45,
  },

  authorName: {
    marginLeft: 10,
    fontSize: 18,
    fontStyle: "italic",
  },

  title: {
    fontSize: 36,
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: 1.2,

    [theme.breakpoints.up("md")]: {
      fontSize: 60,
    },
  },

  subtitle: {
    marginTop: 20,
    fontSize: 21,
    fontWeight: 500,
    lineHeight: 1.2,

    [theme.breakpoints.up("md")]: {
      marginTop: 10,
      fontSize: 36,
    },
  },
}))

function CourseDetailsBanner({ course }) {
  const { authors, image, title, subtitle } = course
  const classes = useStyles({ bgImage: image })
  const authorsBlock = authors.map((author) => {
    return (
      <Grid item key={author.id}>
        <Grid container alignItems="center">
          <Avatar
            src={author.photo}
            alt="author"
            className={classes.authorAvatar}
          />
          <div className={classes.authorName}>Курс {author.genitive}</div>
        </Grid>
      </Grid>
    )
  })
  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.root}
    >
      <Grid item>
        <h1 className={classes.title}>{title}</h1>
      </Grid>
      <Grid item>
        <h2 className={classes.subtitle}>{subtitle}</h2>
      </Grid>
      <Grid item>
        <Grid container justify="center" className={classes.authorsList}>
          {authorsBlock}
        </Grid>
      </Grid>
    </Grid>
  )
}

CourseDetailsBanner.propTypes = {
  course: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        id: AuthorType.id,
        photo: AuthorType.photo,
        genitive: AuthorType.genitive,
      })
    ).isRequired,
    image: CourseType.image,
    title: CourseType.title,
    subtitle: CourseType.subtitle,
  }).isRequired,
}

export default CourseDetailsBanner
