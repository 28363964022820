import { combineReducers } from "redux"
import { normalize } from "normalizr"

import detailsReducer from "./details/reducer"
import DETAILS_ACTION_TYPES from "./details/actiontypes"
import discipleSchema from "./schema"
import UPDATING_ACTION_TYPES from "./updating/actiontypes"
import updatingReducer from "./updating/reducer"
import { entitiesReducerFactory } from "core/factories/reducers"

const { FETCH_DETAILS_SUCCESS } = DETAILS_ACTION_TYPES
const { UPDATE_SUCCESS } = UPDATING_ACTION_TYPES

export const disciplesEntitiesReducer = entitiesReducerFactory(
  {
    detailsSuccess: FETCH_DETAILS_SUCCESS,
    updateSuccess: UPDATE_SUCCESS,
  },
  discipleSchema,
  (normalizedData) => normalizedData.entities.disciples
)

export const profileEntitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DETAILS_SUCCESS:
    case UPDATE_SUCCESS:
      const normalizedData = normalize(action.details, discipleSchema)
      return {
        ...state,
        ...normalizedData.entities.profiles,
      }

    default:
      return state
  }
}

export const disciplesStateReducer = combineReducers({
  byId: detailsReducer,
  updating: updatingReducer,
})
